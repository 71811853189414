.vikinglotto-footer-navigation {
  background-color: #0000ff;
}
.vikinglotto-footer-navigation .vikinglotto-content-wrapper {
  padding: 0;
  text-align: left;
}
.vikinglotto-footer-navigation .vikinglotto-section {
  background: #0000ff;
  width: 100%;
  padding: 0;
  margin: auto;
}
@media (min-width: 950px) {
  .vikinglotto-footer-navigation .vikinglotto-section {
    max-width: 96rem;
  }
}
.vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link {
  max-width: 86.5vw;
  font-size: 5.4vw;
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
  color: #fff;
  line-height: 19.9vw;
  text-decoration: none;
  border-bottom: 0.1rem solid #fff;
  cursor: pointer;
  position: relative;
  display: block;
  transition: all 0.2s ease;
  margin: 0 auto;
}
@media (min-width: 950px) {
  .vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link {
    width: calc(50% - 2.5rem);
    font-size: 2rem;
    line-height: 10rem;
    display: inline-block;
  }
  .vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:nth-child(odd) {
    margin-right: 2.5rem;
  }
  .vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:nth-child(even) {
    margin-left: 2.5rem;
  }
  .vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:nth-last-child(1),
  .vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:nth-last-child(2) {
    border-bottom: none;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link {
    font-size: 2.5vw;
    line-height: 11vw;
  }
}
.vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:hover {
  color: #000078;
  opacity: 0.5;
}
.vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:hover:before {
  border-left: 2vw solid #000;
  opacity: 0.5;
}
@media (min-width: 950px) {
  .vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:hover:before {
    border-width: 1rem;
  }
}
.vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:last-child {
  border-bottom: none;
}
.vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:before,
.vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -2vw;
  width: 0;
  height: 0;
  border-top: 2vw solid transparent;
  border-bottom: 2vw solid transparent;
  border-left: 2vw solid #fff;
}
@media (min-width: 950px) {
  .vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:before,
  .vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:after {
    margin-top: -1rem;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-left: 1rem solid #fff;
  }
}
.vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:before {
  right: 0;
  border-left: 2vw solid #fff;
}
@media (min-width: 950px) {
  .vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:before {
    border-width: 1rem;
  }
}
.vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:after {
  right: .2rem;
  border-left: 2vw solid #0000ff;
}
@media (min-width: 950px) {
  .vikinglotto-footer-navigation .vikinglotto-section .footer-navigation-link:after {
    border-width: 1rem;
  }
}
