﻿@import url("../Mixins/_mixins.less");

.top-navigation {
  display: none;
}

.vikinglotto-winning-numbers-spot {

  @media (min-width: @vikinglotto-mobile-width) {
    margin: 0 auto;
  }

  .vikinglotto-section {
    background-color: @vikinglotto-white;
    border-color: @vikinglotto-white;
    position: relative;
    transition: max-height 1s ease-in-out;
    overflow: hidden;

    &.section-with-dropdown {
      overflow: visible;
    }

    .vikinglotto-content-wrapper {
      padding: 0 0 4rem 0;

      @media (min-width: @vikinglotto-desktop-width) {
        font-size: 4rem;
        padding: 5.5rem 0 6rem;
        margin: 0 auto;
      }

      .headline {
        font-size: 3rem;
        font-weight: bold;
        color: @vikinglotto-lightblue;
        line-height: 1;
        margin: 1.5rem 0;

        @media (min-width: @vikinglotto-desktop-width) {
          font-size: 5rem;
          margin: 0 auto 1rem;
        }
      }

      .sub-headline {
        font-size: 1.7rem;
        font-weight: bold;
        margin: 0 auto 1.5rem;
        color: @vikinglotto-lightblue;
        display: block;
      }

      .select-date-section {
        padding: 0 2.5rem 1rem;

        @media (min-width: @vikinglotto-desktop-width) {
          padding: 0;
        }

        .date-picker-placeholder-wrapper {
          padding: 1rem;

          .icon-arrow {
            top: 1.5rem;
            right: 1rem;
          }

          @media (min-width: @vikinglotto-desktop-width) {
            padding: 1rem 3rem;

            .icon-arrow {
              right: 4rem;
            }
          }
        }

        .select-dropdown {
          @media (min-width: @vikinglotto-desktop-width) {
            max-width: 45rem;
          }

          .cs-placeholder {
            font-size: 3.4vw;
            color: @vikinglotto-white;
            background-color: @vikinglotto-darkblue;
            border: none;
            transition: background-color .15s;

            @media (min-width: @vikinglotto-mobile-width) {
              font-size: 1.8rem;
            }
          }

          .select-options ul li {
            background-color: @vikinglotto-darkblue;

            span {
              font-size: 1.8rem;
            }
          }
        }
      }

      .winning-numbers-list {
        position: relative;
        display: flex;
        overflow-x: scroll;
        flex:  0 0 auto;

        @media (min-width: @vikinglotto-desktop-width) {
          padding: 0 calc(~'(100vw - 96rem) / 2');
        }

        @media (min-width: @vikinglotto-large-desktop-width) {
          overflow: hidden;
        }

        &::after {
          content: "";
          display: block;
          height: 39rem;
          width: 100%;
          min-width: 175vw;
          background-color: @vikinglotto-midnight;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1;

          @media (min-width: @vikinglotto-mobile-width) {
            min-width: 115vw;
          }

          @media (min-width: @vikinglotto-desktop-width) {
            min-width: auto;
            height: 55rem;
          }
        }

        .winning-numbers-element {
          padding: 0 0 0 2.5rem;
          box-sizing: border-box;
          position: relative;
          z-index: 2;
          margin-bottom: 4rem;
          min-width: 85vw;
          text-align: left;

          @media (min-width: @vikinglotto-mobile-width) {
            min-width: 55vw;
          }

          @media (min-width: @vikinglotto-desktop-width) {
            min-width: auto;
            text-align: center;
          }
          
          &.winning-numbers-element--fade {
            opacity: 0.5;
          }

          .logo {
            border-bottom: 0.1rem solid @vikinglotto-lightgray;
            fill: @vikinglotto-blue;
            min-height: 6rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;

            @media (min-width: @vikinglotto-desktop-width) {
              border-bottom-width: 0.1rem;
              padding-bottom: 1rem;
              margin: 3rem auto;
              justify-content: center;
              align-items: center;
            }

            svg {
              display: block;

              @media (min-width: @vikinglotto-desktop-width) {
                width: 11rem;
                height: 3.5rem;
              }

              &.vikinglotto-logo {
                height: 3rem;
                width: 15rem;
                margin-top: 2rem;
                display: block;

                @media (min-width: @vikinglotto-desktop-width) {
                  width: 20.2rem;
                  height: 4rem;
                }
              }

              &.joker-hat {
                width: 6.5rem;
                height: 2.2rem;
                padding: 0;
                flex: 100%;
                transform: translateX(-20%);

                @media (min-width: @vikinglotto-desktop-width) {
                  width: 6.5rem;
                  height: 3.2rem;
                  transform: translateY(-30%);
                }
              }

              &.joker-logo {
                height: 2rem;
                width: 10rem;
                margin-top: -1rem;

                @media (min-width: @vikinglotto-desktop-width) {
                  width: 11.2rem;
                  height: 2.6rem;
                }
              }
            }

            .joker-text {
              font-size: 2rem;
              font-style: italic;
              color: @vikinglotto-blue;
              margin-top: -1rem;

              @media (min-width: @vikinglotto-desktop-width) {
                font-size: 2.9rem;
              }
            }
          }

          .numbers {
            min-height: 24rem;
            margin-bottom: 1rem;
            @media (min-width: @vikinglotto-mobile-width) {
              margin-bottom: 2rem;
            }

            @media (min-width: @vikinglotto-desktop-width) {
              min-height: 22rem;
            }

            .number-container {

              .numbers-headline {
                font-size: 1.6rem;
                font-weight: bold;
                color: @vikinglotto-lightgray;
                line-height: 1;
                margin: 2rem auto;

                @media (min-width: @vikinglotto-desktop-width) {
                  margin: 0;
                }
              }

              ul, li {
                margin: 0;
                padding: 0;
              }

              &:last-child {
                ul {
                  padding: 0;
                }
              }

              ul {
                list-style-type: none;
                margin: 0 -0.3rem 3rem;

                &.bonus {
                  li {
                    height: 6rem;
                    font-size: 1.2rem;
                    background-color: @vikinglotto-white;

                    @media (min-width: @vikinglotto-desktop-width) {
                      width: 5.2rem;
                      height: 6.8rem;
                      font-size: 1.6rem;
                    }

                    &:after {
                      display: none;
                    }

                    span {
                      position: absolute;
                      left: 50%;
                      transform: translateX(-50%);
                      top: 0.7rem;
                    }
                  }
                }
              }

              li {
                display: inline-block;
                margin: 0 0.3rem;
                background-color: @vikinglotto-blue;
                width: 3.6rem;
                height: 3.6rem;
                border-radius: 100%;
                font-size: 1.2rem;
                font-weight: 900;
                color: @vikinglotto-lightgray;
                line-height: 3.2;
                text-align: center;
                position: relative;

                @media (min-width: @vikinglotto-mobile-width) {
                  width: 4.5rem;
                  height: 4.5rem;
                  font-size: 1.4rem;
                  margin: 0 0.3rem;
                }

                &:after {
                  content: '';
                  width: 35%;
                  border-bottom: 0.1rem solid @vikinglotto-lightgray;
                  display: block;
                  margin: auto;
                  bottom: 30%;
                  position: relative;

                  @media (min-width: @vikinglotto-desktop-width) {
                    border-bottom-width: 0.1rem;
                  }
                }

                .number-icon {
                  fill: @vikinglotto-white;
                  position: absolute;
                  width: 60%;
                  height: 60%;
                  transform: translate(-50%,-50%);
                  top: 50%;
                  left: 50%;

                  &.helmet {
                    width: 3.4rem;
                    height: 6rem;
                    top: 45%;
                  }
                }

                span {
                  position: relative;
                  z-index: 1;
                  display: block;
                  width: inherit;
                  height: inherit;
                }
              }
            }
          }


          .results {

            table {
              width: 100%;
              border-collapse: inherit;
              border-radius: 7.5px;
              table-layout: fixed;
              border: 0.1rem solid fade(@vikinglotto-lightgray, 40%);
              overflow: hidden;

              td, th {
                padding: 1rem;
                font-size: 1.2rem;
                color: @vikinglotto-lightgray;
                border: 0.1rem solid fade(@vikinglotto-lightgray, 40%);

                @media (min-width: @vikinglotto-desktop-width) {
                  padding: 1.7rem 1.3rem;
                  font-size: 1.5rem;
                }
              }

              th {
                font-weight: bold;
              }

              tr {
                background-color: @vikinglotto-white;
                &:nth-child(even) {
                  background-color: @vikinglotto-whitesmoke;
                }
              }

              thead {
                th {
                  font-weight: bold;
                  font-size: 1.2rem;
                  color: @vikinglotto-white;
                  background-color: @vikinglotto-blue;
                  padding: 0.5rem;

                  @media (min-width: @vikinglotto-desktop-width) {
                    padding: 2rem;
                    font-size: 1.6rem;
                  }
                }
              }
            }
          }

          &.vikinglotto-winning-numbers {
            .numbers .super {
              li {
                background-color: @vikinglotto-darkblue;
              }
            }

            .number-box {
              width: 40%;
              display: inline-block;

              h2 {
                margin-top: 0;
              }
            }
          }

          &.joker-winning-numbers {
            .numbers {
              li {
                background-color: @vikinglotto-red;
                background-image: linear-gradient(45deg, @vikinglotto-lightgreen 25%, transparent 25%, transparent 75%, @vikinglotto-lightgreen 75%, @vikinglotto-lightgreen), linear-gradient(45deg, @vikinglotto-lightgreen 25%, transparent 25%, transparent 75%, @vikinglotto-lightgreen 75%, @vikinglotto-lightgreen);
                background-size: 30% 30%;
                background-position: 0 20%, 20% 0;
              }
            }
          }
        }
      }

      .disclaimer-info-text {
        font-size: 2rem;
        font-weight: 900;
        color: @vikinglotto-darkgray;
        padding: 2.5rem 3rem;
        margin: 0 auto;
      }
    }
  }

  .featured-winners {
    font-size: 3.2vw;
    border: 0.1rem solid fade(@vikinglotto-lightgray, 40%);
    border-radius: 7.5px;
    color: @vikinglotto-lightgray;
    margin: 1rem auto;

    @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
      font-size: 1.8rem;
    }

    @media (min-width: @vikinglotto-desktop-width) {
      font-size: 1.5rem;
    }

    .header {
      text-align: left;
      font-weight: 600;
      padding: 2vw 2rem;
      color: @vikinglotto-lightgray;
      border-bottom: 1px solid fade(@vikinglotto-lightgray, 25%);
      position: relative;

      @media (min-width: @vikinglotto-mobile-width) {
        padding: 1.9rem;
      }

      .icon-arrow {
        position: absolute;
        width: 3.2vw;
        height: 3.2vw;
        right: 2rem;
        top: 2.5vw;
        fill: @vikinglotto-lightgray;

        @media (min-width: @vikinglotto-mobile-width) {
          top: 2.2rem;
          width: 2rem;
          height: 2rem;
        }
      }
    }

    .text {
      text-align: left;
      padding: 2vw 2rem;
      line-height: 5vw;
      font-style: italic;

      @media (min-width: @vikinglotto-mobile-width) {
        line-height: 3rem;
        padding: 1rem 2rem;
      }

      p {
        margin: 0;
      }
    }

    &.hide {
      .header {
        border-bottom: none;

        .icon-arrow {
          transform: rotate(180deg);
        }
      }

      .header:after {
        display: none;
      }

      .text {
        display: none;
      }
    }
  }
}
