@import url("../../Mixins/_mixins.less");

.region-vikinglotto {
  .how-to-play-section {
    .headline {
      font-style: italic;
      color: @vikinglotto-blue;
      text-transform: uppercase;
    }

    .text {
      color: @numbergames-mediumgray;
    }

    .video-headline {
      color: @vikinglotto-blue;
      text-transform: uppercase;
    }

    .video-element {
      .video-frame {
        background: @vikinglotto-darkblue;
      }

      .video-play-button {
        .play-button {
          border-color: transparent transparent transparent @vikinglotto-blue;
        }
      }
    }
  }
}