@import url("../variables.less");

.top-navigation-theme-vikinglotto {

  @theme-color-a: #000078;
  @theme-color-b: #0000ff;

  .menu-toggle-right {
    background-color: @theme-color-b;
  }

  .brand-nav {
    background-color: @theme-color-b;
  }

  .corporate-nav {
    background-color: @theme-color-a;

    &__list-item:not(.js-has-subnav) {
      .corporate-nav__list-link:hover {
        color: @ds-white;
        opacity: 0.7;
      }
    }
  }

  .mobile-navigation {
    &-header {
      background-color: @theme-color-b;
    }

    &-footer {
      &-icon {
        background-color: @theme-color-b;
      }
    }

    &-item {
      &-link {
        &:before {
          background-color: @theme-color-b;
        }
      }
    }
  }

  .mega-menu {
    &__campaign {
      &__cta-button {
        background-color: @theme-color-b;
        color: white;

        &:hover {
          background-color: @theme-color-a;
        }
      }
    }
  }
}
