.vikinglotto-games-subnavigation-bar {
  background-color: #0000b4;
}
.vikinglotto-games-subnavigation-bar:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
@media (min-width: 950px) {
  .vikinglotto-games-subnavigation-bar {
    pointer-events: none;
  }
}
.vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation {
  width: 84.266vw;
  position: relative;
  background-color: #0000ff;
  float: left;
}
@media (min-width: 950px) {
  .vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation {
    width: 100%;
    float: none;
    background-color: transparent;
  }
}
.vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-placeholder {
  border-right: 2px solid #0000b4;
  margin: 0;
}
.vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-placeholder .subnavigation-arrow {
  position: absolute;
  top: 4.6vw;
  right: 5vw;
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: transform .25s, opacity .25s;
}
@media (min-width: 950px) {
  .vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-placeholder .subnavigation-arrow {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-placeholder .subnavigation-arrow {
    top: initial;
  }
}
.vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-placeholder .subnavigation-arrow .icon {
  width: 3.466vw;
  height: 2.133vw;
  fill: #fff;
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-placeholder .subnavigation-arrow .icon {
    width: 2.5vw;
    height: 1.5vw;
  }
}
.vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-placeholder,
.vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-options .item {
  font-size: 4.266vw;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  padding: 5.07vw 0 5.07vw 5vw;
  cursor: pointer;
}
@media (min-width: 950px) {
  .vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-placeholder,
  .vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-options .item {
    border-right: none;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
    padding: 2.15rem 1rem;
    margin: 0;
    background-color: #000078;
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-placeholder,
  .vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-options .item {
    font-size: 2.5vw;
    padding: 2.5vw 0 2.5vw 6.666vw;
  }
}
.vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-options a {
  color: #fff;
  text-decoration: none;
}
.vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-options {
  max-height: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  z-index: 2;
  overflow: hidden;
  transition: max-height 0.25s linear;
}
.vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-options .item {
  background-color: #0000ff;
  border-bottom: 2px solid #0000b4;
  border-right: 2px solid #0000b4;
}
.vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-options .item:first-child {
  border-top: 2px solid #0000b4;
}
.vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation .subnavigation-options .item:last-child {
  border-bottom-right-radius: 6px;
}
.vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation.active .subnavigation-arrow {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}
.vikinglotto-games-subnavigation-bar .vikinglotto-games-subnavigation.active .subnavigation-options {
  max-height: 100vw;
}
.vikinglotto-games-subnavigation-bar .vikinglotto-information-button {
  width: 15.734vw;
  height: 16vw;
  position: relative;
  float: left;
  cursor: pointer;
}
@media (min-width: 950px) {
  .vikinglotto-games-subnavigation-bar .vikinglotto-information-button {
    width: 4rem;
    height: 4rem;
    background-color: #000078;
    border-radius: 0 0 0 6px;
    float: right;
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-games-subnavigation-bar .vikinglotto-information-button {
    height: 8.4vw;
  }
}
.vikinglotto-games-subnavigation-bar .vikinglotto-information-button:before {
  content: '?';
  font-size: 5.6vw;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
@media (min-width: 950px) {
  .vikinglotto-games-subnavigation-bar .vikinglotto-information-button:before {
    font-size: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-games-subnavigation-bar .vikinglotto-information-button:before {
    font-size: 3.6vw;
  }
}
