.vikinglotto-game-tabs-wrapper {
  background-color: #0000b4;
}
.vikinglotto-game-tabs-wrapper:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.vikinglotto-game-tabs-wrapper .tabs-navigation {
  width: 14vw;
  display: inline-block;
  float: left;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-navigation {
    width: 7rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item {
  padding-top: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.vikinglotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item .tab-navigation-icon {
  width: 4.8vw;
  height: 4.8vw;
  text-align: center;
  fill: #fff;
  color: #fff;
  position: absolute;
  margin: auto;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate3d(0, -50%, 0);
}
@media (min-width: 768px) {
  .vikinglotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item .tab-navigation-icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item.active {
  background-color: #000078;
}
.vikinglotto-game-tabs-wrapper .tabs-navigation .tab-navigation-item.active .tab-navigation-icon {
  fill: #fff;
  color: #fff;
}
.vikinglotto-game-tabs-wrapper .tabs-container {
  width: 86vw;
  font-size: 0;
  line-height: 0;
  background-color: #000078;
  overflow: hidden;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container {
    width: calc(100% - 7rem);
  }
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container {
    width: calc(100% - 6rem);
    margin: auto;
    overflow: hidden;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper {
  width: 72.8vw;
  font-size: 3.733vw;
  color: #fff;
  padding: 0.6vw 0 1.9vw;
  margin: auto;
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper {
    font-size: 1.8rem;
  }
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper {
    width: 100%;
    font-size: 2.8rem;
    padding: 0 0 1rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .tab-headline {
  font-size: 3.733vw;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  padding: 5.4vw 0 5.5vw;
  border-bottom: 1px solid #fff;
  line-height: .7;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .tab-headline {
    font-size: 1.6rem;
    padding: 3.5rem 0 2.5rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper p {
  font-size: 3.733vw;
  line-height: 1.3;
  margin: 0;
  padding: 2.9vw 0 1vw;
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper p {
    font-size: 1.8rem;
    line-height: 1.5;
  }
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper p {
    width: 58.5rem;
    font-size: 1.6rem;
    line-height: 1.5;
    padding: 2.5rem 0 .6rem;
    margin: auto;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper p.explanation {
  font-size: 2.8vw;
  line-height: 1.3;
  margin: 0;
  padding: 4vw 0;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper p.explanation {
    width: 58.5rem;
    font-size: 1.4rem;
    line-height: 1.5;
    padding: 0 0 .6rem 0;
    margin: auto;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper ol {
  margin: 0;
  padding: 4vw 0;
  list-style-type: none;
  line-height: 1.3;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper ol {
    width: 58.5rem;
    font-size: 1.6rem;
    padding: 2rem 0 1.8rem;
    margin: auto;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper ol li {
  counter-increment: custom-counter;
  position: relative;
  padding: 7.7vw 0 4.8vw;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper ol li {
    padding: 4.1rem 0 2.4rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper ol li:before {
  content: counter(custom-counter);
  width: 6vw;
  height: 6vw;
  font-size: 2.933vw;
  font-weight: bold;
  color: #1d1d1d;
  border-radius: 50%;
  background-color: #fff;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 6vw;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper ol li:before {
    width: 3.4rem;
    height: 3.4rem;
    font-size: 1.6rem;
    line-height: 3.4rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table {
  width: 100%;
  border-collapse: inherit;
  border-radius: 6px;
  overflow: hidden;
  line-height: 1.3;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table {
    width: 45rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table thead th {
  background-color: #dddddd;
  font-size: 3.2vw;
  color: #4f5254;
  padding: 3vw 0 3vw;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table thead th {
    font-size: 1.6rem;
    padding: 1.8rem 0 1.7rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table thead th:first-child {
  width: 39%;
  border-right: 0.2rem solid rgba(163, 166, 171, 0.4);
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table thead th:first-child {
    width: 40%;
    border-right: 0.1rem solid rgba(163, 166, 171, 0.4);
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table tbody tr:nth-child(odd) {
  background-color: #fff;
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table tbody tr:nth-child(even) {
  background-color: #f1f2f2;
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table tbody tr td {
  font-size: 3.2vw;
  color: #4f5254;
  padding: 3.4vw 0 3.3vw;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table tbody tr td {
    font-size: 1.5rem;
    padding: 1.6rem 0 1.6rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table tbody tr td:first-child {
  border-right: 0.2rem solid rgba(163, 166, 171, 0.4);
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .simple-custom-table tbody tr td:first-child {
    border-right: 0.1rem solid rgba(163, 166, 171, 0.4);
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper {
  max-height: 570vw;
  position: relative;
  overflow: hidden;
  transition: max-height 0.5s linear;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper {
    max-height: 268rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper .expand-action-wrapper {
  text-align: center;
  height: 15rem;
  margin: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: -moz-linear-gradient(top, rgba(197, 0, 5, 0) 0%, rgba(197, 0, 5, 0) 1%, #c50005 90%, #c50005 100%);
  background: -webkit-linear-gradient(top, rgba(197, 0, 5, 0) 0%, rgba(197, 0, 5, 0) 1%, #c50005 90%, #c50005 100%);
  background: linear-gradient(to bottom, rgba(197, 0, 5, 0) 0%, rgba(197, 0, 5, 0) 1%, #c50005 90%, #c50005 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c50005', endColorstr='#c50005', GradientType=0);
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper .expand-action-wrapper .btn {
  position: relative;
  bottom: -7rem;
  background: #fff;
  display: inline-block;
  text-transform: uppercase;
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper.active {
  padding-bottom: 16rem;
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper.active .simple-custom-table {
  margin-bottom: 14rem;
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper.active .expand-action-wrapper {
  bottom: -1rem;
}
.vikinglotto-game-tabs-wrapper .tabs-container .tab-container .inner-wrapper .table-foldable-wrapper.active .expand-action-wrapper .btn {
  bottom: -5rem;
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordions-container {
  border-radius: 6px;
  overflow: hidden;
  line-height: 1.3;
  margin: 3.2vw auto 0;
}
@media (min-width: 768px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordions-container {
    width: 45rem;
    margin: 1.7rem auto 2.2rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordions-container .print-button {
  font-size: 0;
  line-height: 0;
  background-color: #0000ff;
  float: right;
  border-radius: 6px 6px 0 0;
  padding: 2vw 3vw 3vw;
  margin-bottom: -1vw;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  transition: transform 0.1s ease-out;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordions-container .print-button {
    padding: 1rem 1.5rem 2rem;
    margin-bottom: -0.5rem;
    bottom: -0.5rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordions-container .print-button .icon-print {
  width: 4vw;
  height: 4vw;
  fill: #fff;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordions-container .print-button .icon-print {
    width: 2.5rem;
    height: 2.5rem;
  }
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordions-container .print-button:hover {
    transform: translate3d(0, -0.5rem, 0);
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordions-container .accordions-header {
  font-size: 3.733vw;
  color: #fff;
  background-color: #0000ff;
  padding: 2.935vw 0;
  border-radius: 6px 6px 0 0;
  clear: both;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordions-container .accordions-header {
    font-size: 1.6rem;
    padding: 1.9rem 0;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper {
  line-height: 1.3;
  border-bottom: .2rem solid #fff;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper {
    border-bottom: .1rem solid #fff;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header {
  text-align: left;
  background-color: #f1f2f2;
  padding: 4.267vw 8vw 4.267vw 4vw;
  position: relative;
  cursor: pointer;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header {
    padding: 0.4rem 3rem 1rem 2rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .title {
  font-size: 3.733vw;
  font-weight: bold;
  color: #4f5254;
  text-transform: uppercase;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .title {
    font-size: 1.5rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .icon {
  width: 2.667vw;
  height: 2.667vw;
  position: absolute;
  top: 5.7vw;
  right: 3.966vw;
  fill: #777a7f;
  overflow: visible;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .icon {
    width: 1.3rem;
    height: 1.3rem;
    top: 2.3rem;
    right: 1.9rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .icon use {
  transition: transform .25s linear, opacity .25s linear;
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .icon .arrow-down {
  transform: translateY(-0.7vw);
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .icon .arrow-down {
    transform: translateY(-0.5rem);
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-header .icon .close {
  opacity: 0;
  transform: translateY(-150%);
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body {
  background-color: #fff;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.25s linear;
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table {
  width: 100%;
  line-height: 1.3;
  font-size: 3.2vw;
  color: #4f5254;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table {
    font-size: 1.5rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table thead tr {
  border-bottom: .2rem solid #f1f2f2;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table thead tr {
    border-bottom: .1rem solid #f1f2f2;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table thead tr td {
  padding: 2.6vw 0 3.9vw;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table thead tr td {
    padding: 1.6rem 0 1.5rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table thead tr td span {
  display: none;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table thead tr td span {
    display: inline-block;
    padding-left: .5rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr:first-child td {
  padding-top: 2.5vw;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr:first-child td {
    padding-top: 1.4rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr:last-child td {
  padding-bottom: 3.4vw;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr:last-child td {
    padding-bottom: 1.7rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr td {
  width: 17.25%;
  padding-top: 1vw;
  padding-bottom: .9vw;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr td {
    width: 18.5%;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr td:last-child {
  width: 31%;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .accordion-table tbody tr td:last-child {
    width: 26%;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table {
  width: 100%;
  line-height: 1.3;
  font-size: 3.733vw;
  color: #4f5254;
  text-align: left;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table {
    font-size: 1.5rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr td {
  width: 78%;
  padding: 2.8vw 0 2.8vw 4vw;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr td {
    width: 88%;
    padding: 1.6rem 0 1.6rem 2rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr td:last-child {
  width: 22%;
  padding: 2.8vw 0;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr td:last-child {
    width: 12%;
    padding: 1.6rem 0;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:first-child td {
  padding: 4.5vw 0 3.3vw 4vw;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:first-child td {
    padding: 1.6rem 0 1.6rem 2rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:first-child td:last-child {
  padding: 4.5vw 0 3.3vw 0;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:first-child td:last-child {
    padding: 1.6rem 0;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:last-child td {
  padding: 3.3vw 0 4vw 4vw;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:last-child td {
    padding: 1.6rem 0 1.6rem 2rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:last-child td:last-child {
  padding: 3.3vw 0 4vw 0;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper .accordion-body .simple-accordion-table tbody tr:last-child td:last-child {
    padding: 1.6rem 0;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper.active .accordion-header .icon .arrow-down {
  opacity: 0;
  transform: translateY(150%);
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper.active .accordion-header .icon .close {
  opacity: 1;
  transform: translateY(-0.4rem);
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper.active .accordion-body {
  max-height: 40rem;
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper:last-child {
  border-bottom: none;
}
.vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper:last-child .accordion-body .accordion-table tbody tr:last-child td {
  padding-bottom: 3.6vw;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .accordion-wrapper:last-child .accordion-body .accordion-table tbody tr:last-child td {
    padding-bottom: 1.5rem;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .game-container .rows-container {
  width: 86.835%;
}
@media (min-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .game-container .rows-container {
    width: 91.6rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-game-tabs-wrapper .tabs-container .game-container .rows-container {
    width: 60%;
  }
}
.vikinglotto-game-tabs-wrapper .tabs-container .game-container .rows-container .number-picker-container {
  text-align: left;
}
