﻿// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

@keyframes vikinglotto-shake-overlay-slide-animation {
  0% {
    transform: translateX(0);
  }

  92% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

.vikinglotto-classic-game, .vikinglotto-system-game {
  .shake-overlay-wrapper {
    width: 100%;
    height: 100%;
    color: @vikinglotto-white;
    text-align: center;
    background-color: @vikinglotto-blue;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    animation: vikinglotto-shake-overlay-slide-animation 4s 2s linear forwards;

    .phone-whrapper {
      position: relative;
      transform: translateY(25vh);

      @keyframes vikinglotto-phone-shake-animation {
        0% {
          visibility: visible;
          transform: translateY(-100%) rotate(0);
          opacity: 0;
        }

        2% {
          opacity: 1;
        }

        8% {
          transform: translateY(0) rotate(0);
        }

        15% {
          transform: translateY(-25%) rotate(0);
        }

        25% {
          transform: translateY(0) rotate(0);
        }

        30% {
          transform: translateY(-10%) rotate(0);
        }

        35% {
          transform: translateY(0) rotate(0);
        }

        37.5% {
          transform: translateY(-5%) rotate(0);
        }

        40% {
          transform: translateY(0) rotate(0);
        }

        42.5% {
          transform: rotate(20deg);
        }

        45% {
          transform: rotate(0);
        }

        47.5% {
          transform: rotate(-20deg);
        }

        50% {
          transform: rotate(0);
        }

        52.5% {
          transform: rotate(20deg);
        }

        55% {
          transform: rotate(0);
        }

        57.5% {
          transform: rotate(-20deg);
        }

        60% {
          transform: rotate(0);
        }

        62.5% {
          transform: rotate(20deg);
        }

        65% {
          transform: rotate(0);
        }

        67.5% {
          transform: rotate(-20deg);
        }

        70% {
          transform: rotate(0);
        }

        72.5% {
          transform: rotate(15deg);
        }

        75% {
          transform: rotate(0);
        }

        77.5% {
          transform: rotate(-15deg);
        }

        80% {
          transform: rotate(0);
        }

        82.5% {
          transform: rotate(15deg);
        }

        85% {
          transform: rotate(0);
        }

        87.5% {
          transform: rotate(-15deg);
        }

        90% {
          transform: rotate(0);
        }

        92.5% {
          transform: rotate(10deg);
        }

        95% {
          transform: rotate(0);
        }

        97.5% {
          transform: rotate(-10deg);
        }

        100% {
          visibility: visible;
          transform: rotate(0);
          opacity: 1;
        }
      }

      @keyframes vikinglotto-phone-shadow-animation {
        0% {
          transform: translateY(-200%) scale(.5);
          opacity: 0;
        }

        15% {
          transform: translateY(0) scale(1);
          opacity: 1;
        }

        30% {
          transform: translateY(-75%) scale(.7);
          opacity: .6;
        }

        40% {
          transform: translateY(0) scale(1);
          opacity: 1;
        }

        50% {
          transform: translateY(-25%) scale(.9);
          opacity: .8;
        }

        60% {
          transform: translateY(0) scale(1);
          opacity: 1;
        }

        100% {
          transform: translateY(0) scale(1);
          opacity: 1;
        }
      }

      .phone-shape-whrapper {
        width: 26vw;
        position: relative;
        margin: auto;
        opacity: 0;
        visibility: hidden;
        animation: vikinglotto-phone-shake-animation 2.5s 2.7s linear forwards;

        .phone-shape {
          width: 26vw;
          height: 46.666vw;
          fill: @vikinglotto-white;
          position: relative;
        }

        .shake-lines {
          width: 4.733vw;
          height: 9.333vw;
          position: absolute;

          &:before, &:after {
            content: '';
            width: 1.333vw;
            background-color: @vikinglotto-darkblue;
            position: absolute;
          }

          &:before {
            height: 100%;
          }

          &:after {
            height: 70%;
            top: 15%;
          }

          &:first-of-type {
            top: 6.666vw;
            left: -7.666vw;

            &:before {
              right: 0;
            }

            &:after {
              left: 0;
            }
          }

          &:last-of-type {
            bottom: 6.666vw;
            right: -7.666vw;

            &:before {
              left: 0;
            }

            &:after {
              right: 0;
            }
          }
        }
      }

      .phone-shadow {
        width: 26vw;
        height: 2.5vw;
        background-color: @vikinglotto-darkblue;
        border-radius: 50%;
        position: absolute;
        bottom: -7.5vw;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 0;
        animation: vikinglotto-phone-shadow-animation 1.4s 2.7s linear forwards;
      }
    }

    @keyframes vikinglotto-shake-text-animation {
      0% {
        transform: translateY(-50vh) scale(1.4);
      }

      10% {
        transform: translateY(-50vh) scale(1.4);
      }

      20% {
        transform: translateY(-50vh) scale(1.6);
      }

      30% {
        transform: translateY(-50vh) scale(1.4);
      }

      40% {
        transform: translateY(-50vh) scale(1.6);
      }

      50% {
        transform: translateY(-50vh) scale(1.4);
      }

      100% {
        transform: translateY(-10vh) scale(1);
      }
    }

    .shake-overlay-text {
      width: 60%;
      font-size: 5.6vw;
      line-height: 1.3;
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translateY(-50vw) scale(1);
      animation: vikinglotto-shake-text-animation .5s 2.1s linear forwards;
    }

    @keyframes vikinglotto-shake-overlay-remove-animation {
      0% {
        left: 0;
      }

      100% {
        left: 100vw;
      }
    }

    &.remove {
      animation: vikinglotto-shake-overlay-slide-animation 1s forwards linear;
    }
  }
}
