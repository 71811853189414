.vikinglotto-rotary-selector {
  font-size: 1vw;
  margin: auto;
  overflow: hidden;
  padding-bottom: 2rem;
}
@media (min-width: 950px) {
  .vikinglotto-rotary-selector {
    font-size: .55rem;
  }
}
@media screen and (min-resolution: 2dppx) and (min-width: 950px) {
  .vikinglotto-rotary-selector {
    font-size: 6px;
  }
}
.vikinglotto-rotary-selector .selector-container {
  height: 32em;
  max-width: 60em;
  position: relative;
  padding: 6em 0;
  perspective: 150em;
  margin: auto;
  box-sizing: content-box;
}
.vikinglotto-rotary-selector .selector-background {
  width: 32em;
  height: 32em;
  background-color: #0000ff;
  border-radius: 1em;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.vikinglotto-rotary-selector .selector-numbers {
  width: 60em;
  height: 0;
  padding-bottom: 60em;
  position: absolute;
  transform-origin: center center;
  transform-style: preserve-3d;
  transition: transform 0.5s cubic-bezier(0.34, 1.61, 0.7, 1);
  transform: rotateX(-25deg) rotateZ(0deg) translateZ(-45em);
  top: 70em;
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper {
  width: 30em;
  height: 100%;
  position: absolute;
  left: 50%;
  top: -50%;
  margin-left: -15em;
  text-align: center;
  transform-origin: center bottom;
  transform-style: preserve-3d;
  /* Define two variables as the loop limits */
  /* Create a Parametric mixin and add a guard operation */
  /* the mixin is called, css outputted and iterations called */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
  /* As the mixin is called CSS is outputted */
  /* Interation call and operation */
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(0n + 0) {
  transform: rotate(-20deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(1n + 0) {
  transform: rotate(0deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(2n + 0) {
  transform: rotate(20deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(3n + 0) {
  transform: rotate(40deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(4n + 0) {
  transform: rotate(60deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(5n + 0) {
  transform: rotate(80deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(6n + 0) {
  transform: rotate(100deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(7n + 0) {
  transform: rotate(120deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(8n + 0) {
  transform: rotate(140deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(9n + 0) {
  transform: rotate(160deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(10n + 0) {
  transform: rotate(180deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(11n + 0) {
  transform: rotate(200deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(12n + 0) {
  transform: rotate(220deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(13n + 0) {
  transform: rotate(240deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(14n + 0) {
  transform: rotate(260deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(15n + 0) {
  transform: rotate(280deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(16n + 0) {
  transform: rotate(300deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(17n + 0) {
  transform: rotate(320deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper:nth-child(18n + 0) {
  transform: rotate(340deg) translateZ(0);
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper .number {
  font-size: 15em;
  font-weight: 900;
  color: #fff;
  position: relative;
  top: 4em;
  transform: scale(0.6) translateZ(0) rotateX(10deg) translateY(-7em);
  opacity: 0;
  transition: transform .3s, opacity .25s;
  line-height: 1.75;
  /* Fix for Signa font */
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vikinglotto-rotary-selector .selector-numbers .number-wrapper .number {
    top: 4.4em;
  }
}
@media screen and (min-resolution: 2dppx) and (min-width: 950px) {
  .vikinglotto-rotary-selector .selector-numbers .number-wrapper .number {
    top: 3.9em;
  }
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view .number {
  cursor: pointer;
  transform: scale(0.7) translateY(-7.75em) translateZ(0) rotateX(20deg);
  opacity: .85;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view .number {
    top: 4.4em;
  }
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus1'] .number {
  transform: scale(0.7) translateY(-7.75em) translateZ(0) rotateX(20deg) translateX(1em) rotateZ(5deg);
}
@media (min-width: 950px) {
  .vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus1'] .number {
    transform: scale(0.7) translate3d(1.6em, -8.3em, 0.2em) rotateX(24deg) rotateY(0deg) rotateZ(-8deg);
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus1'] .number {
    transform: scale(0.7) translateY(-8.5em) translateZ(0) rotateX(20deg) translateX(1.5em) rotateZ(-5deg);
  }
}
@media all and (-ms-high-contrast: none) and all and (-ms-high-contrast: none), (-ms-high-contrast: active) and all and (-ms-high-contrast: none), all and (-ms-high-contrast: none) and (-ms-high-contrast: active), (-ms-high-contrast: active) and (-ms-high-contrast: active), all and (-ms-high-contrast: none) and (max-width: 950px), (-ms-high-contrast: active) and (max-width: 950px) {
  .vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus1'] .number {
    transform: scale(0.7) translateY(-7.75em) translateZ(0) rotateX(20deg) translateX(1em) rotateZ(5deg);
  }
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus1'] .number {
  transform: scale(0.7) translateY(-7.75em) translateZ(0) rotateX(20deg) translateX(-1em) rotateZ(-5deg);
}
@media (min-width: 950px) {
  .vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus1'] .number {
    transform: scale(0.7) translate3d(-1.6em, -8.3em, 0.2em) rotateX(16.4deg) rotateY(0deg) rotateZ(8deg);
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus1'] .number {
    transform: scale(0.7) translateY(-8.5em) translateZ(0) rotateX(20deg) translateX(-1.5em) rotateZ(5deg);
  }
}
@media all and (-ms-high-contrast: none) and all and (-ms-high-contrast: none), (-ms-high-contrast: active) and all and (-ms-high-contrast: none), all and (-ms-high-contrast: none) and (-ms-high-contrast: active), (-ms-high-contrast: active) and (-ms-high-contrast: active), all and (-ms-high-contrast: none) and (max-width: 950px), (-ms-high-contrast: active) and (max-width: 950px) {
  .vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus1'] .number {
    transform: scale(0.7) translateY(-7.75em) translateZ(0) rotateX(20deg) translateX(-1em) rotateZ(-5deg);
  }
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus2'] .number {
  transform: scale(0.7) translateY(-7.75em) translateZ(0) rotateX(20deg) translateX(0.5em);
}
@media (min-width: 950px) {
  .vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus2'] .number {
    transform: scale(0.7) translate3d(-0.1em, -9.65em, 1.7em) rotateX(10deg) rotateY(3deg) rotateZ(-18deg);
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus2'] .number {
    transform: scale(0.7) translateX(1em) translateY(-9.2em) rotateX(20deg) rotateZ(-14deg);
  }
}
@media all and (-ms-high-contrast: none) and all and (-ms-high-contrast: none), (-ms-high-contrast: active) and all and (-ms-high-contrast: none), all and (-ms-high-contrast: none) and (-ms-high-contrast: active), (-ms-high-contrast: active) and (-ms-high-contrast: active), all and (-ms-high-contrast: none) and (max-width: 950px), (-ms-high-contrast: active) and (max-width: 950px) {
  .vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='plus2'] .number {
    transform: scale(0.7) translateX(0.5em) translateY(-7.75em) rotateX(20deg);
  }
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus2'] .number {
  transform: scale(0.7) translateY(-7.75em) translateZ(0) rotateX(20deg) translateX(-0.5em);
}
@media (min-width: 950px) {
  .vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus2'] .number {
    transform: scale(0.7) translate3d(0.1em, -9.65em, 1.7em) rotateX(10deg) rotateY(3deg) rotateZ(18deg);
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus2'] .number {
    transform: scale(0.7) translateY(-9.2em) rotateX(20deg) translateX(-1em) rotateZ(14deg);
  }
}
@media all and (-ms-high-contrast: none) and all and (-ms-high-contrast: none), (-ms-high-contrast: active) and all and (-ms-high-contrast: none), all and (-ms-high-contrast: none) and (-ms-high-contrast: active), (-ms-high-contrast: active) and (-ms-high-contrast: active), all and (-ms-high-contrast: none) and (max-width: 950px), (-ms-high-contrast: active) and (max-width: 950px) {
  .vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-view[data-id='minus2'] .number {
    transform: scale(0.7) translateY(-7.75em) rotateX(20deg) translateX(-0.5em);
  }
}
.vikinglotto-rotary-selector .selector-numbers .number-wrapper.in-focus .number {
  transform: scale(1) translateY(-6em) translateZ(1em) rotateX(30deg);
  opacity: 1;
}
.vikinglotto-rotary-selector .selector-foreground {
  width: 50.666em;
  height: 32em;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateZ(7em) scale(0.95333);
}
.vikinglotto-rotary-selector .selector-foreground .navigation-button {
  width: 9.5em;
  height: 10.666em;
  background-color: #0000ff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.vikinglotto-rotary-selector .selector-foreground .navigation-button:before,
.vikinglotto-rotary-selector .selector-foreground .navigation-button:after {
  content: '';
  width: 4em;
  height: 4em;
  border-radius: 50%;
  border-style: solid;
  border-color: transparent transparent transparent #0000ff;
  border-width: 1em;
  box-sizing: border-box;
  position: absolute;
}
.vikinglotto-rotary-selector .selector-foreground .navigation-button .icon {
  width: 2.13em;
  height: 3.46em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #fff;
}
.vikinglotto-rotary-selector .selector-foreground .navigation-button.previous {
  border-radius: 1em 0 0 1em;
  left: 0;
}
.vikinglotto-rotary-selector .selector-foreground .navigation-button.previous:before {
  top: -3em;
  right: -0.7em;
  transform: rotate(-135deg);
}
.vikinglotto-rotary-selector .selector-foreground .navigation-button.previous:after {
  bottom: -3.1em;
  right: -0.7em;
  transform: rotate(135deg);
}
.vikinglotto-rotary-selector .selector-foreground .navigation-button.next {
  border-radius: 0 1em 1em 0;
  right: 0;
}
.vikinglotto-rotary-selector .selector-foreground .navigation-button.next:before {
  top: -3em;
  left: -0.8em;
  transform: rotate(-45deg);
}
.vikinglotto-rotary-selector .selector-foreground .navigation-button.next:after {
  bottom: -3em;
  left: -0.8em;
  transform: rotate(45deg);
}
.vikinglotto-rotary-selector .selector-foreground .number-frame {
  width: 32em;
  height: 32em;
  border-radius: 1em;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  overflow: hidden;
}
.vikinglotto-rotary-selector .selector-foreground .number-frame:before,
.vikinglotto-rotary-selector .selector-foreground .number-frame:after {
  content: '';
  width: 18%;
  height: 100%;
  position: absolute;
  top: 0;
}
.vikinglotto-rotary-selector .selector-foreground .number-frame:before {
  left: 0;
  background: linear-gradient(to right, #0000ff 0%, #0000ff 20%, rgba(0, 0, 255, 0) 100%);
}
.vikinglotto-rotary-selector .selector-foreground .number-frame:after {
  right: 0;
  background: linear-gradient(to right, rgba(0, 0, 255, 0) 0%, #0000ff 80%, #0000ff 100%);
}
.vikinglotto-rotary-selector .selector-foreground .number-frame .text-box {
  width: 21.333em;
  text-align: center;
  margin: auto;
  padding-top: 1.65em;
  border-top: 0.2rem solid #fff;
  position: absolute;
  top: 21em;
  left: 0;
  right: 0;
}
.vikinglotto-rotary-selector .selector-foreground .number-frame .text-box .text {
  font-size: 4.266em;
  color: #fff;
}
