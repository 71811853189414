.vikinglotto-receipt .vikinglotto-section {
  background-color: #fff;
  border-color: #fff;
}
@media (min-width: 950px) {
  .vikinglotto-receipt .vikinglotto-section {
    padding: 4rem 0;
  }
}
.vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper {
  width: 100%;
  max-width: 86.7vw;
  padding: 8vw 0 4vw;
}
@media (min-width: 950px) {
  .vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper {
    max-width: 96rem;
    padding: 0;
  }
}
.vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation {
  margin: auto;
}
@media (min-width: 950px) {
  .vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation {
    width: 100%;
    max-width: 96rem;
  }
}
.vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation svg.receipt-icon {
  width: 13.33vw;
  height: 13.33vw;
  margin: 0 auto 3.5vw;
}
@media (min-width: 950px) {
  .vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation svg.receipt-icon {
    width: 6.4rem;
    height: 6.4rem;
    margin: 0 auto 2rem;
  }
}
.vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation .receipt-text {
  font-size: 3.75vw;
  color: #1d1d1d;
  margin: 0 auto 1.5rem;
}
@media (min-width: 950px) {
  .vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation .receipt-text {
    max-width: 100%;
    font-size: 2rem;
    line-height: 2.4rem;
  }
}
.vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation .reminder-alert {
  margin: 2rem;
  color: black;
  font-size: 3vw;
}
@media (min-width: 950px) {
  .vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation .reminder-alert {
    font-size: 1.6rem;
  }
}
.vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation .reminder-alert input[type=checkbox] {
  display: none;
}
.vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation .reminder-alert input[type=checkbox]:checked + label .checkbox:after {
  content: '';
  position: absolute;
  border: 0.3vw solid black;
  border-left: 0;
  border-top: 0;
  transform: rotate(45deg);
  font-size: 3.4vw;
  left: 0.3em;
  top: 0.1em;
  width: 0.3em;
  height: 0.5em;
}
@media (min-width: 950px) {
  .vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation .reminder-alert input[type=checkbox]:checked + label .checkbox:after {
    border: 0.2rem solid black;
    border-left: 0;
    border-top: 0;
    font-size: 2rem;
  }
}
.vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation .reminder-alert label {
  background: #eee;
  display: inline-block;
  padding: 4vw 4vw 4vw 10vw;
  border-radius: 0.5rem;
  position: relative;
  text-align: left;
  line-height: 1.5;
  user-select: none;
}
@media (min-width: 950px) {
  .vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation .reminder-alert label {
    padding: 2.5rem 2.5rem 2.5rem 6rem;
  }
}
.vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation .reminder-alert label .checkbox {
  background: white;
  width: 3.5vw;
  height: 3.5vw;
  left: 4vw;
  top: 4.5vw;
  border-radius: 0.4rem;
  content: '';
  display: inline-block;
  vertical-align: text-top;
  border: 0.1rem solid #a0a0a0;
  position: absolute;
}
@media (min-width: 950px) {
  .vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation .reminder-alert label .checkbox {
    width: 2.0rem;
    height: 2.0rem;
    left: 2.5rem;
    top: 2.6rem;
  }
}
.vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation .reminder-alert.reminder-alert--loading label {
  overflow: hidden;
}
.vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation .reminder-alert.reminder-alert--loading label:before {
  position: absolute;
  content: "";
  top: -50%;
  bottom: -50%;
  left: -200%;
  width: 3rem;
  background-color: rgba(0, 0, 0, 0.05);
  animation: reminder-alert--loading 1000ms linear infinite;
  transform: rotate(20deg);
}
.vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation .reminder-alert.reminder-alert--loading label:after {
  position: absolute;
  content: "";
  top: -50%;
  bottom: -50%;
  left: -200%;
  width: 1.5rem;
  background-color: rgba(0, 0, 0, 0.05);
  animation: reminder-alert--loading 1000ms linear infinite;
  animation-delay: 500ms;
  transform: rotate(20deg) translateX(-2rem);
}
@keyframes reminder-alert--loading {
  100% {
    left: 300%;
  }
}
.vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .receipt-confirmation .go-to-coupon {
  border-bottom: 0.1rem solid #000;
  cursor: pointer;
  display: inline-block;
}
.vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .hr {
  border-color: #1d1d1d;
  display: none;
}
@media (min-width: 950px) {
  .vikinglotto-receipt .vikinglotto-section .vikinglotto-content-wrapper .hr {
    display: block;
    margin: 3.5rem auto 2.5rem;
  }
}
