@import url("../Mixins/_mixins.less");

.vikinglotto-receipt,
.vikinglotto-no-open-draw {
  .countdown {
    width: 100%;
    max-width: 82vw;
    margin: auto;
    padding-top: 6vw;

    @media (min-width: @vikinglotto-desktop-width) {
      max-width: 96rem;
      padding: 1.5rem 0;
    }

    .countdown-headline {
      font-size: 4.67vw;
      font-weight: 900;
      font-style: italic;
      text-transform: uppercase;
      color: @vikinglotto-darkblue;
      margin-top: 0;

      @media (min-width: @vikinglotto-desktop-width) {
        font-size: 2.6rem;
        line-height: 0.9;
      }
    }

    .number-container {
      display: inline-block;
      margin: 0 3vw;
      color: @vikinglotto-darkblue;

      @media (min-width: @vikinglotto-desktop-width) {
        margin: 0 1.5rem;
      }

      .number {
        font-size: 8vw;
        font-weight: bold;
        margin-right: 1vw;

        @media (min-width: @vikinglotto-desktop-width) {
          font-size: 3.8rem;
          line-height: normal;
          margin-right: .5rem;
        }
      }

      .time-denominator {
        font-size: 5.4vw;
        line-height: 5.9vw;

        @media (min-width: @vikinglotto-desktop-width) {
          font-size: 2rem;
          line-height: 2.4rem;
        }
      }
    }
  }
}
