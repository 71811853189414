.vikinglotto__curved-bg {
  position: absolute;
  background: #0000ff;
  top: 0;
  bottom: 1rem;
  left: -6rem;
  right: -6rem;
  pointer-events: unset;
  z-index: -1;
  border-top-left-radius: 50% 5rem;
  border-top-right-radius: 50% 5rem;
  border-bottom-left-radius: 50% 5rem;
  border-bottom-right-radius: 50% 5rem;
  overflow: hidden;
}
@media screen and (min-width: 1216px) {
  .vikinglotto__curved-bg {
    left: -7.5vw;
    right: -7.5vw;
    border-top-left-radius: 60% 5.5rem;
    border-top-right-radius: 60% 5.5rem;
    border-bottom-left-radius: 60% 5.5rem;
    border-bottom-right-radius: 60% 5.5rem;
  }
}
@media screen and (min-width: 1440px) {
  .vikinglotto__curved-bg {
    left: 0;
    right: 0;
    border-top-left-radius: 770% 35rem;
    border-top-right-radius: 770% 35rem;
    border-bottom-left-radius: 770% 35rem;
    border-bottom-right-radius: 770% 35rem;
  }
}
.vikinglotto__curved-bg.vikinglotto__curved-bg--top {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.vikinglotto__curved-bg.vikinglotto__curved-bg--bottom {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
