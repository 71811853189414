@import url("../Mixins/_mixins.less");

.region-vikinglotto {

  .header-spot {
    background-color: @vikinglotto-darkblue;

    .content-wrapper {
      min-height: 56vw;
      font-style: italic;
      color: @vikinglotto-white;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 7vw 0;
      box-sizing: border-box;

      @media (min-width: @vikinglotto-desktop-width) {
        min-height: 40rem;
        padding: 8rem 0;
      }

      .top-logo {
        img {
          width: 10vw;
          height: 10vw;

          @media (min-width: @vikinglotto-desktop-width) {
            width: 6.4rem;
            height: 6.4rem;
          }
        }
      }

      h1 {
        font-size: 7vw;
        text-transform: uppercase;
        line-height: 0.9;

        @media (min-width: @vikinglotto-desktop-width) {
          font-size: 3.5rem;
        }
      }

      h2 {
        font-size: 4.5vw;
        text-transform: uppercase;
        line-height: 0.8;
        margin: 0;

        @media (min-width: @vikinglotto-desktop-width) {
          font-size: 2rem;
        }
      }
    }
  }
}

.vikinglotto-no-open-draw {

  .vikinglotto-section {
    background-color: @vikinglotto-white;
    border-color: @vikinglotto-white;

    .vikinglotto-content-wrapper {
      min-height: 31vw;
      font-style: italic;
      z-index: 4;
      padding: 8vw;

      @media (min-width: @vikinglotto-desktop-width) {
        min-height: 22rem;
        padding: 8rem 0 4rem;
      }

      .no-open-draw-text {
        max-width: 86.5vw;
        font-size: 5vw;
        font-weight: 900;
        color: @vikinglotto-darkblue;
        line-height: 1;
        margin: 1vw auto;
        text-transform: uppercase;

        @media (min-width: @vikinglotto-desktop-width) {
          font-size: 3.5rem;
          margin: 1rem auto;
        }
      }
    }
  }
}
