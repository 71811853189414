.vikinglotto-promotion-spot .vikinglotto-section {
  position: relative;
  border-color: #0000ff;
}
.vikinglotto-promotion-spot .vikinglotto-section > * {
  position: relative;
  z-index: 2;
}
.vikinglotto-promotion-spot .vikinglotto-section .vikinglotto-image-wrapper {
  margin-top: -15vw;
  padding-top: 15vw;
}
@media (min-width: 950px) {
  .vikinglotto-promotion-spot .vikinglotto-section .vikinglotto-image-wrapper {
    margin-top: -10rem;
    padding-top: 10rem;
  }
}
.vikinglotto-promotion-spot .vikinglotto-section .vikinglotto-image-wrapper .vikinglotto-image {
  display: block;
  margin: auto;
  width: 75vw;
  height: auto;
  max-width: 100%;
}
@media (min-width: 950px) {
  .vikinglotto-promotion-spot .vikinglotto-section .vikinglotto-image-wrapper .vikinglotto-image {
    width: auto;
  }
}
.vikinglotto-promotion-spot .vikinglotto-section .btn {
  margin-top: 6.5vw;
}
@media (min-width: 950px) {
  .vikinglotto-promotion-spot .vikinglotto-section .btn {
    margin-top: 3rem;
  }
}
