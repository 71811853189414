﻿@import url("../Mixins/_mixins.less");

.vikinglotto-game-promotion-spot {

  .btn {
    margin: 2vw auto;

    @media(min-width:@vikinglotto-desktop-width) {
      margin: 1rem auto;
    }
  }
  //font-size: 0; // remove spacing between elements since they are inline
  &.big {
    font-size: 0;

    .vikinglotto-section {
      background-color: @vikinglotto-blue;

      .vikinglotto-content-wrapper {
        .info-text {
          @media (min-width: @vikinglotto-desktop-width) {
            width: 50rem;
          }
        }
      }
    }
  }

  &.small {
    .vikinglotto-section {
      background: transparent;
    }
  }

  .vikinglotto-section {
    .vikinglotto-content-wrapper {
      padding: 6rem 2rem;
      box-sizing: border-box;
    }

    &.small {
      display: inline-block;
      width: 100%;

      @media (max-width: @vikinglotto-desktop-width) {
        display: block;
        width: 100%;
      }
    }

    .vikinglotto-content-wrapper {
      //padding: 5vw 0;
      position: relative;
      text-align: center;

      @media (min-width: @vikinglotto-desktop-width) {
        max-width: 50rem;
      }

      .vikinglotto-fast-play {
        display: inline-block;
        margin: 0 auto;

        .fast-play-image {
          margin-bottom: 4rem;
        }
      }
    }
  }
}
