@import url("../Mixins/_mixins.less");

.vikinglotto-game-tabs-wrapper {
  .clearfix();
  background-color:@vikinglotto-mediumblue;
  .tabs-navigation {
    width:14vw;
    display:inline-block;
    float:left;
    @media (min-width: @vikinglotto-desktop-width) {
      width:7rem;
    }
		
    .tab-navigation-item {
      padding-top:100%;
      position:relative;
      cursor:pointer;
      overflow:hidden;
      .tab-navigation-icon {
        width:4.8vw;
        height:4.8vw;
        text-align:center;
        fill:@vikinglotto-white;
        color: @vikinglotto-white;
        position:absolute;
        margin:auto;
        top:50%;
        left:0;
        right:0;
        transform:translate3d(0,-50%,0);
        @media (min-width: @vikinglotto-mobile-width) {
          width:2.5rem;
          height:2.5rem;
        }
      }

      &.active {
        background-color:@vikinglotto-darkblue;
        .tab-navigation-icon {
          fill:@vikinglotto-white;
          color: @vikinglotto-white;
        }
      }
    }
  }
  .tabs-container {
    width:86vw;
    font-size:0;
    line-height:0;
    background-color:@vikinglotto-darkblue;
    overflow:hidden;
    @media (min-width: @vikinglotto-desktop-width) {
      width: ~"calc(100% - 7rem)";
    }

    .tab-container {
      @media (min-width: @vikinglotto-desktop-width) {
        width: ~"calc(100% - 6rem)";
        margin:auto;
        overflow:hidden;
      }
      .inner-wrapper {
        width:72.8vw;
        font-size:3.733vw;
        color:#fff;
        padding:0.6vw 0 1.9vw;
        margin:auto;
                
        @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
          font-size: 1.8rem;
        }

        @media (min-width: @vikinglotto-desktop-width) {
          width:100%;
          font-size:2.8rem;
          padding:0 0 1rem;
        }
        .tab-headline {
          font-size:3.733vw;
          font-weight:bold;
          text-transform:uppercase;
          margin:0;
          padding:5.4vw 0 5.5vw;
          border-bottom:1px solid @vikinglotto-white;
          line-height:.7;

          @media (min-width: @vikinglotto-desktop-width) {
            font-size:1.6rem;
            padding:3.5rem 0 2.5rem;
          }
        }

        p {
          font-size:3.733vw;
          line-height:1.3;
          margin:0;
          padding:2.9vw 0 1vw;
                    
          @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
            font-size: 1.8rem;
            line-height: 1.5;
          }

          @media (min-width: @vikinglotto-desktop-width) {
            width:58.5rem;
            font-size:1.6rem;
            line-height:1.5;
            padding:2.5rem 0 .6rem;
            margin:auto;
          }

          &.explanation {
            font-size:2.8vw;
            line-height:1.3;
            margin:0;
            padding: 4vw 0;
            @media (min-width: @vikinglotto-desktop-width) {
              width:58.5rem;
              font-size:1.4rem;
              line-height:1.5;
              padding: 0 0 .6rem 0;
              margin:auto;
            }
          }
        }

        ol {
          margin:0;
          padding:4vw 0;
          list-style-type:none;
          line-height:1.3;
          @media (min-width: @vikinglotto-desktop-width) {
            width:58.5rem;
            font-size:1.6rem;
            padding:2rem 0 1.8rem;
            margin:auto;
          }

          li {
            counter-increment:custom-counter;
            position:relative;
            padding:7.7vw 0 4.8vw;
            @media (min-width: @vikinglotto-desktop-width) {
              padding:4.1rem 0 2.4rem;
            }

            &:before {
              content:counter(custom-counter);
              width:6vw;
              height:6vw;
              font-size:2.933vw;
              font-weight:bold;
              color:@vikinglotto-darkgray;
              border-radius:50%;
              background-color:@vikinglotto-white;
              margin:auto;
              position:absolute;
              top:0;
              left:0;
              right:0;
              line-height:6vw; 

              @media (min-width: @vikinglotto-desktop-width) {
                width:3.4rem;
                height:3.4rem;
                font-size:1.6rem;
                line-height:3.4rem;
              }
            }
          }
        }

        .simple-custom-table {
          width:100%;
          border-collapse:inherit;
          border-radius:6px;
          overflow:hidden;
          line-height:1.3;
          //margin:3.2vw 0 4.6vw;
          @media (min-width: @vikinglotto-desktop-width) {
            width:45rem;
            //margin:1.7rem auto 3.2rem;
          }

          thead {
            th {
              background-color: #dddddd;
              font-size:3.2vw;
              color:@vikinglotto-lightgray;
              padding:3vw 0 3vw;
              @media (min-width: @vikinglotto-desktop-width) {
                font-size:1.6rem;
                padding:1.8rem 0 1.7rem;
              }
              &:first-child {
                width:39%;
                border-right:.2rem solid rgba(163, 166, 171, 0.4);
                @media (min-width: @vikinglotto-desktop-width) {
                  width:40%;
                  border-right: 0.1rem solid rgba(163, 166, 171, 0.4);
                }
              }
            }
          }

          tbody {
            tr {
              &:nth-child(odd) {
                background-color:#fff;
              }
              &:nth-child(even) {
                background-color:#f1f2f2;
              }
              td {
                font-size:3.2vw;
                color:@vikinglotto-lightgray;
                padding:3.4vw 0 3.3vw;
                @media (min-width: @vikinglotto-desktop-width) {
                  font-size:1.5rem;
                  padding:1.6rem 0 1.6rem;
                }
                &:first-child {
                  border-right:.2rem solid rgba(163, 166, 171, .4);
                  @media (min-width: @vikinglotto-desktop-width) {
                    border-right:.1rem solid rgba(163, 166, 171, .4);
                  }
                }
              }
            }
          }
        }

        .table-foldable-wrapper {
          max-height:570vw;
          position:relative;
          overflow:hidden;
          transition:max-height .5s linear;
          @media (min-width: @vikinglotto-desktop-width) {
            max-height:268rem;
          }

          .expand-action-wrapper {
            text-align:center;
            height:15rem;
            margin:auto;
            position:absolute;
            bottom:0;
            left:0;
            right:0;
            background: -moz-linear-gradient(top,  rgba(197,0,5,0) 0%, rgba(197,0,5,0) 1%, rgba(197,0,5,1) 90%, rgba(197,0,5,1) 100%);
            background: -webkit-linear-gradient(top,  rgba(197,0,5,0) 0%,rgba(197,0,5,0) 1%,rgba(197,0,5,1) 90%,rgba(197,0,5,1) 100%);
            background: linear-gradient(to bottom,  rgba(197,0,5,0) 0%,rgba(197,0,5,0) 1%,rgba(197,0,5,1) 90%,rgba(197,0,5,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c50005', endColorstr='#c50005',GradientType=0 );
						
            .btn {
              position:relative;
              bottom:-7rem;
              background:@vikinglotto-white;
              display:inline-block;
              text-transform:uppercase;
            }
          }

          &.active {
            padding-bottom:16rem;
            .simple-custom-table {
              margin-bottom:14rem;
            }
            .expand-action-wrapper {
              bottom:-1rem;
              .btn {
                bottom:-5rem;
              }
            }
          }
        }
      }
    }

    .accordions-container {
      border-radius:6px;
      overflow:hidden;
      line-height:1.3;
      margin:3.2vw auto 0;
      @media (min-width: @vikinglotto-mobile-width) {
        width:45rem;
        margin:1.7rem auto 2.2rem;
      }

      .print-button {
        font-size:0;
        line-height:0;
        background-color:@vikinglotto-blue;
        float:right;
        border-radius:6px 6px 0 0;
        padding:2vw 3vw 3vw;
        margin-bottom:-1vw;
        box-sizing:border-box;
        position:relative;
        cursor:pointer;
        transition:transform .1s ease-out;
        @media (min-width: @vikinglotto-desktop-width) {
          padding:1rem 1.5rem 2rem;
          margin-bottom:-.5rem;
          bottom:-.5rem;
        }
        .icon-print {
          width:4vw;
          height:4vw;
          fill:@vikinglotto-white;
          @media (min-width: @vikinglotto-desktop-width) {
            width:2.5rem;
            height:2.5rem;
          }
        }

        &:hover {
          @media (min-width: @vikinglotto-desktop-width) {
            transform:translate3d(0, -.5rem, 0);
          }
        }
      }

      .accordions-header {
        font-size:3.733vw;
        color:#fff;
        background-color:@vikinglotto-blue;
        padding:2.935vw 0;
        border-radius:6px 6px 0 0;
        clear:both;

        @media (min-width: @vikinglotto-desktop-width) {
          font-size:1.6rem;
          padding:1.9rem 0;
        }
      }
    }
    .accordion-wrapper {
      line-height:1.3;
      border-bottom:.2rem solid #fff;
      @media (min-width: @vikinglotto-desktop-width) {
        border-bottom:.1rem solid #fff;
      }
      .accordion-header {
        text-align:left;
        background-color:#f1f2f2;
        padding:4.267vw 8vw 4.267vw 4vw;
        position:relative;
        cursor:pointer;
        @media (min-width: @vikinglotto-desktop-width) {
          padding:0.4rem 3rem 1rem 2rem;
        }
        .title {
          font-size:3.733vw;
          font-weight:bold;
          color:@vikinglotto-lightgray;
          text-transform:uppercase;
          @media (min-width: @vikinglotto-desktop-width) {
            font-size:1.5rem;
          }
        }
        .icon {
          width:2.667vw;
          height:2.667vw;
          position:absolute;
          top:5.7vw;
          right:3.966vw;
          fill:#777a7f;
          overflow:visible;
          @media (min-width: @vikinglotto-desktop-width) {
            width: 1.3rem;
            height: 1.3rem;
            top: 2.3rem;
            right: 1.9rem;
          }
          use {
            transition:transform .25s linear, opacity .25s linear;
          }
          .arrow-down {
            transform:translateY(-.7vw);
            @media (min-width: @vikinglotto-desktop-width) {
              transform:translateY(-.5rem);
            }
          }
          .close {
            opacity:0;
            transform:translateY(-150%);
          }
        }
      }
      .accordion-body {
        background-color:#fff;
        overflow:hidden;
        max-height:0;
        transition:max-height .25s linear;
        .accordion-table {
          width:100%;
          line-height:1.3;
          font-size:3.2vw;
          color:@vikinglotto-lightgray;
          @media (min-width: @vikinglotto-desktop-width) {
            font-size:1.5rem;
          }

          thead {
            tr {
              border-bottom:.2rem solid #f1f2f2;
              @media (min-width: @vikinglotto-desktop-width) {
                border-bottom:.1rem solid #f1f2f2;
              }
              td {
                padding:2.6vw 0 3.9vw;
                @media (min-width: @vikinglotto-desktop-width) {
                  padding:1.6rem 0 1.5rem;
                }
                span {
                  display:none;
                  @media (min-width: @vikinglotto-desktop-width) {
                    display:inline-block;
                    padding-left:.5rem;
                  }
                }
              }
            }
          }

          tbody {
            tr {
              &:first-child {
                td {
                  padding-top:2.5vw;
                  @media (min-width: @vikinglotto-desktop-width) {
                    padding-top:1.4rem;
                  }
                }
              }
              &:last-child {
                td {
                  padding-bottom:3.4vw;
                  @media (min-width: @vikinglotto-desktop-width) {
                    padding-bottom:1.7rem;
                  }
                }
              }
              td {
                width:17.25%;
                padding-top:1vw;
                padding-bottom:.9vw;
                @media (min-width: @vikinglotto-desktop-width) {
                  width:18.5%;
                  padding-top:.5rem;
                  padding-bottom:.5rem;
                }
                &:last-child {
                  width:31%;
                  @media (min-width: @vikinglotto-desktop-width) {
                    width:26%;
                  }
                }
              }
            }
          }
        }

        .simple-accordion-table {
          width:100%;
          line-height:1.3;
          font-size:3.733vw;
          color:@vikinglotto-lightgray;
          text-align:left;
          @media (min-width: @vikinglotto-desktop-width) {
            font-size:1.5rem;
          }

          tbody {
            tr {
              td {
                width:78%;
                padding:2.8vw 0 2.8vw 4vw;
                @media (min-width: @vikinglotto-desktop-width) {
                  width:88%;
                  padding:1.6rem 0 1.6rem 2rem;
                }
                &:last-child {
                  width:22%;
                  padding:2.8vw 0;
                  @media (min-width: @vikinglotto-desktop-width) {
                    width:12%;
                    padding:1.6rem 0;
                  }
                }
              }
              &:first-child {
                td {
                  padding:4.5vw 0 3.3vw 4vw;
                  @media (min-width: @vikinglotto-desktop-width) {
                    padding:1.6rem 0 1.6rem 2rem;
                  }
                  &:last-child {
                    padding:4.5vw 0 3.3vw 0;
                    @media (min-width: @vikinglotto-desktop-width) {
                      padding:1.6rem 0;
                    }
                  }
                }
              }
              &:last-child {
                td {
                  padding:3.3vw 0 4vw 4vw;
                  @media (min-width: @vikinglotto-desktop-width) {
                    padding:1.6rem 0 1.6rem 2rem;
                  }
                  &:last-child {
                    padding:3.3vw 0 4vw 0;
                    @media (min-width: @vikinglotto-desktop-width) {
                      padding:1.6rem 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.active {
        .accordion-header {
          .icon {
            .arrow-down {
              opacity:0;
              transform:translateY(150%);
            }
            .close {
              opacity:1;
              transform:translateY(-.4rem);
            }
          }
        }
        .accordion-body {
          max-height:40rem;
        }
      }
      &:last-child {
        border-bottom:none;
        .accordion-body {
          .accordion-table {
            tbody {
              tr {
                &:last-child {
                  td {
                    padding-bottom:3.6vw;
                    @media (min-width: @vikinglotto-desktop-width) {
                      padding-bottom:1.5rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .game-container {
      .rows-container {
        width:86.835%;
        @media (min-width: @vikinglotto-desktop-width) {
          width:91.6rem;
        }

        @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
          width: 60%;
        }

        .number-picker-container {
          text-align:left;
        }
      }
    }
  }
}
