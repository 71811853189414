﻿@import (reference) "Mixins/_mixins.less";

.btn {
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 1vw;
  cursor: pointer;
  color: #4f5254;
  font-weight: bold;
  font-size: 3.8vw;
  line-height: 10.7vw;
  padding: 0 4vw;
  display: inline-block;
  position: relative;
  vertical-align: top;
  text-decoration: none;
  background-color: @vikinglotto-btn-color;
  min-width: 32vw;
  overflow: hidden;

  @media (min-width: @vikinglotto-desktop-width) {
    min-width: 12rem;
    font-size: 1.4rem;
    line-height: 4rem;
    border-radius: 5px;
    padding: 0 1.2rem;
  }

  @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
    font-size: 3vw;
    line-height: 8vw;
  }

  &:hover {
    background-color: @vikinglotto-btn-color-hover;
  }

  &.cta {
    background-color: @vikinglotto-cta-yellow;
    color: #2b2b2b // checked the styles and saw that the value is #2b2b2b not #322b00;
  }

  &.grey {
    color: #fff;
    background-color: #a3a6ab;
  }

  &.close {
    position: absolute;
    right: 0;
    width: 4.7vw;
    height: 4.7vw;
    padding: 0;
    background-color: transparent;

    @media (min-width: @vikinglotto-desktop-width) {
      width: 2rem;
      height: 2rem;
    }

    &:before,
    &:after {
      content: ' ';
      position: absolute;
      left: 45%;
      top: -15%;
      display: block;
      height: 6vw;
      width: 0.7vw;
      background-color: #4f5254;

      @media (min-width: @vikinglotto-desktop-width) {
        height: 2.6rem;
        width: 0.2rem;
      }
    }

    &:before {
      transform: rotate(45deg) translateZ(0);
    }

    &:after {
      transform: rotate(-45deg) translateZ(0);
    }
  }
}


// Checkbox:
// currently only an iOS wannabe skin exists
@vikinglotto-radio-switch-radius: 7.3vw;
@vikinglotto-radio-switch-width: 14vw;
@vikinglotto-radio-switch-border-width: 0.6vw;

@vikinglotto-desktop-radio-switch-radius: 2.8rem;
@vikinglotto-desktop-radio-switch-width: 6rem;
@vikinglotto-desktop-radio-switch-border-width: 0.2rem;

.vikinglotto-checkbox {
  text-align: left; // this should not be neccessary, we should remove text align from parent
  position: relative;
  color: #999;
  display: inline-block;

  > label {
    cursor: pointer;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    .user-select(none);
  }

  // iOS-like switch, I summon thee!
  .ios-skin {
    float: left;
    position: relative;
    display: inline-block;
    height: @vikinglotto-radio-switch-radius;
    cursor: pointer;
    top: 1.5vw;

    @media (min-width: @vikinglotto-desktop-width) {
      height: @vikinglotto-desktop-radio-switch-radius;
      top: .9rem;
    }

    > input[type="checkbox"] {
      box-sizing: border-box;
      padding: 0;
    }

    > input {
      line-height: normal;
      position: absolute;
      z-index: -1;
      visibility: hidden;
      opacity: 0;
      font-family: inherit;
      font-size: 100%;
      margin: 0;
    }

    // Body of the checkbox wherein the switch
    // lives and moves
    .ios-skin_body {
      position: relative;
      display: inline-block;
      width: @vikinglotto-radio-switch-width;
      height: @vikinglotto-radio-switch-radius;
      border-radius: @vikinglotto-radio-switch-radius;
      background-color: #fff;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transition: background-color 250ms cubic-bezier(.34, 1.61, .7, 1);
      transition: background-color 250ms cubic-bezier(.34, 1.61, .7, 1);

      @media (min-width: @vikinglotto-desktop-width) {
        width: @vikinglotto-desktop-radio-switch-width;
        height: @vikinglotto-desktop-radio-switch-radius;
        border-radius: @vikinglotto-desktop-radio-switch-radius;
      }
    }

    // The switch is either found on the left or
    // the right side, depending on the state
    @vikinglotto-radio-switch-pos: @vikinglotto-radio-switch-width - @vikinglotto-radio-switch-radius;
    @vikinglotto-desktop-radio-switch-pos: @vikinglotto-desktop-radio-switch-width - @vikinglotto-desktop-radio-switch-radius;

    > :checked ~ .ios-skin_body .ios-skin_switch {
      -webkit-transform: translateX(@vikinglotto-radio-switch-pos);
      transform: translateX(@vikinglotto-radio-switch-pos);

      @media (min-width: @vikinglotto-desktop-width) {
        -webkit-transform: translateX(@vikinglotto-desktop-radio-switch-pos);
        transform: translateX(@vikinglotto-desktop-radio-switch-pos);
      }
    }

    // A checkmark or a dash is visible
    // in the empty space of the body of
    // the checkbox
    @vikinglotto-radio-checkmark-length: 2.9vw;
    @vikinglotto-radio-checkmark-width: 0.8vw;
    @vikinglotto-desktop-radio-checkmark-length: 1.5rem;
    @vikinglotto-desktop-radio-checkmark-width: 0.4rem;

    .checkmark {
      position: absolute;
      right: 5.5vw;
      bottom: 3.8vw;
      z-index: 1;
      // Unchecked checkboxes show a ´dash´
      @media (min-width: @vikinglotto-desktop-width) {
        width: @vikinglotto-desktop-radio-checkmark-width;
        height: @vikinglotto-desktop-radio-checkmark-length;
        right: 0;
        bottom: 0.1rem;
        display: inline-block;
        margin: -1.3rem 2.2rem 0 0;
      }

      &:after {
        content: "";
        position: absolute;
        background-color: @vikinglotto-darkgray;
        height: @vikinglotto-radio-checkmark-width;
        width: @vikinglotto-radio-checkmark-length;

        @media (min-width: @vikinglotto-desktop-width) {
          height: @vikinglotto-desktop-radio-checkmark-width;
          width: @vikinglotto-desktop-radio-checkmark-length;
        }
      }
    }

    > :checked ~ .checkmark {
      .rotate(225deg);
      right: 10.3vw;
      bottom: 2.1vw;
      margin: -1vw 0 0 2.5vw;
      // Checked checkboxes show a ´checkmark´
      @media (min-width: @vikinglotto-desktop-width) {
        right: 4rem;
        bottom: 0.6rem;
        margin: -0.8rem 0 0 1.8rem;
      }

      &:before {
        content: "";
        position: absolute;
        width: @vikinglotto-radio-checkmark-width;
        height: @vikinglotto-radio-checkmark-length;
        background-color: @vikinglotto-darkgray;

        @media (min-width: @vikinglotto-desktop-width) {
          height: @vikinglotto-desktop-radio-checkmark-length;
          width: @vikinglotto-desktop-radio-checkmark-width;
        }
      }

      &:after {
        width: @vikinglotto-radio-checkmark-length / 3 * 2;

        @media (min-width: @vikinglotto-desktop-width) {
          width: @vikinglotto-desktop-radio-checkmark-length / 3 * 2;
          bottom: 1.1rem;
        }
      }
    }

    // The circular switch that lives inside
    // the body and moves from side to side.
    .ios-skin_switch {
      position: absolute;
      /*top: -@vikinglotto-radio-switch-border-width;
        left: -@vikinglotto-radio-switch-border-width;*/
      display: inline-block;
      width: @vikinglotto-radio-switch-radius;
      height: @vikinglotto-radio-switch-radius;
      border: @vikinglotto-radio-switch-border-width solid #fff;
      border-radius: 50%;
      box-sizing: border-box;
      background-color: @vikinglotto-cta-yellow;
      // box-shadow: 0 0.2rem 0.2rem rgba(0,0,0,0.13);
      // Bounces back and fourth cuz of the lolz
      -webkit-transition: 250ms cubic-bezier(.34, 1.61, .7, 1);
      transition: 250ms cubic-bezier(.34, 1.61, .7, 1);

      @media (min-width: @vikinglotto-desktop-width) {
        box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.13);
        width: @vikinglotto-desktop-radio-switch-radius;
        height: @vikinglotto-desktop-radio-switch-radius;
        border: @vikinglotto-desktop-radio-switch-border-width solid #fff;
      }
    }

    // Backgorund color changes when changing
    // the state of the checkbox
    > :not(:checked) ~ .ios-skin_body {
      border-color: transparent;
      background-color: rgba(255, 255, 255, 0.6);
    }

    > :not(:checked) ~ .ios-skin_body .ios-skin_switch {
      border-color: transparent;
      background-color: #fff;
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
    }
  }

  // Optional: label following the checkbox
  label:not(.ios-skin) {
    height: @vikinglotto-radio-switch-radius;
    line-height: @vikinglotto-radio-switch-radius;
    text-align: left;
    position: absolute;
    top: 0;
    left: 2vw;
    vertical-align: middle;
    z-index: 1;
    .user-select(none);

    @media (min-width: @vikinglotto-desktop-width) {
      height: @vikinglotto-desktop-radio-switch-radius;
      line-height: @vikinglotto-desktop-radio-switch-radius;
      left: 1rem;
    }
  }
}

// TO LAYOUT?

.joker-container {
  margin: auto;
  padding: 4vw 0;
  text-align: center;

  @media (min-width: @vikinglotto-desktop-width) {
    padding: 1rem 0 3rem;
  }

  .vikinglotto-checkbox {
    label:not(.ios-skin) {
      position: relative;
      left: -3vw;

      @media (min-width: @vikinglotto-desktop-width) {
        left: -.5rem;
      }

      .joker-label-icon {
        width: 34.8vw;
        height: 6.4vw;
        position: relative;
        top: 1.7vw;

        @media (min-width: @vikinglotto-desktop-width) {
          width: 14.8rem;
          height: 2.8rem;
          top: .3rem;
        }
      }
    }
  }

  .joker-info-text {
    font-size: 3.773vw;
    font-weight: 100;
    color: @vikinglotto-white;

    @media (min-width: @vikinglotto-desktop-width) {
      font-size: 1.6rem;
    }
  }
}
