.vikinglotto-confirm .vikinglotto-section {
  background-color: #fff;
  border-color: #fff;
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display {
  padding-top: 11vw;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display {
    padding-top: 6rem;
    padding-bottom: 0;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header {
  background-color: #fff;
  padding-bottom: 2vw;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header {
    padding-bottom: 2rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .draw-date {
  max-width: 86.5vw;
  font-size: 3.47vw;
  font-weight: bold;
  text-transform: uppercase;
  color: #1d1d1d;
  position: relative;
  z-index: 1;
  padding-bottom: 2.67vw;
  margin: auto;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .draw-date {
    max-width: 96rem;
    font-size: 1.6rem;
    padding-bottom: 1.5rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .alt-text {
  max-width: 86.5vw;
  font-size: 3.47vw;
  font-weight: bold;
  text-transform: uppercase;
  color: #1d1d1d;
  position: relative;
  z-index: 1;
  padding: 3vw 0;
  margin: auto;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .alt-text {
    font-size: 2rem;
    padding: 1.5rem 0;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .confirm-headline {
  max-width: 86.5vw;
  font-size: 5.4vw;
  font-weight: 900;
  font-style: italic;
  text-align: inherit;
  text-transform: uppercase;
  line-height: 6.67vw;
  color: #000078;
  margin: 1vw auto;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .confirm-headline {
    font-size: 3.6rem;
    line-height: 3.8rem;
    margin: 0 auto;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .confirm-subheadline {
  max-width: 86.5vw;
  font-size: 4.5vw;
  color: #000078;
  text-transform: uppercase;
  padding-top: .7rem;
  margin: auto;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .confirm-subheadline {
    font-size: 2.4rem;
    margin: 0 auto 1rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .joker-container {
  display: block;
  padding-top: 2.4vw;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .joker-container {
    padding: 0rem 0 3.5rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .joker-container .joker-type-container {
  margin-top: 1vw;
  font-style: italic;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .joker-container .joker-type-container {
    display: inline-block;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .joker-container .joker-type-container .text {
  width: auto;
  font-size: 4.27vw;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1;
  color: #000078;
  display: inline-block;
  margin: auto;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .joker-container .joker-type-container .text {
    font-size: 2.2rem;
  }
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .joker-container .joker-type-container .text.and {
    margin-left: 1rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .joker-container .joker-type-container svg.icon-joker_logo_vindertal {
  width: 28vw;
  height: 5.2vw;
  transform: translateY(0.6vw);
  margin: 0 1.5vw;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display > div.vikinglotto-confirm-page-header .joker-container .joker-type-container svg.icon-joker_logo_vindertal {
    width: 15.3rem;
    height: 2.9rem;
    -ms-transform: translateY(0.5rem);
    transform: translateY(0.5rem);
    margin: 0 1rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container {
  position: relative;
  overflow: hidden;
  transition: max-height 0.5s;
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row {
  width: 100%;
  font-size: 4.266vw;
  background-color: #fff;
  padding: 5.4vw 6.666vw 5.135vw;
  position: relative;
  box-sizing: border-box;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row {
    font-size: 1.8rem;
    padding: 2.5rem 0 2.2rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row:nth-child(odd) {
  background-color: #ededee;
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row.hidden {
  display: none;
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row.system {
  background-color: #fff;
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row.system .number-row-inner {
  width: auto;
  text-align: center;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row.system .number-row-inner {
    max-width: 56rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row.system .number-row-inner .row-title {
  width: 100%;
  text-transform: uppercase;
  display: block;
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row.system .number-row-inner .row-numbers {
  width: auto;
  line-height: 1.5;
  display: inline-block;
  padding-top: 1rem;
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row.system .number-row-inner .plus-separator {
  width: 1.867vw;
  height: 1.867vw;
  fill: #000078;
  position: static;
  margin: 0 0 0.5vw 1.5vw;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row.system .number-row-inner .plus-separator {
    width: 1rem;
    height: 1rem;
    margin: 0 0 0.1rem 1rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row.system .number-row-inner .viking-number {
  font-weight: bold;
  position: static;
  padding-top: 0;
  margin-left: 1.5vw;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row.system .number-row-inner .viking-number {
    margin-left: 1rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row .number-row-inner {
  font-size: 4vw;
  text-align: left;
  color: #000078;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row .number-row-inner {
    width: 43rem;
    font-size: 2rem;
    position: relative;
    margin: auto;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row .number-row-inner .row-title {
  width: 24%;
  font-size: 4vw;
  font-style: normal;
  font-weight: 600;
  text-transform: unset;
  line-height: 1;
  color: #1d1d1d;
  cursor: default;
  display: inline-block;
  position: relative;
  margin: 0 auto;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row .number-row-inner .row-title {
    width: 27%;
    font-size: 2rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row .number-row-inner .row-numbers {
  font-weight: bold;
  display: inline-block;
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row .number-row-inner .plus-separator {
  width: 1.867vw;
  height: 1.867vw;
  fill: #000078;
  position: absolute;
  top: 7.4vw;
  right: 28.5vw;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row .number-row-inner .plus-separator {
    width: 1rem;
    height: 1rem;
    top: 0.8rem;
    right: 9.3rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row .number-row-inner .viking-number {
  font-weight: bold;
  position: absolute;
  left: 74.5vw;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row .number-row-inner .viking-number {
    left: 35.7rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row .number-row-inner .delete-button {
  width: 8vw;
  height: 8vw;
  position: absolute;
  right: 4vw;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row .number-row-inner .delete-button {
    width: 1.2rem;
    height: 1.4rem;
    top: 50%;
    right: 0;
    cursor: pointer;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row .number-row-inner .delete-button svg.icon-x {
  width: 2.667vw;
  height: 2.667vw;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .number-row .number-row-inner .delete-button svg.icon-x {
    width: 1.2rem;
    height: 1.2rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .bottom-gradient {
  width: 100%;
  height: 10.7vw;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 50%, #ffffff 60%, #fff 100%);
  /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#007db9e8', endColorstr='#FFF', GradientType=0);*/
  transition: opacity 0.5s;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .bottom-gradient {
    height: 7.2rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .bottom-gradient.hidden {
  display: none;
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .rows-container .bottom-gradient .show-more-button {
  text-transform: uppercase;
  color: #000;
  background-color: #feb700;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  margin: 0;
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .terms-container {
  width: 100%;
  font-size: 3.47vw;
  color: #1d1d1d;
  background-color: #ededee;
  line-height: 1.3;
  position: relative;
  padding: 5vw 0 7vw;
  margin: auto;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .terms-container {
    font-size: 1.8rem;
    padding: 2.5rem 0 3rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .terms-container .terms-link {
  color: #1d1d1d;
  cursor: pointer;
  display: block;
}
@media (min-width: 950px) {
  .vikinglotto-confirm .vikinglotto-section .vikinglotto-row-display .terms-container .terms-link {
    padding-top: .5rem;
  }
}
.vikinglotto-confirm .vikinglotto-section .btn {
  margin: 6rem 1rem 2rem;
}
.vikinglotto-confirm .vikinglotto-section .cta-deposit {
  margin: 2rem;
}
