.win-message-container {
  background-color: #000078;
  padding: 5vw 6.67vw 3vw;
}
@media (min-width: 950px) {
  .win-message-container {
    padding: 3rem;
  }
}
.win-message-container .receive-winner-message-icon {
  width: 10vw;
  height: 10vw;
  margin: 0 auto 2.5vw;
}
@media (min-width: 950px) {
  .win-message-container .receive-winner-message-icon {
    width: 6.4rem;
    height: 6.4rem;
    margin: 0 auto;
  }
}
.win-message-container .notice-step {
  color: #fff;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.win-message-container .notice-step .headline {
  width: 100%;
  max-width: 86.5vw;
  font-size: 5.5vw;
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  line-height: 1;
  margin: 1vw auto;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .headline {
    font-size: 5rem;
    margin: 1rem auto .5rem;
  }
}
.win-message-container .notice-step .subheadline {
  width: 100%;
  max-width: 60vw;
  font-size: 4.27vw;
  line-height: 1.5;
  padding: 1rem 0;
  text-transform: none;
  margin: 1vw auto;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .subheadline {
    max-width: 100%;
    font-size: 2.6rem;
    line-height: 1;
    padding-top: 0;
    margin: 1rem auto;
  }
}
.win-message-container .notice-step .checkbox-container {
  margin-top: 1vw;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .checkbox-container {
    display: flex;
    margin-top: 0;
  }
}
.win-message-container .notice-step .checkbox-container .vikinglotto-checkbox {
  display: flex;
  margin-bottom: 6.67vw;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .checkbox-container .vikinglotto-checkbox {
    margin: 0 2.5rem;
  }
}
.win-message-container .notice-step .checkbox-container .vikinglotto-checkbox label {
  display: flex;
  align-items: center;
  position: relative;
}
.win-message-container .notice-step .checkbox-container .vikinglotto-checkbox label:not(.ios-skin) {
  position: relative;
  padding: 0.2vw 0 0 3.33vw;
  margin: 1vw auto;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .checkbox-container .vikinglotto-checkbox label:not(.ios-skin) {
    padding: 0 0 0 2rem;
    margin: 1rem auto;
  }
}
.win-message-container .notice-step .checkbox-container .vikinglotto-checkbox label .media-text {
  width: auto;
  font-size: 4.27vw;
  font-weight: bold;
  font-style: italic;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.1;
  margin: 0;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .checkbox-container .vikinglotto-checkbox label .media-text {
    font-size: 2rem;
  }
}
