@import (reference) "Mixins/_mixins.less";

.hd {
  width: 100%;
  max-width: @vikinglotto-device-width;
  text-transform: uppercase;
  text-align: inherit;
  line-height: 1;
  display: block;
  margin: 1vw auto;

  @media(min-width:@vikinglotto-desktop-width) {
    margin: 1rem auto;
  }

  small {
    display: block;
  }
}

.hd,
.label,
.span,
.p {

  &.xxsmall {
    font-size: 5.4vw;
    line-height: 5.9vw;

    @media (min-width: @vikinglotto-mobile-width) {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }

  &.xsmall {
    font-size: 3.1vw;
    text-transform: none;
    font-weight: 400;

    @media (min-width: @vikinglotto-mobile-width) {
      font-size: 2rem;
      line-height: 2rem;
    }
  }

  &.large {
    font-size: 5.4vw;

    @media (min-width: @vikinglotto-mobile-width) {
      font-size: 3.5rem;
      line-height: 3.5rem;
      font-weight: 900;
    }
  }

  &.xlarge {
    font-size: 10.7vw;
    line-height: 10.7vw;

    @media (min-width: @vikinglotto-mobile-width) {
      font-size: 5rem;
      line-height: 5rem;
      font-weight: 900;
    }
  }

  &.xxlarge {
    font-size: 16vw;
    font-weight: 800;

    @media (min-width: @vikinglotto-mobile-width) {
      font-size: 10.4rem;
      font-weight: 900;
    }
  }

  &.italic {
    font-style: italic;
  }

  &.semibold {
    font-weight: 600;
  }

  &.italic {
    font-style: italic;
  }

  &.bold {
    font-weight: bold;
  }

  &.white {
    color: @vikinglotto-white;
  }

  &.mediumgray {
    color: @vikinglotto-mediumgray;
  }

  &.darkblue {
    color: @vikinglotto-darkblue;
  }
}
