.vikinglotto-classic-game .statistics-component-for-game-client-wrapper,
.vikinglotto-system-game .statistics-component-for-game-client-wrapper,
.vikinglotto-lucky-game .statistics-component-for-game-client-wrapper {
  border-color: #000078;
  background-color: #0000b4;
}
.vikinglotto-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .numbers-count-wrapper,
.vikinglotto-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .numbers-count-wrapper,
.vikinglotto-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .numbers-count-wrapper {
  background-color: #0000ff;
}
.vikinglotto-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper,
.vikinglotto-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper,
.vikinglotto-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper {
  box-sizing: content-box;
}
.vikinglotto-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box,
.vikinglotto-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box,
.vikinglotto-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box {
  background-color: #0000ff;
}
.vikinglotto-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell,
.vikinglotto-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell,
.vikinglotto-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell {
  width: 8vw;
  height: 8vw;
  font-size: 3vw;
  line-height: 1;
  margin: 1vw;
  padding-top: 2.2vw;
  color: #1d1d1d;
  background-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell,
  .vikinglotto-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell,
  .vikinglotto-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell {
    width: 4rem;
    height: 4rem;
    font-size: 1.6rem;
    line-height: 1.5;
    margin: .5rem;
    padding-top: .8rem;
  }
}
.vikinglotto-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.special .number-cell,
.vikinglotto-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.special .number-cell,
.vikinglotto-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.special .number-cell {
  color: #fff;
  padding-top: 1.9vw;
  border-radius: 0;
  background-color: transparent;
  background-image: url(/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons/icon-viking-helmet-white.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-sizing: border-box;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.special .number-cell,
  .vikinglotto-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.special .number-cell,
  .vikinglotto-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.special .number-cell {
    padding-top: .6rem;
  }
}
.vikinglotto-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.selected .number-cell,
.vikinglotto-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.selected .number-cell,
.vikinglotto-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.selected .number-cell {
  background-color: #feb700;
}
.vikinglotto-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.selected.special .number-cell,
.vikinglotto-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.selected.special .number-cell,
.vikinglotto-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.selected.special .number-cell {
  color: #feb700;
  background-color: transparent;
  background-image: url(/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons/icon-viking-helmet-yellow.svg);
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .statistics-component-for-game-client-wrapper + .vikinglotto-classic-game-section .vikinglotto-content-wrapper .rows-container {
    padding-top: 2rem;
  }
}
