@import url("../Mixins/_mixins.less");

.vikinglotto-footer-navigation {
  background-color: @vikinglotto-blue;

  .vikinglotto-content-wrapper {
    padding: 0;
    text-align: left;
  }

  .vikinglotto-section {
    background: @vikinglotto-blue;
    width: 100%;
    padding: 0;
    margin: auto;

    @media (min-width: @vikinglotto-desktop-width) {
      max-width: 96rem;
    }

    .footer-navigation-link {
      max-width: 86.5vw;
      font-size: 5.4vw;
      font-weight: bold;
      font-style: normal;
      text-transform: uppercase;
      color: #fff;
      line-height: 19.9vw;
      text-decoration: none;
      border-bottom: .1rem solid @vikinglotto-white;
      cursor: pointer;
      position: relative;
      display: block;
      transition: all 0.2s ease;
      margin: 0 auto;

      @media (min-width: @vikinglotto-desktop-width) {
        width: calc(~'50% - 2.5rem');
        font-size: 2rem;
        line-height: 10rem;
        display: inline-block;

        &:nth-child(odd) {
          margin-right: 2.5rem;
        }

        &:nth-child(even) {
          margin-left: 2.5rem;
        }

        &:nth-last-child(1), &:nth-last-child(2) {
          border-bottom: none;
        }
      }

      @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
        font-size: 2.5vw;
        line-height: 11vw;
      }

      &:hover {
        color: @vikinglotto-darkblue;
        opacity: 0.5;

        &:before {
          border-left: 2vw solid #000;
          opacity: 0.5;

          @media (min-width: @vikinglotto-desktop-width) {
            border-width: 1rem;
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }
      // the big block links found in the footer have a css
      // generated arrow stuck at the end.
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -2vw;
        width: 0;
        height: 0;
        border-top: 2vw solid transparent;
        border-bottom: 2vw solid transparent;
        border-left: 2vw solid #fff;

        @media (min-width: @vikinglotto-desktop-width) {
          margin-top: -1rem;
          border-top: 1rem solid transparent;
          border-bottom: 1rem solid transparent;
          border-left: 1rem solid #fff;
        }
      }

      &:before {
        right: 0;
        border-left: 2vw solid @vikinglotto-white;

        @media (min-width: @vikinglotto-desktop-width) {
          border-width: 1rem;
        }
      }

      &:after {
        right: .2rem;
        border-left: 2vw solid @vikinglotto-blue;

        @media (min-width: @vikinglotto-desktop-width) {
          border-width: 1rem;
        }
      }
    }
  }
}
