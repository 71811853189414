// bring in mixins
@import (reference) "../Mixins/_mixins.less"; // ┬──┬﻿ ¯\_(ツ)

.vikinglotto-classic-game {
  @media (min-width: @vikinglotto-desktop-width) {
    background-color: @vikinglotto-darkblue;
    position: relative;
  }

  .vikinglotto-content-wrapper {
    width: 100vw;
    text-align: left;
    overflow: hidden;
    padding: 0;

    @media (min-width: @vikinglotto-desktop-width) {
      width: 100%;
      overflow: visible;
    }
  }

  @vikinglotto-rows-sliding-animation-duration: .6s;

  .rows-container {
    height: 126.17vw;
    font-size: 0;
    line-height: 0;
    white-space: nowrap;
    padding-top: 3.633vw;
    padding-bottom: 4.8vw;
    position: relative;
    will-change: transform;
    transform-origin: right center;
    transition: transform .2s linear;
    overflow: hidden;

    @media (min-width: @vikinglotto-desktop-width) {
      height: 43.8rem;
      padding-top: 0;
      padding-bottom: 2rem;
    }

    @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
      height: 87vw;
    }

    .keyframes(vikinglotto-slide-to-right-animation; {
    }

  );

    .keyframes(vikinglotto-slide-to-right-infocus-row-animation; {
      0% {
        .transform(scale(0.9) translate3D(115%, 1%, 0));
        .opacity(.5);
      }

      100% {
        .transform(scale(1) translate3D(0, 0, 0));
        .opacity(1);
      }
    }

  );

    .keyframes(vikinglotto-slide-to-right-next-row-animation; {
      0% {
        .transform(scale(0.9) translate3D(185%, 1%, 0));
        .opacity(.5);
      }

      100% {
        .transform(scale(0.9) translate3D(115%, 1%, 0));
        .opacity(.5);
      }
    }

  );

    .keyframes(vikinglotto-slide-to-right-prev-row-animation; {
      0% {
        .transform(scale(1) translate3D(0, 0, 0));
        .opacity(1);
      }

      100% {
        .transform(scale(0.9) translate3D(-115%, 1%, 0));
        .opacity(.5);
      }
    }

  );

    .keyframes(vikinglotto-slide-to-right-prev-prev-row-animation; {
      0% {
        .transform(scale(0.9) translate3D(-115%, 1%, 0));
        .opacity(.5);
      }

      100% {
        .transform(scale(0.9) translate3D(-185%, 1%, 0));
        .opacity(.5);
      }
    }

  );


    .keyframes(vikinglotto-slide-to-left-animation; {
    }

  );

    .keyframes(vikinglotto-slide-to-left-half-animation; {
    }

  );

    .keyframes(vikinglotto-slide-to-left-infocus-row-animation; {
      0% {
        .transform(scale(0.9) translate3D(-115%, 1%, 0));
        .opacity(.5);
      }

      100% {
        .transform(scale(1) translate3D(0, 0, 0));
        .opacity(1);
      }
    }

  );

    .keyframes(vikinglotto-slide-to-left-next-row-animation; {
      0% {
        .transform(scale(1) translate3D(0, 0, 0));
        .opacity(1);
      }

      100% {
        .transform(scale(0.9) translate3D(115%, 1%, 0));
        .opacity(.5);
      }
    }

  );

    .keyframes(vikinglotto-slide-to-right-next-next-row-animation; {
      0% {
        .transform(scale(0.9) translate3D(115%, 1%, 0));
        .opacity(.5);
      }

      100% {
        .transform(scale(0.9) translate3D(185%, 1%, 0));
        .opacity(.5);
      }
    }

  );

    .keyframes(vikinglotto-slide-to-left-prev-row-animation; {
      0% {
        .transform(scale(0.9) translate3D(-185%, 1%, 0));
        .opacity(.5);
      }

      100% {
        .transform(scale(0.9) translate3D(-115%, 1%, 0));
        .opacity(.5);
      }
    }

  );


    @media (min-width: @vikinglotto-desktop-width) {
      .keyframes(vikinglotto-slide-to-right-animation; {
      }

    );

      .keyframes(vikinglotto-slide-to-right-infocus-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,900,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1));
          .opacity(1);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-right-next-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,1400,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,900,6,0,1));
          .opacity(.5);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-right-prev-row-animation; {
        0% {
          .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1));
          .opacity(1);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-900,6,0,1));
          .opacity(.5);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-right-prev-prev-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-900,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-1400,6,0,1));
          .opacity(.5);
        }
      }

    );


      .keyframes(vikinglotto-slide-to-left-half-animation; {
      }

    );

      .keyframes(vikinglotto-slide-to-left-animation; {
      }

    );

      .keyframes(vikinglotto-slide-to-left-infocus-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-900,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1));
          .opacity(1);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-left-next-row-animation; {
        0% {
          .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1));
          .opacity(1);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,900,6,0,1));
          .opacity(.5);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-left-next-next-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,900,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,1400,6,0,1));
          .opacity(.5);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-left-prev-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-1400,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-900,6,0,1));
          .opacity(.5);
        }
      }

    );
    }

    @media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: @vikinglotto-desktop-width) {
      .keyframes(vikinglotto-slide-to-right-infocus-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,727,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1));
          .opacity(1);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-right-next-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,1140,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,727,6,0,1));
          .opacity(.5);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-right-prev-row-animation; {
        0% {
          .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1));
          .opacity(1);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-727,6,0,1));
          .opacity(.5);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-right-prev-prev-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-727,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-1140,6,0,1));
          .opacity(.5);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-left-infocus-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-727,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1));
          .opacity(1);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-left-next-row-animation; {
        0% {
          .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1));
          .opacity(1);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,727,6,0,1));
          .opacity(.5);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-left-next-next-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,727,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,1140,6,0,1));
          .opacity(.5);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-left-prev-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-1140,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-727,6,0,1));
          .opacity(.5);
        }
      }

    );
    }

    @media screen and (min-resolution: 2dppx) and (min-width: @vikinglotto-desktop-width) {
      .keyframes(vikinglotto-slide-to-right-animation; {
      }

    );

      .keyframes(vikinglotto-slide-to-right-infocus-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,900,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1));
          .opacity(1);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-right-next-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,1400,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,900,6,0,1));
          .opacity(.5);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-right-prev-row-animation; {
        0% {
          .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1));
          .opacity(1);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-900,6,0,1));
          .opacity(.5);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-right-prev-prev-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-900,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-1400,6,0,1));
          .opacity(.5);
        }
      }

    );


      .keyframes(vikinglotto-slide-to-left-half-animation; {
      }

    );

      .keyframes(vikinglotto-slide-to-left-animation; {
      }

    );

      .keyframes(vikinglotto-slide-to-left-infocus-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-900,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1));
          .opacity(1);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-left-next-row-animation; {
        0% {
          .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1));
          .opacity(1);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,900,6,0,1));
          .opacity(.5);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-left-next-next-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,900,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,1400,6,0,1));
          .opacity(.5);
        }
      }

    );

      .keyframes(vikinglotto-slide-to-left-prev-row-animation; {
        0% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-1400,6,0,1));
          .opacity(.5);
        }

        100% {
          .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-900,6,0,1));
          .opacity(.5);
        }
      }

    );
    }

    &.slide-to-right {
      .animation(vikinglotto-slide-to-right-animation @vikinglotto-rows-sliding-animation-duration forwards ease-in-out);

      .in-focus {
        .animation(vikinglotto-slide-to-right-infocus-row-animation @vikinglotto-rows-sliding-animation-duration forwards ease-in-out);
      }

      .next-row {
        .animation(vikinglotto-slide-to-right-next-row-animation @vikinglotto-rows-sliding-animation-duration forwards ease-in-out);
      }

      .prev-row {
        .animation(vikinglotto-slide-to-right-prev-row-animation @vikinglotto-rows-sliding-animation-duration forwards ease-in-out);
      }

      .prev-prev-row {
        .animation(vikinglotto-slide-to-right-prev-prev-row-animation @vikinglotto-rows-sliding-animation-duration forwards ease-in-out);
      }
    }

    &.slide-to-left, &.slide-to-left-half {
      .animation(vikinglotto-slide-to-left-animation @vikinglotto-rows-sliding-animation-duration forwards linear);

      .in-focus {
        .animation(vikinglotto-slide-to-left-infocus-row-animation @vikinglotto-rows-sliding-animation-duration forwards linear);
      }

      .next-row {
        .animation(vikinglotto-slide-to-left-next-row-animation @vikinglotto-rows-sliding-animation-duration forwards linear);
      }

      .next-next-row {
        .animation(vikinglotto-slide-to-left-next-next-row-animation @vikinglotto-rows-sliding-animation-duration forwards linear);
      }

      .prev-row {
        .animation(vikinglotto-slide-to-left-prev-row-animation @vikinglotto-rows-sliding-animation-duration forwards linear);
      }
    }


    &.slide-to-left .in-focus + .next-row:not(.interacted-row):last-of-type,
    &.slide-to-left-half .in-focus + .next-row:not(.interacted-row):last-of-type {
      .animation(vikinglotto-slide-to-left-next-row-animation @vikinglotto-rows-sliding-animation-duration forwards linear);
    }

    &.delete-row {
      .keyframes(vikinglotto-delete-row-animation-in-focus-row; {
        0% {
          .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1));
          .opacity(1);
        }

        100% {
          .transform(matrix3d(0.5,0,0.00,0,0.00,0.5,0.00,-.0002,0,0,1,0,0,1400,0,1));
          .opacity(0);
        }
      }

    );

      & > .in-focus {
        .animation(vikinglotto-delete-row-animation-in-focus-row .5s .0s forwards linear);
      }

      & > .in-focus + .next-row {
        .animation(vikinglotto-slide-to-right-infocus-row-animation .5s .15s forwards linear);
      }

      & > .in-focus + .next-row + div {
        .animation(vikinglotto-delete-row-animation-next-next-row .5s .15s forwards linear);
      }
    }
  }

  .left-gradient, .right-gradient {
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;

    @media (min-width: @vikinglotto-desktop-width) {
      width: 16vw;
    }
  }

  .left-gradient {
    left: 0;
    background: -moz-linear-gradient(left, rgba(0,0,120,1) 0%, rgba(0,0,120,0) 100%);
    background: -webkit-linear-gradient(left, rgba(0,0,120,1) 0%,rgba(0,0,120,0) 100%);
    background: linear-gradient(to right, rgba(0,0,120,1) 0%,rgba(0,0,120,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000078', endColorstr='#00000078',GradientType=1 );
  }

  .right-gradient {
    right: -19vw;
    background: -moz-linear-gradient(left, rgba(0,0,120,0) 0%, rgba(0,0,120,1) 100%);
    background: -webkit-linear-gradient(left, rgba(0,0,120,0) 0%,rgba(0,0,120,1) 100%);
    background: linear-gradient(to right, rgba(0,0,120,0) 0%,rgba(0,0,120,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000078', endColorstr='#000078',GradientType=1 );
    transition: right .25S linear;

    &.active {
      right: 0;
    }
  }

  .rows-wrapper {
    filter: url("#rows-side-sliding-blur");
  }

  .row-container {
    width: 74.799%;
    padding-bottom: 1.6vw;
    display: inline-block;
    position: absolute;
    visibility: hidden;
    left: 0;
    right: 0;
    margin: auto;
    .box-sizing();
    .opacity(0);

    @media (min-width: @vikinglotto-desktop-width) {
      width: 91.6rem;
      padding-bottom: 1.9rem;
    }

    @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
      width: 52%;
    }

    &.in-focus, &.next-row, &.prev-row, &.next-next-row, &.prev-prev-row {
      .opacity(.5);
      visibility: visible;
      will-change: transform, opacity;
    }

    &.in-focus {
      .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1));
      .opacity(1);
      z-index: 1;
    }

    &.next-row, &.prev-row, &.prev-prev-row, &.next-next-row {
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &.next-row {
      .transform(scale(0.9) translate3D(115%, 1%, 0));

      @media (min-width: @vikinglotto-desktop-width) {
        .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,900,6,0,1));
      }

      @media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: @vikinglotto-desktop-width) {
        .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,727,6,0,1));
      }

      @media screen and (min-resolution: 2dppx) and (min-device-width: @vikinglotto-desktop-width) {
        .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,900,6,0,1));
      }
    }

    &.next-next-row {
      .transform(scale(0.9) translate3D(185%, 1%, 0));

      @media (min-width: @vikinglotto-desktop-width) {
        .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,1400,6,0,1));
      }

      @media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: @vikinglotto-desktop-width) {
        .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,1140,6,0,1));
      }

      @media screen and (min-resolution: 2dppx) and (min-device-width: @vikinglotto-desktop-width) {
        .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,1400,6,0,1));
      }
    }


    &.prev-row {
      .transform(scale(0.9) translate3D(-115%, 1%, 0));

      @media (min-width: @vikinglotto-desktop-width) {
        .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-900,6,0,1));
      }

      @media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: @vikinglotto-desktop-width) {
        .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-727,6,0,1));
      }

      @media screen and (min-resolution: 2dppx) and (min-width: @vikinglotto-desktop-width) {
        .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-900,6,0,1));
      }
    }

    &.prev-prev-row {
      .transform(scale(0.9) translate3D(-185%, 1%, 0));

      @media (min-width: @vikinglotto-desktop-width) {
        .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-1400,6,0,1));
      }

      @media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: @vikinglotto-desktop-width) {
        .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-1140,6,0,1));
      }

      @media screen and (min-resolution: 2dppx) and (min-width: @vikinglotto-desktop-width) {
        .transform(matrix3d(0.815,0,0.00,0,0.00,0.815,0.00,0,0,0,1,0,-1400,6,0,1));
      }
    }


    @keyframes vikinglotto-not-completed-animation {
      0% {
        transform: scale(1);
      }

      14.28% {
        transform: scale(1.2);
      }

      28.57% {
        transform: scale(1);
      }

      42.85% {
        transform: scale(1.2);
      }

      57.13% {
        transform: scale(1);
      }

      71.41% {
        transform: scale(1.2);
      }

      85.69% {
        transform: scale(1);
      }
    }

    @keyframes vikinglotto-not-completed-text-animation {
      0% {
        transform: scale(1);
        opacity: .3;
      }

      25% {
        transform: scale(1.3);
        opacity: 1;
      }

      50% {
        transform: scale(1);
        opacity: .3;
      }

      75% {
        transform: scale(1.3);
        opacity: 1;
      }

      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    &.row-not-completed {
      .cell:not(.selected) {
        animation: vikinglotto-not-completed-animation 1s forwards;
      }

      .viking-box:not(.selected) {
        animation: vikinglotto-not-completed-animation 1s forwards;
      }

      .header-rules-text {
        animation: vikinglotto-not-completed-text-animation 1s forwards;
      }
    }

    @keyframes vikinglotto-row-demo-animation {
      0% {
        opacity: .5;
        left: 0%;
      }

      20% {
        opacity: .9;
        left: -5%;
      }

      60% {
        opacity: .9;
        left: -5%;
      }

      100% {
        opacity: .5;
        left: 0%;
      }
    }

    @keyframes vikinglotto-row-demo1-animation {
      0% {
        filter: blur(0);
      }

      10% {
        filter: blur(5px);
      }

      80% {
        filter: blur(5px);
      }

      100% {
        filter: blur(0);
      }
    }

    @keyframes vikinglotto-row-demo2-animation {
      0% {
        opacity: .5;
      }

      40% {
        opacity: .7;
      }

      60% {
        opacity: .7;
      }

      100% {
        opacity: .5;
      }
    }

    @keyframes vikinglotto-cell-selection-animation-demo {
      0% {
        width: 0%;
        height: 0%;
        top: 50%;
        left: 50%;
        margin: 0;
      }

      40% {
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        margin: -50%;
      }

      70% {
        width: 130%;
        height: 130%;
        top: 50%;
        left: 50%;
        margin: -65%;
      }

      100% {
        width: 0%;
        height: 0%;
        top: 50%;
        left: 50%;
        margin: 0;
      }
    }

    &.row-completed {
      &.row-blury.row-number-1 {
        animation: vikinglotto-row-demo1-animation 5s linear forwards;
      }

      & + .row-number-2.new-row {
        animation: vikinglotto-row-demo-animation .7s 4s linear forwards;
      }

      & + .new-row {
        animation: vikinglotto-row-demo2-animation .7s 4s linear forwards;

        .cell {
          .back-face {
            @media (min-width: @vikinglotto-desktop-width) {
              animation-name: vikinglotto-cell-selection-animation-demo;
              animation-duration: .6s;
              animation-fill-mode: forwards;
              animation-timing-function: ease-out;
            }
          }

          @media (min-width: @vikinglotto-desktop-width) {
            &:nth-child(12n+1) {
              .back-face {
                animation-delay: 0s;
              }
            }

            &:nth-child(12n+2) {
              .back-face {
                animation-delay: .09s;
              }
            }

            &:nth-child(12n+3) {
              .back-face {
                animation-delay: .18s;
              }
            }

            &:nth-child(12n+4) {
              .back-face {
                animation-delay: .27s;
              }
            }

            &:nth-child(12n+5) {
              .back-face {
                animation-delay: .36s;
              }
            }

            &:nth-child(12n+6) {
              .back-face {
                animation-delay: .45s;
              }
            }

            &:nth-child(12n+7) {
              .back-face {
                animation-delay: .54s;
              }
            }

            &:nth-child(12n+8) {
              .back-face {
                animation-delay: .63s;
              }
            }

            &:nth-child(12n+9) {
              .back-face {
                animation-delay: .72s;
              }
            }

            &:nth-child(12n+10) {
              .back-face {
                animation-delay: .81s;
              }
            }

            &:nth-child(12n+11) {
              .back-face {
                animation-delay: .90s;
              }
            }

            &:nth-child(12n+12) {
              .back-face {
                animation-delay: .99s;
              }
            }
          }
        }
      }
    }
  }

  .prev-row-button {
    width: 10.5vw;
    height: 32vw;
    background-color: rgba(235,248,253,.8);
    position: absolute;
    left: 0;
    top: 50%;
    border-radius: 0 6px 6px 0;
    .transform(translateY(-50%) translateX(-10.5vw));
    .transitionWithTransform(transform .15s);
    z-index: 1;

    @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
      width: 8vw;
      height: 28vw;
      top: 47%;
    }

    @media (min-width: @vikinglotto-desktop-width) {
      width: 5rem;
      height: 14rem;
      .transform(translateY(-50%) translateX(-5rem));
      cursor: pointer;
    }

    .icon {
      width: 2.4vw;
      height: 3.468vw;
      font-size: 4.5vw;
      line-height: .55;
      fill: @vikinglotto-darkgray;
      background-color: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      .transform(translateY(-50%) translateX(-1.5vw));

      @media (min-width: @vikinglotto-desktop-width) {
        width: 1.2rem;
        height: 1.2rem;
        .transform(translateY(-50%) translateX(-.2rem));
      }
    }

    &.active {
      transform: translateY(-50%) translateX(0);

      @media (min-width: @vikinglotto-desktop-width) {
        transform: translateY(-50%) translateX(-.8rem);
      }

      &:hover {
        @media (min-width: @vikinglotto-desktop-width) {
          transform: translateY(-50%) translateX(0);
        }
      }
    }
  }

  .create-new-row-button, .next-row-button {
    width: 10.5vw;
    height: 32vw;
    background-color: rgba(235,248,253,.8);
    position: absolute;
    right: 0;
    top: 50%;
    border-radius: 6px 0 0 6px;
    transform: translateY(-50%) translateX(10.5vw);
    transition: right .15s, transform .15s;
    z-index: 1;

    @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
      width: 8vw;
      height: 28vw;
      top: 47%;
    }

    @media (min-width: @vikinglotto-desktop-width) {
      width: 5rem;
      height: 14rem;
      .transform(translateY(-50%) translateX(5rem));
      cursor: pointer;
    }

    .icon {
      width: 2.4vw;
      height: 3.468vw;
      font-size: 4.5vw;
      line-height: .55;
      fill: @vikinglotto-darkgray;
      background-color: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-1.5vw);

      @media (min-width: @vikinglotto-desktop-width) {
        width: 1.2rem;
        height: 1.2rem;
        transform: translateY(-50%) translateX(-.8rem);
      }
    }
  }

  .next-row-button {
    &.active {
      transform: translateY(-50%) translateX(0);

      @media (min-width: @vikinglotto-desktop-width) {
        transform: translateY(-50%) translateX(.8rem);
      }

      &:hover {
        @media (min-width: @vikinglotto-desktop-width) {
          transform: translateY(-50%) translateX(0);
        }
      }
    }
  }

  .create-new-row-button {
    @media (min-width: @vikinglotto-desktop-width) {
      width: 6.4rem;
      transform: translateY(-50%) translateX(6.4rem);
    }

    .icon {
      @media (min-width: @vikinglotto-desktop-width) {
        .transform(translateY(-50%) translateX(-1.6rem));
      }
    }

    @keyframes vikinglotto-create-button-demo-animation {
      0% {
        transform: translateY(-50%) translateX(6.4rem);
      }

      40% {
        background-color: rgba(235,248,253,.8);
        transform: translateY(-50%) translateX(0);
      }

      100% {
        transform: translateY(-50%) translateX(2.3rem);
      }
    }

    &.active {
      transform: translateY(-50%) translateX(0);

      @media (min-width: @vikinglotto-desktop-width) {
        .transform(translateY(-50%) translateX(2.3rem));
        animation: vikinglotto-create-button-demo-animation .95s linear forwards;
      }

      &:hover {
        @media (min-width: @vikinglotto-desktop-width) {
          right: .8rem;
        }
      }

      & ~ .next-row-button.active {
        display: none;
      }
    }
  }

  @keyframes vikinglotto-create-new-row-text-animation {
    0% {
      transform: translateY(0vw) translateY(-50%) translateX(-50vw) translateX(+50%) scale(.6);
      opacity: 0;
      filter: blur(5px);
    }

    10% {
      transform: translateY(65vw) translateY(-50%) translateX(-50vw) translateX(+50%) scale(1.1);
      opacity: 1;
      filter: blur(0);
    }

    15% {
      transform: translateY(68vw) translateY(-50%) translateX(-50vw) translateX(+50%) scale(1.1);
      opacity: 1;
      filter: blur(0);
    }

    75% {
      transform: translateY(68vw) translateY(-50%) translateX(-50vw) translateX(+50%) scale(1.1);
      opacity: 1;
      filter: blur(0);
    }

    80% {
      transform: translateY(68vw) translateY(-50%) translateX(-50vw) translateX(+50%) scale(.6);
      opacity: 1;
      filter: blur(0);
    }

    100% {
      transform: translateY(68vw) translateY(-50%) translateX(-50vw) translateX(+50%) scale(.6);
      opacity: 1;
      filter: blur(0);
    }
  }

  @keyframes vikinglotto-create-new-row-text-animation-desktop {
    0% {
      transform: translateY(1rem) translateY(-50%) translateX(-50vw) translateX(+50%) scale(.6);
      opacity: 0;
      filter: blur(5px);
    }

    10% {
      transform: translateY(20rem) translateY(-50%) translateX(-50vw) translateX(+50%) scale(1.1);
      opacity: 1;
      filter: blur(0);
    }

    15% {
      transform: translateY(24.7rem) translateY(-50%) translateX(-50vw) translateX(+50%) scale(1.1);
      opacity: 1;
      filter: blur(0);
    }

    75% {
      transform: translateY(24.7rem) translateY(-50%) translateX(-50vw) translateX(+50%) scale(1.1);
      opacity: 1;
      filter: blur(0);
    }

    80% {
      transform: translateY(24.7rem) translateY(-50%) translateX(-50vw) translateX(+50%) scale(.6);
      opacity: 1;
      filter: blur(0);
    }

    100% {
      transform: translateY(24.7rem) translateY(-50%) translateX(-50vw) translateX(+50%) scale(.6);
      opacity: 1;
      filter: blur(0);
    }
  }

  .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text {
    width: 64vw;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transform: translateY(0vw) translateY(-50%) translateX(-50vw) translateX(+50%) scale(.6);
    animation: vikinglotto-create-new-row-text-animation 4s .5s linear forwards;
    z-index: 3;
    pointer-events: none;

    @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
      top: -25%;
    }

    @media (min-width: @vikinglotto-desktop-width) {
      width: 130%;
      transform: translateY(1rem) translateY(-50%) translateX(-50vw) translateX(+50%) scale(.6);
      animation: vikinglotto-create-new-row-text-animation-desktop 4s .5s linear forwards;
    }

    @keyframes vikinglotto-create-new-row-text-box-animation {
      0% {
        transform: scale(1);
        opacity: 1;
        filter: blur(0);
      }

      100% {
        transform: scale(1);
        opacity: 0;
        filter: blur(5px);
      }
    }

    .create-new-row-text-box {
      font-size: 6.5vw;
      line-height: 1.2;
      color: @vikinglotto-darkblue;
      background-color: @vikinglotto-white;
      padding: 1.3vw 2.6vw;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      white-space: normal;
      animation: vikinglotto-create-new-row-text-box-animation .5s 3.6s linear forwards;
      pointer-events: none;

      @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
        font-size: 2.5rem;
        line-height: 1.5;
      }

      @media (min-width: @vikinglotto-desktop-width) {
        font-size: 1.5vw;
        padding: 1rem 2rem;
        animation: vikinglotto-create-new-row-text-box-animation .5s 3.6s linear forwards;
      }
    }

    @keyframes vikinglotto-create-new-row-text-arrow-animation {
      0% {
        transform: translateX(0) translateY(-50%) scale(.2);
        opacity: 0;
      }

      40% {
        transform: translateX(40vw) translateY(-50%) scale(1);
        opacity: 1;
      }

      70% {
        opacity: 1;
      }

      100% {
        transform: translateX(80vw) translateY(-50%) scale(.8);
        opacity: 0;
        display: none;
      }
    }

    .create-new-row-text-arrow {
      height: 35vw;
      width: 1.5vw;
      position: absolute;
      top: 50%;
      right: 25%;
      z-index: -1;
      transform: translateX(0) translateY(-50%) scale(.2);
      opacity: 0;
      animation: vikinglotto-create-new-row-text-arrow-animation 1s 3.5s linear forwards;

      @media (min-width: @vikinglotto-desktop-width) {
        height: 25rem;
        right: 15%;
      }

      &:before, &:after {
        content: '';
        height: 63%;
        width: 5vw;
        background-color: @vikinglotto-white;
        position: absolute;
        right: 0;

        @media (min-width: @vikinglotto-desktop-width) {
          width: 5rem;
          height: 66%;
        }
      }

      &:before {
        top: 10%;
        transform-origin: center top;
        transform: rotate(-45deg);
      }

      &:after {
        bottom: 10%;
        transform-origin: center bottom;
        transform: rotate(45deg);
      }
    }
  }

  @keyframes vikinglotto-create-new-row-blink-animation {
    0% {
      background-color: transparent;
    }

    12.5% {
      background-color: rgba(245, 217, 0, 0.95);
    }

    25% {
      background-color: transparent;
    }

    37.5% {
      background-color: rgba(245, 217, 0, 0.95);
    }

    50% {
      background-color: transparent;
    }

    62.5% {
      background-color: rgba(245, 217, 0, 0.95);
    }

    75% {
      background-color: transparent;
    }

    87.5% {
      background-color: rgba(245, 217, 0, 0.95);
    }

    100% {
      background-color: transparent;
    }
  }

  .row-number-2.new-row ~ .create-new-row-button.active:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border-radius: 6px 0 0 6px;
    animation: vikinglotto-create-new-row-blink-animation 1.5s 3.5s linear forwards;
    z-index: -1;
  }

  .rows-list-box {
    width: 70vw;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 16vw;
    overflow: visible;
    background-color: @vikinglotto-blue;
    transform: translateX(-100%);
    transition: transform .25s linear;
    will-change: transform;

    @media (min-width: @vikinglotto-desktop-width) {
      width: 30rem;
      bottom: 7rem;
    }

    &.active {
      transition: transform .15s linear;
      transform: translateX(0);

      .toggle-button {
        background-color: rgba(235,248,253,1);

        .icon-list {
          opacity: 0;
        }

        .icon-arrow {
          opacity: 1;
        }
      }
    }

    .toggle-button {
      width: 10.5vw;
      height: 10.5vw;
      background-color: rgba(235,248,253,.8);
      position: absolute;
      right: 0;
      top: 50%;
      border-radius: 0 6px 6px 0;
      transform: translateY(-30vw) translateX(10.5vw);
      transition: transform .15s, background-color .25s;
      z-index: 1;

      @media (min-width: @vikinglotto-desktop-width) {
        width: 4.2rem;
        height: 7rem;
        transform: translateY(-16rem) translateX(4.2rem);
        cursor: pointer;
      }

      &:hover {
        .icon-list, .icon-arrow {
          @media (min-width: @vikinglotto-desktop-width) {
            transform: translateY(-50%) translateX(-50%) scale(1.3);
          }
        }
      }

      .icon-list, .icon-arrow {
        width: 3.8vw;
        height: 4vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        opacity: 1;
        transition: transform .25s, opacity .25s;
        fill: @vikinglotto-darkgray;

        @media (min-width: @vikinglotto-desktop-width) {
          width: 1.7rem;
          height: 1.2rem;
        }
      }

      .icon-arrow {
        opacity: 0;
      }

      @keyframes vikinglotto-button-count-wrapper-animation {
        0% {
          opacity: 0;
          transform: scale(0);
        }

        70% {
          opacity: 1;
          transform: scale(1.4);
        }

        100% {
          opacity: 1;
          transform: scale(1);
        }
      }

      .button-count {
        font-size: 3vw;
        font-weight: 600;
        color: #fff;
        text-align: center;
        padding: 0 1.5vw;
        user-select: none;
        border-radius: 3vw;
        background-color: @vikinglotto-blue;
        position: absolute;
        top: -10%;
        left: 50%;
        opacity: 0;
        animation: vikinglotto-button-count-wrapper-animation .25s linear forwards;

        @media (min-width: @vikinglotto-desktop-width) {
          min-width: 1.5rem;
          font-size: 1.4rem;
          padding: .05rem .5rem .1rem;
          border-radius: 1rem;
        }
      }
    }

    .container {
      width: 100%;
      height: 100%;
      overflow-y: auto;

      .items-box {
        font-size: 0;
        position: relative;

        .rows-list-header {
          font-size: 3.5vw;
          line-height: 2.6;
          color: @vikinglotto-white;
          text-align: center;
          background-color: @vikinglotto-blue;

          @media (min-width: @vikinglotto-desktop-width) {
            font-size: 1.6rem;
            line-height: 2.8;
          }

          @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
            font-size: 2.6vw;
          }
        }

        @keyframes vikinglotto-information-text-wrapper-fade-in-animation {
          0% {
            max-height: 0;
            opacity: 0;
            margin-bottom: 0;
          }

          100% {
            max-height: 25rem;
            opacity: 1;
            margin-bottom: 2rem;
          }
        }

        @keyframes vikinglotto-information-text-wrapper-fade-out-animation {
          0% {
            max-height: 25rem;
            opacity: 1;
            margin-bottom: 2rem;
          }

          100% {
            max-height: 0;
            opacity: 0;
            margin-bottom: 0;
          }
        }

        .information-text-wrapper {
          width: 80%;
          max-height: 0;
          margin: auto;
          text-align: center;
          margin-bottom: 0;
          overflow: hidden;
          animation: vikinglotto-information-text-wrapper-fade-in-animation .25s linear forwards;

          @media (min-width: @vikinglotto-desktop-width) {
            margin-bottom: 2rem;
          }

          &.remove {
            animation: vikinglotto-information-text-wrapper-fade-out-animation .25s linear forwards;
          }

          .text-row {
            color: #fff;
            font-size: 6vw;
            font-weight: 600;
            margin-top: 4vw;

            @media (min-width: @vikinglotto-desktop-width) {
              font-size: 2.6rem;
              margin-top: 3rem;
            }
          }
        }

        .items-inner-box {
          @keyframes vikinglotto-rows-list-in-animation {
            0% {
              transform: scaleY(0);
              opacity: 0;
              margin-bottom: -4rem;
            }

            70% {
              opacity: 1;
            }

            100% {
              transform: scaleY(1);
              opacity: 1;
              margin-bottom: 0;
            }
          }

          .item {
            width: 100%;
            font-weight: 600;
            position: relative;
            overflow: hidden;
            padding-left: 2.666vw;
            box-sizing: border-box;
            transform-origin: top;
            transition: background-color .25s linear;
            animation: vikinglotto-rows-list-in-animation .15s linear forwards;

            @media (min-width: @vikinglotto-desktop-width) {
              cursor: pointer;
              padding-left: 1.45rem;
            }

            @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
              font-size: 2.6vw;
            }

            &:nth-child(odd) {
              background-color: @vikinglotto-whitesmoke;
            }

            &:nth-child(even) {
              background-color: #fff;
            }

            &.active-row {
              background-color: #83d1ff;
            }

            &:hover {
              transition: background-color .15s linear;

              @media (min-width: @vikinglotto-desktop-width) {
                background-color: #c0f6ff;
              }
            }

            .item-inner {
              width: 100%;
              margin: auto;
              position: relative;

              .headline {
                width: 30%;
                font-size: 3.5vw;
                line-height: 2.8;
                color: @vikinglotto-blue;
                position: relative;
                float: left;

                @media (min-width: @vikinglotto-desktop-width) {
                  width: 24%;
                  font-size: 1.2rem;
                  line-height: 3.3;
                }

                @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
                  font-size: 2.6vw;
                }
              }

              .numbers-container {
                width: 70%;
                font-size: 3.5vw;
                line-height: 2.8;
                color: @vikinglotto-darkblue;
                display: inline-block;
                box-sizing: border-box;
                position: relative;

                @media (min-width: @vikinglotto-desktop-width) {
                  width: 76%;
                  font-size: 1.4rem;
                  line-height: 2.8;
                }

                @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
                  font-size: 2.6vw;
                }

                .plus-separator {
                  width: 1.867vw;
                  height: 1.867vw;
                  fill: @vikinglotto-darkblue;
                  position: absolute;
                  top: 3.9vw;
                  left: 34.6vw;

                  @media (min-width: @vikinglotto-desktop-width) {
                    width: 1rem;
                    height: 1rem;
                    top: 1.5rem;
                    left: 14rem;
                  }

                  @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
                    top: 3.8vw;
                  }
                }

                .viking-numbers {
                  position: absolute;
                  left: 38vw;

                  @media (min-width: @vikinglotto-desktop-width) {
                    left: 16rem;
                  }
                }
              }

              .delete-button {
                width: 8vw;
                height: 8.3vw;
                position: absolute;
                right: 0;
                top: .7vw;
                transition: transform .15s linear;

                &:hover {
                  @media (min-width: @vikinglotto-desktop-width) {
                    transform: scale(1.4);
                  }
                }

                @media (min-width: @vikinglotto-desktop-width) {
                  width: 1rem;
                  height: 1rem;
                  padding: 1.45rem;
                  top: 0;
                  cursor: pointer;
                }

                @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
                  top: 1vw;
                }

                .icon {
                  width: 2.667vw;
                  height: 2.667vw;
                  position: absolute;
                  fill: #777a7f;
                  top: 50%;
                  left: 50%;
                  transform: translate3d(-50%,-50%,0);

                  @media (min-width: @vikinglotto-desktop-width) {
                    width: 1.2rem;
                    height: 1.2rem;
                  }
                }
              }
            }

            &.invalid-row {
              box-sizing: border-box;
              padding-left: 5vw;

              @media (min-width: @vikinglotto-desktop-width) {
                padding-left: 3rem;
              }

              .headline {
                &:before {
                  content: '!';
                  width: 3.734vw;
                  height: 3.734vw;
                  display: inline-block;
                  font-size: 2.7vw;
                  .border-radius(50%);
                  background-color: #d01212;
                  margin-right: 0.934vw;
                  position: absolute;
                  top: 2.8vw;
                  left: -4vw;
                  line-height: 1.4;
                  text-indent: 1.5vw;
                  color: #fff;
                  cursor: pointer;

                  @media (min-width: @vikinglotto-desktop-width) {
                    width: 2.2rem;
                    height: 2.2rem;
                    font-size: 1.4rem;
                    margin-right: .7rem;
                    line-height: 1.6;
                    text-indent: .95rem;
                    top: .8rem;
                    left: -2.7rem;
                  }

                  @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
                    top: 2.9vw;
                    line-height: 1.2;
                    text-indent: 1.4vw;
                  }
                }
              }
            }
          }
        }

        .error-message-box {
          background-color: #e9e9e9;
          margin-top: -6vw;
          margin-left: -1.333vw;
          padding: 4.666vw 4vw;
          .box-sizing();
          .border-radius(6px);
          position: absolute;
          .transform(translateY(-110%));
          .opacity(0);
          visibility: hidden;
          .transitionWithTransform(transform .25s, opacity .25s, visibility 0s .3s;);

          @media (min-width: @vikinglotto-desktop-width) {
            margin-top: -3rem;
            margin-left: -1rem;
            padding: 1.5rem 2rem;
          }

          &:after {
            content: '';
            border-left: 1.333vw solid transparent;
            border-right: 1.333vw solid transparent;
            border-top: 1.333vw solid #e9e9e9;
            position: absolute;
            bottom: -1.333vw;
            left: 2vw;

            @media (min-width: @vikinglotto-desktop-width) {
              border-left: .6rem solid transparent;
              border-right: .6rem solid transparent;
              border-top: .6rem solid #e9e9e9;
              bottom: -.6rem;
              left: 1.3rem;
            }
          }

          .error-headline {
            font-size: 3.2vw;
            font-weight: bold;
            text-transform: uppercase;

            @media (min-width: @vikinglotto-desktop-width) {
              font-size: 1.6rem;
            }
          }

          .error-subheadline {
            font-size: 3.2vw;
            font-weight: 300;

            @media (min-width: @vikinglotto-desktop-width) {
              font-size: 1.5rem;
            }
          }
        }

        .quick-button-wrapper {
          position: relative;
          padding: 6vw 0;
          text-align: center;

          @media (min-width: @vikinglotto-desktop-width) {
            height: 4rem;
            padding: 2rem 0;
          }

          @keyframes vikinglotto-quick-button-fade-in-animation {
            0% {
              transform: translateY(-100%);
              opacity: 0;
            }

            70% {
              opacity: 1;
            }

            100% {
              transform: translateY(0);
              opacity: 1;
            }
          }

          @keyframes vikinglotto-quick-button-fade-out-animation {
            0% {
              transform: translateY(0);
              opacity: 1;
            }

            70% {
              opacity: 1;
            }

            100% {
              transform: translateY(120%);
              opacity: 0;
            }
          }

          .quick-button {
            width: 90%;
            font-size: 3.734vw;
            font-weight: 600;
            color: @vikinglotto-darkgray;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 6px;
            padding: 3vw;
            margin: auto;
            text-transform: uppercase;
            position: absolute;
            left: 0;
            right: 0;
            transition: background-color .15s linear;
            animation: vikinglotto-quick-button-fade-in-animation .25s linear forwards;

            @media (min-width: @vikinglotto-desktop-width) {
              font-size: 1.6rem;
              padding: .9rem;
              cursor: pointer;
            }

            &:hover {
              @media (min-width: @vikinglotto-desktop-width) {
                background-color: #ededed;
              }
            }

            &.inactive {
              animation: vikinglotto-quick-button-fade-out-animation .25s linear forwards;
            }
          }
        }
      }
    }

    .button {
      width: 10.5vw;
      height: 10.5vw;
      background-color: rgba(235,248,253,.8);
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 0 6px 6px 0;
      overflow: hidden;
      .transform(translateY(-30vw) translateX(-10.5vw));
      .transitionWithTransform(transform .15s, background-color .1s;);
      z-index: 1;

      @media (min-width: @vikinglotto-desktop-width) {
        width: 4.2rem;
        height: 7rem;
        .transform(translateY(-16rem) translateX(-4.2vw));
        cursor: pointer;
      }

      .icon-list {
        width: 3.8vw;
        height: 4vw;
        fill: @vikinglotto-darkgray;
        position: absolute;
        top: 50%;
        left: 50%;
        .transform(translateY(-50%) translateX(-1.6vw));
        .opacity(1);
        .transitionWithTransform(transform .25s, opacity .25s;);

        @media (min-width: @vikinglotto-desktop-width) {
          width: 1.5rem;
          height: 1.2rem;
          .transform(translateY(-50%) translateX(-.7rem));
        }
      }

      .icon-arrow-left {
        width: 3.8vw;
        height: 3.47vw;
        position: absolute;
        top: 50%;
        left: 50%;
        .transform(translateY(-50%) translateX(7vw));
        .opacity(0);
        .transitionWithTransform(transform .25s, opacity .25s;);

        @media (min-width: @vikinglotto-desktop-width) {
          width: 1.5rem;
          height: 1.2rem;
          .transform(translateY(-50%) translateX(-.7rem));
        }
      }

      &.active {
        .transform(translateY(-30vw) translateX(-1.5vw));

        @media (min-width: @vikinglotto-desktop-width) {
          .transform(translateY(-16rem) translateX(0));
        }
      }
    }

    &.active {
      .container {
        transform: translate3d(0, 0, 0);

        .informations-box, > .headline {
          background-color: rgba(255,255,255,1);
        }

        &.single-row {
          transform: translate3d(0, 30vw, 0);

          @media (min-width: @vikinglotto-desktop-width) {
            transform: translate3d(0, 8rem, 0);
          }
        }
      }

      .button {
        background-color: rgba(255,255,255,1);
        .transform(translateY(-30vw) translateX(85.6vw));

        @media (min-width: @vikinglotto-desktop-width) {
          .transform(translateY(-16rem) translateX(48.8rem));
        }

        .icon-list {
          .transform(translateY(-50%) translateX(-11vw));
          .opacity(0);
        }

        .icon-arrow-left {
          .transform(translateY(-50%) translateX(-1.6vw));
          .opacity(1);

          @media (min-width: @vikinglotto-desktop-width) {
            .transform(translateY(-50%) translateX(-.3rem));
          }
        }
      }
    }
  }
}

.vikinglotto-classic-game {
  .vikinglotto-content-wrapper--version-2 {
    .rows-container {
      @media (min-width: @vikinglotto-desktop-width) {
        height: 46.8rem;
      }
    }
  }
}
