﻿@import url("../../Mixins/_mixins.less");

.region-vikinglotto {

  .two-column-spot {
    .promotion-spot {
      background-color: transparent;
    }
  }

  .promotion-spot {
    background-color: @vikinglotto-blue;

    @media (min-width: @desktop-width) {
      padding: 5.3rem 5rem 2.5rem;
    }

    @media (min-width: @mobile-width) and (max-width: @desktop-width) {
      max-width: 500px;
    }
  }

  .promotion-spot__headline {
    max-width: 86.5vw;
    font-size: 2.2rem;
    font-style: italic;
    text-transform: uppercase;
    color: #fff;
    margin: 1vw auto;

    @media (min-width: @desktop-width) {
      max-width: 50rem;
      font-size: 5rem;
      line-height: 5rem;
      font-weight: 900;
      margin: 1rem auto;
    }

    @media (min-width: @mobile-width) and (max-width: @desktop-width) {
      font-size: 6rem;
      line-height: 6rem;
    }
  }

  .promotion-spot__info-text {
    max-width: 86.5vw;
    font-weight: normal;
    font-size: 3.73vw;
    color: #fff;

    @media (min-width: @desktop-width) {
      max-width: 45rem;
      min-height: 7.2rem;
      font-size: 2rem;
      line-height: 3rem;
      margin: 1rem auto 2.5rem;
    }
  }
}