.vikinglotto-lightning {
  background: #000078;
  position: relative;
  text-align: center;
  color: #fff;
}
.vikinglotto-lightning .vikinglotto-content-wrapper {
  min-height: 20rem;
  padding: 4vw 0 0;
}
@media (min-width: 950px) {
  .vikinglotto-lightning .vikinglotto-content-wrapper {
    min-height: 20rem;
    padding: 6rem 0 0;
  }
}
.vikinglotto-lightning .vikinglotto-content-wrapper .game-not-available-wrapper {
  padding-bottom: 9vw;
}
@media (min-width: 950px) {
  .vikinglotto-lightning .vikinglotto-content-wrapper .game-not-available-wrapper {
    min-height: 22rem;
    padding-bottom: 5rem;
  }
}
.vikinglotto-lightning .vikinglotto-content-wrapper .game-not-available-wrapper img {
  width: 17.33vw;
  height: 17.33vw;
  margin-bottom: 2vw;
  margin-top: -8vw;
}
@media (min-width: 950px) {
  .vikinglotto-lightning .vikinglotto-content-wrapper .game-not-available-wrapper img {
    width: 6.4rem;
    height: 6.4rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }
}
.vikinglotto-lightning .vikinglotto-content-wrapper .headline {
  max-width: 86.5vw;
  font-size: 10.7vw;
  font-style: italic;
  font-weight: 900;
  line-height: 10.7vw;
  text-transform: uppercase;
  margin: 3vw auto;
}
@media (min-width: 768px) {
  .vikinglotto-lightning .vikinglotto-content-wrapper .headline {
    font-size: 5rem;
    line-height: 5rem;
    margin: 1rem auto 3rem;
  }
}
.vikinglotto-lightning .vikinglotto-content-wrapper .subheadline {
  max-width: 86.5vw;
  font-size: 3.1vw;
  font-weight: normal;
  margin: 1vw auto;
}
@media (min-width: 950px) {
  .vikinglotto-lightning .vikinglotto-content-wrapper .subheadline {
    font-size: 2rem;
    line-height: 2rem;
    margin: 1rem auto;
  }
}
.vikinglotto-lightning .vikinglotto-slider-selector {
  width: 100%;
  text-align: center;
  margin: 6.5vw auto 4vw;
  position: relative;
}
@media (min-width: 950px) {
  .vikinglotto-lightning .vikinglotto-slider-selector {
    width: 58rem;
    margin: 2.5rem auto 5rem;
  }
}
.vikinglotto-lightning .vikinglotto-slider-selector .number {
  text-transform: uppercase;
}
.vikinglotto-lightning .vikinglotto-section > .vikinglotto-content-wrapper {
  padding-bottom: 0;
}
