.region-vikinglotto .header-spot {
  background-color: #000078;
}
.region-vikinglotto .header-spot .content-wrapper {
  min-height: 56vw;
  font-style: italic;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7vw 0;
  box-sizing: border-box;
}
@media (min-width: 950px) {
  .region-vikinglotto .header-spot .content-wrapper {
    min-height: 40rem;
    padding: 8rem 0;
  }
}
.region-vikinglotto .header-spot .content-wrapper .top-logo img {
  width: 10vw;
  height: 10vw;
}
@media (min-width: 950px) {
  .region-vikinglotto .header-spot .content-wrapper .top-logo img {
    width: 6.4rem;
    height: 6.4rem;
  }
}
.region-vikinglotto .header-spot .content-wrapper h1 {
  font-size: 7vw;
  text-transform: uppercase;
  line-height: 0.9;
}
@media (min-width: 950px) {
  .region-vikinglotto .header-spot .content-wrapper h1 {
    font-size: 3.5rem;
  }
}
.region-vikinglotto .header-spot .content-wrapper h2 {
  font-size: 4.5vw;
  text-transform: uppercase;
  line-height: 0.8;
  margin: 0;
}
@media (min-width: 950px) {
  .region-vikinglotto .header-spot .content-wrapper h2 {
    font-size: 2rem;
  }
}
.vikinglotto-no-open-draw .vikinglotto-section {
  background-color: #fff;
  border-color: #fff;
}
.vikinglotto-no-open-draw .vikinglotto-section .vikinglotto-content-wrapper {
  min-height: 31vw;
  font-style: italic;
  z-index: 4;
  padding: 8vw;
}
@media (min-width: 950px) {
  .vikinglotto-no-open-draw .vikinglotto-section .vikinglotto-content-wrapper {
    min-height: 22rem;
    padding: 8rem 0 4rem;
  }
}
.vikinglotto-no-open-draw .vikinglotto-section .vikinglotto-content-wrapper .no-open-draw-text {
  max-width: 86.5vw;
  font-size: 5vw;
  font-weight: 900;
  color: #000078;
  line-height: 1;
  margin: 1vw auto;
  text-transform: uppercase;
}
@media (min-width: 950px) {
  .vikinglotto-no-open-draw .vikinglotto-section .vikinglotto-content-wrapper .no-open-draw-text {
    font-size: 3.5rem;
    margin: 1rem auto;
  }
}
