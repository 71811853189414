.top-navigation {
  display: none;
}
@media (min-width: 768px) {
  .vikinglotto-winning-numbers-spot {
    margin: 0 auto;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section {
  background-color: #fff;
  border-color: #fff;
  position: relative;
  transition: max-height 1s ease-in-out;
  overflow: hidden;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section.section-with-dropdown {
  overflow: visible;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper {
  padding: 0 0 4rem 0;
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper {
    font-size: 4rem;
    padding: 5.5rem 0 6rem;
    margin: 0 auto;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .headline {
  font-size: 3rem;
  font-weight: bold;
  color: #0000ff;
  line-height: 1;
  margin: 1.5rem 0;
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .headline {
    font-size: 5rem;
    margin: 0 auto 1rem;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .sub-headline {
  font-size: 1.7rem;
  font-weight: bold;
  margin: 0 auto 1.5rem;
  color: #0000ff;
  display: block;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .select-date-section {
  padding: 0 2.5rem 1rem;
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .select-date-section {
    padding: 0;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .select-date-section .date-picker-placeholder-wrapper {
  padding: 1rem;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .select-date-section .date-picker-placeholder-wrapper .icon-arrow {
  top: 1.5rem;
  right: 1rem;
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .select-date-section .date-picker-placeholder-wrapper {
    padding: 1rem 3rem;
  }
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .select-date-section .date-picker-placeholder-wrapper .icon-arrow {
    right: 4rem;
  }
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .select-date-section .select-dropdown {
    max-width: 45rem;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .select-date-section .select-dropdown .cs-placeholder {
  font-size: 3.4vw;
  color: #fff;
  background-color: #000078;
  border: none;
  transition: background-color 0.15s;
}
@media (min-width: 768px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .select-date-section .select-dropdown .cs-placeholder {
    font-size: 1.8rem;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .select-date-section .select-dropdown .select-options ul li {
  background-color: #000078;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .select-date-section .select-dropdown .select-options ul li span {
  font-size: 1.8rem;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list {
  position: relative;
  display: flex;
  overflow-x: scroll;
  flex: 0 0 auto;
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list {
    padding: 0 calc((100vw - 96rem) / 2);
  }
}
@media (min-width: 1200px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list {
    overflow: hidden;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list::after {
  content: "";
  display: block;
  height: 39rem;
  width: 100%;
  min-width: 175vw;
  background-color: #d0e3ff;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
@media (min-width: 768px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list::after {
    min-width: 115vw;
  }
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list::after {
    min-width: auto;
    height: 55rem;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element {
  padding: 0 0 0 2.5rem;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  margin-bottom: 4rem;
  min-width: 85vw;
  text-align: left;
}
@media (min-width: 768px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element {
    min-width: 55vw;
  }
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element {
    min-width: auto;
    text-align: center;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element.winning-numbers-element--fade {
  opacity: 0.5;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .logo {
  border-bottom: 0.1rem solid #4f5254;
  fill: #0000ff;
  min-height: 6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .logo {
    border-bottom-width: 0.1rem;
    padding-bottom: 1rem;
    margin: 3rem auto;
    justify-content: center;
    align-items: center;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .logo svg {
  display: block;
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .logo svg {
    width: 11rem;
    height: 3.5rem;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .logo svg.vikinglotto-logo {
  height: 3rem;
  width: 15rem;
  margin-top: 2rem;
  display: block;
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .logo svg.vikinglotto-logo {
    width: 20.2rem;
    height: 4rem;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .logo svg.joker-hat {
  width: 6.5rem;
  height: 2.2rem;
  padding: 0;
  flex: 100%;
  transform: translateX(-20%);
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .logo svg.joker-hat {
    width: 6.5rem;
    height: 3.2rem;
    transform: translateY(-30%);
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .logo svg.joker-logo {
  height: 2rem;
  width: 10rem;
  margin-top: -1rem;
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .logo svg.joker-logo {
    width: 11.2rem;
    height: 2.6rem;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .logo .joker-text {
  font-size: 2rem;
  font-style: italic;
  color: #0000ff;
  margin-top: -1rem;
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .logo .joker-text {
    font-size: 2.9rem;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers {
  min-height: 24rem;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers {
    margin-bottom: 2rem;
  }
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers {
    min-height: 22rem;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container .numbers-headline {
  font-size: 1.6rem;
  font-weight: bold;
  color: #4f5254;
  line-height: 1;
  margin: 2rem auto;
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container .numbers-headline {
    margin: 0;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container ul,
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container li {
  margin: 0;
  padding: 0;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container:last-child ul {
  padding: 0;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container ul {
  list-style-type: none;
  margin: 0 -0.3rem 3rem;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container ul.bonus li {
  height: 6rem;
  font-size: 1.2rem;
  background-color: #fff;
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container ul.bonus li {
    width: 5.2rem;
    height: 6.8rem;
    font-size: 1.6rem;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container ul.bonus li:after {
  display: none;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container ul.bonus li span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0.7rem;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container li {
  display: inline-block;
  margin: 0 0.3rem;
  background-color: #0000ff;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 100%;
  font-size: 1.2rem;
  font-weight: 900;
  color: #4f5254;
  line-height: 3.2;
  text-align: center;
  position: relative;
}
@media (min-width: 768px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container li {
    width: 4.5rem;
    height: 4.5rem;
    font-size: 1.4rem;
    margin: 0 0.3rem;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container li:after {
  content: '';
  width: 35%;
  border-bottom: 0.1rem solid #4f5254;
  display: block;
  margin: auto;
  bottom: 30%;
  position: relative;
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container li:after {
    border-bottom-width: 0.1rem;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container li .number-icon {
  fill: #fff;
  position: absolute;
  width: 60%;
  height: 60%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container li .number-icon.helmet {
  width: 3.4rem;
  height: 6rem;
  top: 45%;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .numbers .number-container li span {
  position: relative;
  z-index: 1;
  display: block;
  width: inherit;
  height: inherit;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .results table {
  width: 100%;
  border-collapse: inherit;
  border-radius: 7.5px;
  table-layout: fixed;
  border: 0.1rem solid rgba(79, 82, 84, 0.4);
  overflow: hidden;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .results table td,
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .results table th {
  padding: 1rem;
  font-size: 1.2rem;
  color: #4f5254;
  border: 0.1rem solid rgba(79, 82, 84, 0.4);
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .results table td,
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .results table th {
    padding: 1.7rem 1.3rem;
    font-size: 1.5rem;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .results table th {
  font-weight: bold;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .results table tr {
  background-color: #fff;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .results table tr:nth-child(even) {
  background-color: #ededee;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .results table thead th {
  font-weight: bold;
  font-size: 1.2rem;
  color: #fff;
  background-color: #0000ff;
  padding: 0.5rem;
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element .results table thead th {
    padding: 2rem;
    font-size: 1.6rem;
  }
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element.vikinglotto-winning-numbers .numbers .super li {
  background-color: #000078;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element.vikinglotto-winning-numbers .number-box {
  width: 40%;
  display: inline-block;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element.vikinglotto-winning-numbers .number-box h2 {
  margin-top: 0;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .winning-numbers-list .winning-numbers-element.joker-winning-numbers .numbers li {
  background-color: #c50005;
  background-image: linear-gradient(45deg, #009a60 25%, transparent 25%, transparent 75%, #009a60 75%, #009a60), linear-gradient(45deg, #009a60 25%, transparent 25%, transparent 75%, #009a60 75%, #009a60);
  background-size: 30% 30%;
  background-position: 0 20%, 20% 0;
}
.vikinglotto-winning-numbers-spot .vikinglotto-section .vikinglotto-content-wrapper .disclaimer-info-text {
  font-size: 2rem;
  font-weight: 900;
  color: #1d1d1d;
  padding: 2.5rem 3rem;
  margin: 0 auto;
}
.vikinglotto-winning-numbers-spot .featured-winners {
  font-size: 3.2vw;
  border: 0.1rem solid rgba(79, 82, 84, 0.4);
  border-radius: 7.5px;
  color: #4f5254;
  margin: 1rem auto;
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-winning-numbers-spot .featured-winners {
    font-size: 1.8rem;
  }
}
@media (min-width: 950px) {
  .vikinglotto-winning-numbers-spot .featured-winners {
    font-size: 1.5rem;
  }
}
.vikinglotto-winning-numbers-spot .featured-winners .header {
  text-align: left;
  font-weight: 600;
  padding: 2vw 2rem;
  color: #4f5254;
  border-bottom: 1px solid rgba(79, 82, 84, 0.25);
  position: relative;
}
@media (min-width: 768px) {
  .vikinglotto-winning-numbers-spot .featured-winners .header {
    padding: 1.9rem;
  }
}
.vikinglotto-winning-numbers-spot .featured-winners .header .icon-arrow {
  position: absolute;
  width: 3.2vw;
  height: 3.2vw;
  right: 2rem;
  top: 2.5vw;
  fill: #4f5254;
}
@media (min-width: 768px) {
  .vikinglotto-winning-numbers-spot .featured-winners .header .icon-arrow {
    top: 2.2rem;
    width: 2rem;
    height: 2rem;
  }
}
.vikinglotto-winning-numbers-spot .featured-winners .text {
  text-align: left;
  padding: 2vw 2rem;
  line-height: 5vw;
  font-style: italic;
}
@media (min-width: 768px) {
  .vikinglotto-winning-numbers-spot .featured-winners .text {
    line-height: 3rem;
    padding: 1rem 2rem;
  }
}
.vikinglotto-winning-numbers-spot .featured-winners .text p {
  margin: 0;
}
.vikinglotto-winning-numbers-spot .featured-winners.hide .header {
  border-bottom: none;
}
.vikinglotto-winning-numbers-spot .featured-winners.hide .header .icon-arrow {
  transform: rotate(180deg);
}
.vikinglotto-winning-numbers-spot .featured-winners.hide .header:after {
  display: none;
}
.vikinglotto-winning-numbers-spot .featured-winners.hide .text {
  display: none;
}
