﻿@import (reference) "Mixins/_mixins.less"; // ┬──┬﻿ ¯\_(ツ)

.region-vikinglotto {
  width: 100%;
  font-size: 4vw;

  @media (min-width: @vikinglotto-desktop-width) {
    font-size: 1.4rem;
  }

  .container {
    width: 100vw;

    @media (min-width: @vikinglotto-desktop-width) {
      width: 100%;
    }
  }

  .page-box .container {
    width: 100%;
  }

  .mobile-only {
    display: inherit;

    @media (min-width: @vikinglotto-desktop-width) {
      display: none;
    }
  }

  .mobile-hide {
    display: none;

    @media (min-width: @vikinglotto-desktop-width) {
      display: inherit;
    }
  }
}

// `vikinglotto-section` encapsulates each section entirely
// it also controls background visuals like back-
// ground images, form, and background color.
.vikinglotto-section {
  position: relative;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  background-color: @vikinglotto-darkblue;
  border-color: @vikinglotto-darkblue;
  color: @vikinglotto-white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  &.bg-white {
    background-color: @vikinglotto-white;
    border-color: @vikinglotto-white;
  }

  // `vikinglotto-content-wrapper` controls layout of the
  // content for each section. It also serves as the
  // container for all components and elements.
  > .vikinglotto-content-wrapper {
    margin: auto;
    // centered text is a recurring theme, so we set it as a default.
    text-align: center;
    z-index: 4;
    padding: 15vw 0;

    @media (min-width: @vikinglotto-desktop-width) {
      padding: 8rem 0;
    }

    .a {
      cursor: pointer;
      color: #fff;

      &.hd {
        text-decoration: none;
      }
    }

    .p {
      width: 100%;
      max-width: @vikinglotto-device-width;
      margin: auto;
      color: #fff;
      font-size: 4vw;
      line-height: normal;

      @media (min-width: @vikinglotto-desktop-width) {
        font-size: 1.5rem;
        width: @vikinglotto-desktop-width;
      }

      .a {
        cursor: pointer;
        color: #fff;

        &.hd {
          text-decoration: none;
        }
      }
    }

    .hr {
      width: @vikinglotto-device-width;
      border-width: .2rem;
      border-style: solid;
      border-color: #ededee;
      border-top: 0;
      margin: 6vw auto;

      @media (min-width: @vikinglotto-desktop-width) {
        margin: 2.5rem auto;
        max-width: @vikinglotto-desktop-width;
      }
    }
  }
}

// apply the `manchete` class to any `section` or `vikinglotto-section` for the desired manchete effect
.section.manchete,
.vikinglotto-section.manchete {
  position: relative;

  &.top:before,
  &.bot:after {
    content: '';
    background-image: url(/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons.svg#manchete-vikinglotto_use);
    display: block;
    width: 100%;
    height: 2vw;
    position: absolute;
    background-size: 100% 100%;
    z-index: 1;
  }

  &.top.manchete--white:before,
  &.bot.manchete--white:after {
    background-image: url(/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons.svg#manchete-vikinglotto--white_use)
  }

  // move manchete on top of div, subtract 1px to prevent 1px gap on retina screen
  &.top:before {
    top: ~"calc(-2vw + 1px)";
  }

  &.bot:after {
    bottom: ~"calc(-2vw + 1px)";
    transform: rotate(.5turn);
  }
}
