.btn {
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 1vw;
  cursor: pointer;
  color: #4f5254;
  font-weight: bold;
  font-size: 3.8vw;
  line-height: 10.7vw;
  padding: 0 4vw;
  display: inline-block;
  position: relative;
  vertical-align: top;
  text-decoration: none;
  background-color: #feb700;
  min-width: 32vw;
  overflow: hidden;
}
@media (min-width: 950px) {
  .btn {
    min-width: 12rem;
    font-size: 1.4rem;
    line-height: 4rem;
    border-radius: 5px;
    padding: 0 1.2rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .btn {
    font-size: 3vw;
    line-height: 8vw;
  }
}
.btn:hover {
  background-color: #feb700;
}
.btn.cta {
  background-color: #feb700;
  color: #2b2b2b;
}
.btn.grey {
  color: #fff;
  background-color: #a3a6ab;
}
.btn.close {
  position: absolute;
  right: 0;
  width: 4.7vw;
  height: 4.7vw;
  padding: 0;
  background-color: transparent;
}
@media (min-width: 950px) {
  .btn.close {
    width: 2rem;
    height: 2rem;
  }
}
.btn.close:before,
.btn.close:after {
  content: ' ';
  position: absolute;
  left: 45%;
  top: -15%;
  display: block;
  height: 6vw;
  width: 0.7vw;
  background-color: #4f5254;
}
@media (min-width: 950px) {
  .btn.close:before,
  .btn.close:after {
    height: 2.6rem;
    width: 0.2rem;
  }
}
.btn.close:before {
  transform: rotate(45deg) translateZ(0);
}
.btn.close:after {
  transform: rotate(-45deg) translateZ(0);
}
.vikinglotto-checkbox {
  text-align: left;
  position: relative;
  color: #999;
  display: inline-block;
}
.vikinglotto-checkbox > label {
  cursor: pointer;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.vikinglotto-checkbox .ios-skin {
  float: left;
  position: relative;
  display: inline-block;
  height: 7.3vw;
  cursor: pointer;
  top: 1.5vw;
}
@media (min-width: 950px) {
  .vikinglotto-checkbox .ios-skin {
    height: 2.8rem;
    top: .9rem;
  }
}
.vikinglotto-checkbox .ios-skin > input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
.vikinglotto-checkbox .ios-skin > input {
  line-height: normal;
  position: absolute;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
.vikinglotto-checkbox .ios-skin .ios-skin_body {
  position: relative;
  display: inline-block;
  width: 14vw;
  height: 7.3vw;
  border-radius: 7.3vw;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: background-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: background-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
@media (min-width: 950px) {
  .vikinglotto-checkbox .ios-skin .ios-skin_body {
    width: 6rem;
    height: 2.8rem;
    border-radius: 2.8rem;
  }
}
.vikinglotto-checkbox .ios-skin > :checked ~ .ios-skin_body .ios-skin_switch {
  -webkit-transform: translateX(6.7vw);
  transform: translateX(6.7vw);
}
@media (min-width: 950px) {
  .vikinglotto-checkbox .ios-skin > :checked ~ .ios-skin_body .ios-skin_switch {
    -webkit-transform: translateX(3.2rem);
    transform: translateX(3.2rem);
  }
}
.vikinglotto-checkbox .ios-skin .checkmark {
  position: absolute;
  right: 5.5vw;
  bottom: 3.8vw;
  z-index: 1;
}
@media (min-width: 950px) {
  .vikinglotto-checkbox .ios-skin .checkmark {
    width: 0.4rem;
    height: 1.5rem;
    right: 0;
    bottom: 0.1rem;
    display: inline-block;
    margin: -1.3rem 2.2rem 0 0;
  }
}
.vikinglotto-checkbox .ios-skin .checkmark:after {
  content: "";
  position: absolute;
  background-color: #1d1d1d;
  height: 0.8vw;
  width: 2.9vw;
}
@media (min-width: 950px) {
  .vikinglotto-checkbox .ios-skin .checkmark:after {
    height: 0.4rem;
    width: 1.5rem;
  }
}
.vikinglotto-checkbox .ios-skin > :checked ~ .checkmark {
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);
  right: 10.3vw;
  bottom: 2.1vw;
  margin: -1vw 0 0 2.5vw;
}
@media (min-width: 950px) {
  .vikinglotto-checkbox .ios-skin > :checked ~ .checkmark {
    right: 4rem;
    bottom: 0.6rem;
    margin: -0.8rem 0 0 1.8rem;
  }
}
.vikinglotto-checkbox .ios-skin > :checked ~ .checkmark:before {
  content: "";
  position: absolute;
  width: 0.8vw;
  height: 2.9vw;
  background-color: #1d1d1d;
}
@media (min-width: 950px) {
  .vikinglotto-checkbox .ios-skin > :checked ~ .checkmark:before {
    height: 1.5rem;
    width: 0.4rem;
  }
}
.vikinglotto-checkbox .ios-skin > :checked ~ .checkmark:after {
  width: 1.93333333vw;
}
@media (min-width: 950px) {
  .vikinglotto-checkbox .ios-skin > :checked ~ .checkmark:after {
    width: 1rem;
    bottom: 1.1rem;
  }
}
.vikinglotto-checkbox .ios-skin .ios-skin_switch {
  position: absolute;
  /*top: -@vikinglotto-radio-switch-border-width;
        left: -@vikinglotto-radio-switch-border-width;*/
  display: inline-block;
  width: 7.3vw;
  height: 7.3vw;
  border: 0.6vw solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: #feb700;
  -webkit-transition: 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
@media (min-width: 950px) {
  .vikinglotto-checkbox .ios-skin .ios-skin_switch {
    box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.13);
    width: 2.8rem;
    height: 2.8rem;
    border: 0.2rem solid #fff;
  }
}
.vikinglotto-checkbox .ios-skin > :not(:checked) ~ .ios-skin_body {
  border-color: transparent;
  background-color: rgba(255, 255, 255, 0.6);
}
.vikinglotto-checkbox .ios-skin > :not(:checked) ~ .ios-skin_body .ios-skin_switch {
  border-color: transparent;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  /* for Safari */
  background-clip: padding-box;
  /* for IE9+, Firefox 4+, Opera, Chrome */
}
.vikinglotto-checkbox label:not(.ios-skin) {
  height: 7.3vw;
  line-height: 7.3vw;
  text-align: left;
  position: absolute;
  top: 0;
  left: 2vw;
  vertical-align: middle;
  z-index: 1;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (min-width: 950px) {
  .vikinglotto-checkbox label:not(.ios-skin) {
    height: 2.8rem;
    line-height: 2.8rem;
    left: 1rem;
  }
}
.joker-container {
  margin: auto;
  padding: 4vw 0;
  text-align: center;
}
@media (min-width: 950px) {
  .joker-container {
    padding: 1rem 0 3rem;
  }
}
.joker-container .vikinglotto-checkbox label:not(.ios-skin) {
  position: relative;
  left: -3vw;
}
@media (min-width: 950px) {
  .joker-container .vikinglotto-checkbox label:not(.ios-skin) {
    left: -0.5rem;
  }
}
.joker-container .vikinglotto-checkbox label:not(.ios-skin) .joker-label-icon {
  width: 34.8vw;
  height: 6.4vw;
  position: relative;
  top: 1.7vw;
}
@media (min-width: 950px) {
  .joker-container .vikinglotto-checkbox label:not(.ios-skin) .joker-label-icon {
    width: 14.8rem;
    height: 2.8rem;
    top: .3rem;
  }
}
.joker-container .joker-info-text {
  font-size: 3.773vw;
  font-weight: 100;
  color: #fff;
}
@media (min-width: 950px) {
  .joker-container .joker-info-text {
    font-size: 1.6rem;
  }
}
