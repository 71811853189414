@import (reference) "../../../AvalonComponents/Styles/Colors/_colors";
@import (reference) "../../../AvalonComponents/Styles/Variables/breakpoints";

// Defaults
@vikinglotto-large-desktop-width: 1200px;
@vikinglotto-desktop-width: 950px;
@vikinglotto-mobile-width: 768px;
@vikinglotto-device-width: 86.5vw;

// Colors
@vikinglotto-cta-yellow: #feb700;
@vikinglotto-cta-yellow-hover: #f5d900;
@vikinglotto-white: #fff;
@vikinglotto-black: #000;
@vikinglotto-btn-color: #feb700;
@vikinglotto-btn-color-hover: #feb700;

@vikinglotto-darkgray: #1d1d1d;
@vikinglotto-mediumgray: #dadbdd;
@vikinglotto-lightgray: #4f5254;
@vikinglotto-gray: #2b2b2b;
@vikinglotto-whitesmoke: #ededee;
@vikinglotto-silver: #a3a6ab;

@vikinglotto-blue: #0000ff;
@vikinglotto-lightblue: #0000ff;
@vikinglotto-mediumblue: #0000b4;
@vikinglotto-darkblue: rgba(0, 0, 120, 1); // same as #000078
@vikinglotto-midnight: #d0e3ff;

// Mosaik colors
@vikinglotto-mosaik-babyblue: #a8e1fc;
@vikinglotto-mosaik-darkblue: #020B75;
@vikinglotto-mosaik-pink: #ff7387;
@vikinglotto-mosaik-purple: rgb(100,0,150);
@vikinglotto-mosaik-yellow: #fcff8a;


@vikinglotto-lightgreen: #009a60;
@vikinglotto-red: #c50005;
@vikinglotto-yellow: #feb700;

@vikinglotto-curve-radius: 50% 5rem;
@vikinglotto-curve-radius-xl: 60% 5.5rem;
@vikinglotto-curve-radius-xxl: 770% 35rem;
@vikinglotto-curve-stretch: -6rem;
@vikinglotto-curve-stretch-xl: -7.5vw;
@vikinglotto-curve-stretch-xxl: 0;

.text-shadow (@string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
  text-shadow: @string;
}

.box-shadow (@string) {
  -webkit-box-shadow: @string;
  -moz-box-shadow: @string;
  box-shadow: @string;
}

.drop-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
  -webkit-box-shadow: @x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow: @x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow: @x @y @blur @spread rgba(0, 0, 0, @alpha);
}

.inner-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
  -webkit-box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
}

.box-sizing (@type: border-box) {
  -webkit-box-sizing: @type;
  -moz-box-sizing: @type;
  box-sizing: @type;
}

.border-radius (@radius: .5rem) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;

  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.border-radiuses (@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
  -webkit-border-top-right-radius: @topright;
  -webkit-border-bottom-right-radius: @bottomright;
  -webkit-border-bottom-left-radius: @bottomleft;
  -webkit-border-top-left-radius: @topleft;

  -moz-border-radius-topright: @topright;
  -moz-border-radius-bottomright: @bottomright;
  -moz-border-radius-bottomleft: @bottomleft;
  -moz-border-radius-topleft: @topleft;

  border-top-right-radius: @topright;
  border-bottom-right-radius: @bottomright;
  border-bottom-left-radius: @bottomleft;
  border-top-left-radius: @topleft;

  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.opacity (@opacity: 0.5) {
  -webkit-opacity: @opacity;
  -moz-opacity: @opacity;
  opacity: @opacity;
}

.gradient (@startColor: #eee, @endColor: white) {
  background-color: @startColor;
  background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
  background: -webkit-linear-gradient(top, @startColor, @endColor);
  background: -moz-linear-gradient(top, @startColor, @endColor);
  background: -ms-linear-gradient(top, @startColor, @endColor);
  background: -o-linear-gradient(top, @startColor, @endColor);
}

.horizontal-gradient (@startColor: #eee, @endColor: white) {
  background-color: @startColor;
  background-image: -webkit-gradient(linear, left top, right top, from(@startColor), to(@endColor));
  background-image: -webkit-linear-gradient(left, @startColor, @endColor);
  background-image: -moz-linear-gradient(left, @startColor, @endColor);
  background-image: -ms-linear-gradient(left, @startColor, @endColor);
  background-image: -o-linear-gradient(left, @startColor, @endColor);
}

.animation (@name) {
  -webkit-animation: @name;
  -moz-animation: @name;
  -ms-animation: @name;
  animation: @name;
}

.keyframes(@name; @arguments) {
  @-webkit-keyframes @name { @arguments();
  }
  @-moz-keyframes @name { @arguments();
  }
  @-ms-keyframes @name { @arguments();
  }
  @keyframes @name { @arguments();
  }
}

.transition (@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -ms-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}

.transform (@string) {
  -webkit-transform: @string;
  -moz-transform: @string;
  -ms-transform: @string;
  -o-transform: @string;
  transform: @string;
}

.transitionWithTransform (@transition) {
  -webkit-transition: e("-webkit-@{transition}");
  -moz-transition: e("-moz-@{transition}");
  -ms-transition: e("-ms-@{transition}");
  -o-transition: e("-o-@{transition}");
  transition: @transition;
}

.scale (@factor) {
  -webkit-transform: scale(@factor);
  -moz-transform: scale(@factor);
  -ms-transform: scale(@factor);
  -o-transform: scale(@factor);
}

.rotate (@deg) {
  -webkit-transform: rotate(@deg);
  -moz-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  -o-transform: rotate(@deg);
  transform: rotate(@deg);
}

.skew (@deg, @deg2) {
  -webkit-transform: skew(@deg, @deg2);
  -moz-transform: skew(@deg, @deg2);
  -ms-transform: skew(@deg, @deg2);
  -o-transform: skew(@deg, @deg2);
}

.translate (@x, @y:0) {
  -webkit-transform: translate(@x, @y);
  -moz-transform: translate(@x, @y);
  -ms-transform: translate(@x, @y);
  -o-transform: translate(@x, @y);
}

.translate3d (@x, @y: 0, @z: 0) {
  -webkit-transform: translate3d(@x, @y, @z);
  -moz-transform: translate3d(@x, @y, @z);
  -ms-transform: translate3d(@x, @y, @z);
  -o-transform: translate3d(@x, @y, @z);
}

.perspective (@value: 1000) {
  -webkit-perspective: @value;
  -moz-perspective: @value;
  -ms-perspective: @value;
  perspective: @value;
}

.transform-origin (@x:center, @y:center) {
  -webkit-transform-origin: @x @y;
  -moz-transform-origin: @x @y;
  -ms-transform-origin: @x @y;
  -o-transform-origin: @x @y;
  transform-origin: @x @y;
}

.transform-style(@option) {
  -webkit-transform-style: @option;
  -moz-transform-style: @option;
  -ms-transform-style: @option;
  transform-style: @option;
}

.blur(@value) {
  -webkit-filter: blur(@value);
  -moz-filter: blur(@value);
  -ms-filter: blur(@value);
  filter: blur(@value);
}

.user-select (@option) {
  -webkit-user-select: @option;
  -khtml-user-select: @option;
  -moz-user-select: @option;
  -ms-user-select: @option;
  user-select: @option;
}

.clearfix() {
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
