﻿// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

@cell-width--tablet: calc(~'16.666% - 16px');
@cell-height--tablet: 8vw;

.vikinglotto-classic-game,
.vikinglotto-system-game,
.vikinglotto-lucky-game {

  .statistics-component-for-game-client-wrapper {
    border-color: @vikinglotto-darkblue;
    background-color: @vikinglotto-mediumblue;

    .statistics-component-outer-wrapper {

      .statistics-component-toggle-button-wrapper {
        .toggle-button {
          .numbers-count-wrapper {
            background-color: @vikinglotto-blue;
          }
        }
      }

      .statistics-component-inner-wrapper {
        box-sizing: content-box;

        .numbers-from-statistics-wrapper {
          .selected-number-box {
            background-color: @vikinglotto-blue;

            .number-cell {
              width: 8vw;
              height: 8vw;
              font-size: 3vw;
              line-height: 1;
              margin: 1vw;
              padding-top: 2.2vw;
              color: @vikinglotto-darkgray;
              background-color: @vikinglotto-white;
              border-radius: 50%;
              box-sizing: border-box;

              @media (min-width: @vikinglotto-desktop-width) {
                width: 4rem;
                height: 4rem;
                font-size: 1.6rem;
                line-height: 1.5;
                margin: .5rem;
                padding-top: .8rem;
              }
            }

            &.special {
              .number-cell {
                color: @vikinglotto-white;
                padding-top: 1.9vw;
                border-radius: 0;
                background-color: transparent;
                background-image: url(/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons/icon-viking-helmet-white.svg);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                box-sizing: border-box;

                @media (min-width: @vikinglotto-desktop-width) {
                  padding-top: .6rem;
                }
              }
            }

            &.selected {
              .number-cell {
                background-color: @vikinglotto-yellow;
              }

              &.special {
                .number-cell {
                  color: @vikinglotto-yellow;
                  background-color: transparent;
                  background-image: url(/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons/icon-viking-helmet-yellow.svg);
                }
              }
            }
          }
        }
      }
    }
  }
}
.vikinglotto-classic-game {
  .statistics-component-for-game-client-wrapper {
    & + .vikinglotto-classic-game-section {
      .vikinglotto-content-wrapper {
        .rows-container {
          @media (min-width: @vikinglotto-desktop-width) {
            padding-top: 2rem;
          }
        }
      }
    }
  }
}
