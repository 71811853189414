.vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper,
.vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper,
.vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper,
.vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper {
  padding: 0;
}
.vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .number-picker-footer,
.vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .number-picker-footer,
.vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .number-picker-footer,
.vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .number-picker-footer {
  padding: 4.034vw 0 0;
}
@media (min-width: 950px) {
  .vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .number-picker-footer,
  .vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .number-picker-footer,
  .vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .number-picker-footer,
  .vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .number-picker-footer {
    padding: 1.8rem 0 2.4rem;
  }
}
.vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container,
.vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container,
.vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container,
.vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container {
  width: 84.535%;
  margin: auto;
  position: relative;
  margin-bottom: 4vw;
}
@media (min-width: 950px) {
  .vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container,
  .vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container,
  .vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container,
  .vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container {
    width: 58rem;
    padding-top: 2.5rem;
    margin-bottom: 0rem;
  }
}
@media (min-width: 950px) {
  .vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container:before,
  .vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container:before,
  .vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container:before,
  .vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container:before {
    position: absolute;
    bottom: 9.4rem;
    left: -50vw;
    right: -50vw;
    background-color: #fff;
    height: 2px;
  }
}
.vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container,
.vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container,
.vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container,
.vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container {
  padding-bottom: 0vw;
}
@media (min-width: 950px) {
  .vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container,
  .vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container,
  .vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container,
  .vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container {
    padding-bottom: .6rem;
  }
}
.vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header,
.vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header,
.vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header,
.vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header {
  padding-top: 3.3vw;
  padding-bottom: 1.9vw;
  margin: 0 0 2.6vw;
}
@media (min-width: 950px) {
  .vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header,
  .vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header,
  .vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header,
  .vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header {
    padding-top: 0;
    padding-bottom: 2.5rem;
    margin: 0 0 2rem;
  }
}
.vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header .number-of-rows,
.vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header .number-of-rows,
.vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header .number-of-rows,
.vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header .number-of-rows {
  display: block;
  opacity: .6;
}
@media (min-width: 950px) {
  .vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header .number-of-rows,
  .vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header .number-of-rows,
  .vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header .number-of-rows,
  .vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header .number-of-rows {
    padding-left: 1vw;
    display: inline-block;
  }
}
.vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .number-picker-container .number-picker-wrapper,
.vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .number-picker-container .number-picker-wrapper,
.vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .number-picker-container .number-picker-wrapper,
.vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .number-picker-container .number-picker-wrapper {
  border-bottom: none;
}
.vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container,
.vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container,
.vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container,
.vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container {
  position: absolute;
}
.vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box,
.vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box,
.vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box,
.vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box {
  font-size: 3.733vw;
  padding: .1vw 0 1vw;
  display: block;
  clear: both;
}
@media (min-width: 950px) {
  .vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box,
  .vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box,
  .vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box,
  .vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box {
    font-size: 1.4rem;
    padding: .1rem 0 .5rem;
  }
}
@media (min-width: 950px) {
  .vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box .mobile-hide,
  .vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box .mobile-hide,
  .vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box .mobile-hide,
  .vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box .mobile-hide {
    display: inline;
  }
}
.vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box .mobile-only,
.vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box .mobile-only,
.vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box .mobile-only,
.vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box .mobile-only {
  display: inline;
}
@media (min-width: 950px) {
  .vikinglotto-system-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box .mobile-only,
  .vikinglotto-lucky-game .vikinglotto-system-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box .mobile-only,
  .vikinglotto-system-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box .mobile-only,
  .vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .counter-container .counter-box .mobile-only {
    display: none;
  }
}
.vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header {
  padding-top: 5.05vw;
  padding-bottom: 4.55vw;
}
@media (min-width: 950px) {
  .vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .rows-container .row-container .row-header {
    padding-top: 0;
    padding-bottom: 2.5rem;
  }
}
.vikinglotto-lucky-game .vikinglotto-lucky-game-section .vikinglotto-content-wrapper .number-picker-footer .buttons-container .reset-row-button {
  float: inherit;
}
