.vikinglotto-lucky-game .number-picker-footer .buttons-container {
  text-align: center;
}
.vikinglotto-classic-game .number-picker-footer,
.vikinglotto-system-game .number-picker-footer,
.vikinglotto-lucky-game .number-picker-footer,
.vikinglotto-winning-numbers .number-picker-footer {
  padding: 4.034vw 0 2.134vw;
  position: relative;
  margin: 0 1.9%;
  border-top: 1px solid #fff;
}
.vikinglotto-classic-game .number-picker-footer:after,
.vikinglotto-system-game .number-picker-footer:after,
.vikinglotto-lucky-game .number-picker-footer:after,
.vikinglotto-winning-numbers .number-picker-footer:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-footer,
  .vikinglotto-system-game .number-picker-footer,
  .vikinglotto-lucky-game .number-picker-footer,
  .vikinglotto-winning-numbers .number-picker-footer {
    padding: 3rem 1rem 1.1rem;
    text-align: center;
    margin: 0 1rem;
  }
}
.vikinglotto-classic-game .number-picker-footer .buttons-container,
.vikinglotto-system-game .number-picker-footer .buttons-container,
.vikinglotto-lucky-game .number-picker-footer .buttons-container,
.vikinglotto-winning-numbers .number-picker-footer .buttons-container {
  white-space: nowrap;
}
.vikinglotto-classic-game .number-picker-footer .buttons-container .button,
.vikinglotto-system-game .number-picker-footer .buttons-container .button,
.vikinglotto-lucky-game .number-picker-footer .buttons-container .button,
.vikinglotto-winning-numbers .number-picker-footer .buttons-container .button {
  min-width: 23.2vw;
  font-size: 3.734vw;
  line-height: 1.36;
  font-weight: bold;
  color: #1d1d1d;
  text-align: left;
  background-color: #fff;
  margin-left: 3.334vw;
  padding: 3.05vw 3.6vw 2.55vw;
  border-radius: 6px;
  display: inline-block;
  text-transform: uppercase;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  transition: opacity 0.25s, background-color 0.25s, color 0.25s, transform 0.25s;
  user-select: none;
  overflow: hidden;
  will-change: transform;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-footer .buttons-container .button:hover,
  .vikinglotto-system-game .number-picker-footer .buttons-container .button:hover,
  .vikinglotto-lucky-game .number-picker-footer .buttons-container .button:hover,
  .vikinglotto-winning-numbers .number-picker-footer .buttons-container .button:hover {
    transform: scale(1.05);
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-footer .buttons-container .button,
  .vikinglotto-system-game .number-picker-footer .buttons-container .button,
  .vikinglotto-lucky-game .number-picker-footer .buttons-container .button,
  .vikinglotto-winning-numbers .number-picker-footer .buttons-container .button {
    min-width: initial;
    min-width: inherit;
    font-size: 1.6rem;
    margin-left: 2rem;
    padding: 1.3rem 1.8rem 1.1rem 1.8rem;
    cursor: pointer;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .number-picker-footer .buttons-container .button,
  .vikinglotto-system-game .number-picker-footer .buttons-container .button,
  .vikinglotto-lucky-game .number-picker-footer .buttons-container .button,
  .vikinglotto-winning-numbers .number-picker-footer .buttons-container .button {
    font-size: 1.7rem;
    text-align: center;
  }
}
.vikinglotto-classic-game .number-picker-footer .buttons-container .button:first-child,
.vikinglotto-system-game .number-picker-footer .buttons-container .button:first-child,
.vikinglotto-lucky-game .number-picker-footer .buttons-container .button:first-child,
.vikinglotto-winning-numbers .number-picker-footer .buttons-container .button:first-child {
  margin-left: 0;
  float: left;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-footer .buttons-container .button:first-child,
  .vikinglotto-system-game .number-picker-footer .buttons-container .button:first-child,
  .vikinglotto-lucky-game .number-picker-footer .buttons-container .button:first-child,
  .vikinglotto-winning-numbers .number-picker-footer .buttons-container .button:first-child {
    float: inherit;
  }
}
.vikinglotto-classic-game .number-picker-footer .buttons-container .button.inactive,
.vikinglotto-system-game .number-picker-footer .buttons-container .button.inactive,
.vikinglotto-lucky-game .number-picker-footer .buttons-container .button.inactive,
.vikinglotto-winning-numbers .number-picker-footer .buttons-container .button.inactive {
  -webkit-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  cursor: default;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-footer .buttons-container .button.inactive:hover,
  .vikinglotto-system-game .number-picker-footer .buttons-container .button.inactive:hover,
  .vikinglotto-lucky-game .number-picker-footer .buttons-container .button.inactive:hover,
  .vikinglotto-winning-numbers .number-picker-footer .buttons-container .button.inactive:hover {
    transform: scale(1);
  }
}
.vikinglotto-classic-game .number-picker-footer .buttons-container .button.autogenerating-numbers,
.vikinglotto-system-game .number-picker-footer .buttons-container .button.autogenerating-numbers,
.vikinglotto-lucky-game .number-picker-footer .buttons-container .button.autogenerating-numbers,
.vikinglotto-winning-numbers .number-picker-footer .buttons-container .button.autogenerating-numbers {
  opacity: .3;
}
@keyframes vikinglotto-autogenerate-button-animation {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}
.vikinglotto-classic-game .number-picker-footer .buttons-container .button.autogenerating-numbers:before,
.vikinglotto-system-game .number-picker-footer .buttons-container .button.autogenerating-numbers:before,
.vikinglotto-lucky-game .number-picker-footer .buttons-container .button.autogenerating-numbers:before,
.vikinglotto-winning-numbers .number-picker-footer .buttons-container .button.autogenerating-numbers:before,
.vikinglotto-classic-game .number-picker-footer .buttons-container .button.autogenerating-numbers:after,
.vikinglotto-system-game .number-picker-footer .buttons-container .button.autogenerating-numbers:after,
.vikinglotto-lucky-game .number-picker-footer .buttons-container .button.autogenerating-numbers:after,
.vikinglotto-winning-numbers .number-picker-footer .buttons-container .button.autogenerating-numbers:after {
  content: '';
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;
  background: radial-gradient(center, ellipse cover, rgba(43, 43, 43, 0) 0%, rgba(43, 43, 43, 0) 20%, rgba(43, 43, 43, 0.65) 45%, rgba(43, 43, 43, 0.9) 55%, rgba(43, 43, 43, 0.5) 65%, rgba(43, 43, 43, 0) 90%, rgba(43, 43, 43, 0) 100%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
}
.vikinglotto-classic-game .number-picker-footer .buttons-container .button.autogenerating-numbers:before,
.vikinglotto-system-game .number-picker-footer .buttons-container .button.autogenerating-numbers:before,
.vikinglotto-lucky-game .number-picker-footer .buttons-container .button.autogenerating-numbers:before,
.vikinglotto-winning-numbers .number-picker-footer .buttons-container .button.autogenerating-numbers:before {
  animation: vikinglotto-autogenerate-button-animation 0.8s linear infinite;
}
.vikinglotto-classic-game .number-picker-footer .buttons-container .button.autogenerating-numbers:after,
.vikinglotto-system-game .number-picker-footer .buttons-container .button.autogenerating-numbers:after,
.vikinglotto-lucky-game .number-picker-footer .buttons-container .button.autogenerating-numbers:after,
.vikinglotto-winning-numbers .number-picker-footer .buttons-container .button.autogenerating-numbers:after {
  animation: vikinglotto-autogenerate-button-animation 0.8s 0.3s linear infinite;
}
.vikinglotto-classic-game .number-picker-footer .buttons-container .button.autogenerating-numbers:hover,
.vikinglotto-system-game .number-picker-footer .buttons-container .button.autogenerating-numbers:hover,
.vikinglotto-lucky-game .number-picker-footer .buttons-container .button.autogenerating-numbers:hover,
.vikinglotto-winning-numbers .number-picker-footer .buttons-container .button.autogenerating-numbers:hover {
  cursor: default;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-footer .buttons-container .button.autogenerating-numbers:hover,
  .vikinglotto-system-game .number-picker-footer .buttons-container .button.autogenerating-numbers:hover,
  .vikinglotto-lucky-game .number-picker-footer .buttons-container .button.autogenerating-numbers:hover,
  .vikinglotto-winning-numbers .number-picker-footer .buttons-container .button.autogenerating-numbers:hover {
    transform: scale(1);
  }
}
.vikinglotto-classic-game .number-picker-footer .buttons-container .button.reset-row-button,
.vikinglotto-system-game .number-picker-footer .buttons-container .button.reset-row-button,
.vikinglotto-lucky-game .number-picker-footer .buttons-container .button.reset-row-button,
.vikinglotto-winning-numbers .number-picker-footer .buttons-container .button.reset-row-button {
  float: right;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-footer .buttons-container .button.reset-row-button,
  .vikinglotto-system-game .number-picker-footer .buttons-container .button.reset-row-button,
  .vikinglotto-lucky-game .number-picker-footer .buttons-container .button.reset-row-button,
  .vikinglotto-winning-numbers .number-picker-footer .buttons-container .button.reset-row-button {
    float: none;
  }
}
.vikinglotto-classic-game .number-picker-footer .buttons-container .autogenerate-button .icon,
.vikinglotto-system-game .number-picker-footer .buttons-container .autogenerate-button .icon,
.vikinglotto-lucky-game .number-picker-footer .buttons-container .autogenerate-button .icon,
.vikinglotto-winning-numbers .number-picker-footer .buttons-container .autogenerate-button .icon,
.vikinglotto-classic-game .number-picker-footer .buttons-container .reset-row-button .icon,
.vikinglotto-system-game .number-picker-footer .buttons-container .reset-row-button .icon,
.vikinglotto-lucky-game .number-picker-footer .buttons-container .reset-row-button .icon,
.vikinglotto-winning-numbers .number-picker-footer .buttons-container .reset-row-button .icon {
  width: 2.934vw;
  height: 3.7vw;
  fill: #4f5254;
  position: absolute;
  top: 3.4vw;
  right: 3.7vw;
  -webkit-transition: fill 0.25s;
  -moz-transition: fill 0.25s;
  -ms-transition: fill 0.25s;
  -o-transition: fill 0.25s;
  transition: fill 0.25s;
  overflow: visible;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-footer .buttons-container .autogenerate-button .icon,
  .vikinglotto-system-game .number-picker-footer .buttons-container .autogenerate-button .icon,
  .vikinglotto-lucky-game .number-picker-footer .buttons-container .autogenerate-button .icon,
  .vikinglotto-winning-numbers .number-picker-footer .buttons-container .autogenerate-button .icon,
  .vikinglotto-classic-game .number-picker-footer .buttons-container .reset-row-button .icon,
  .vikinglotto-system-game .number-picker-footer .buttons-container .reset-row-button .icon,
  .vikinglotto-lucky-game .number-picker-footer .buttons-container .reset-row-button .icon,
  .vikinglotto-winning-numbers .number-picker-footer .buttons-container .reset-row-button .icon {
    width: 1.6rem;
    height: 1.6rem;
    top: 1.1rem;
    right: 1.4rem;
  }
}
@-webkit-keyframes vikinglotto-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-moz-keyframes vikinglotto-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-ms-keyframes vikinglotto-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@keyframes vikinglotto-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-webkit-keyframes vikinglotto-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes vikinglotto-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes vikinglotto-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes vikinglotto-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.vikinglotto-classic-game .row-container.shake-state .header-rules-text,
.vikinglotto-system-game .row-container.shake-state .header-rules-text,
.vikinglotto-lucky-game .row-container.shake-state .header-rules-text,
.vikinglotto-winning-numbers .row-container.shake-state .header-rules-text,
.vikinglotto-classic-game .row-container.shake-viking-numbers-state .header-rules-text,
.vikinglotto-system-game .row-container.shake-viking-numbers-state .header-rules-text,
.vikinglotto-lucky-game .row-container.shake-viking-numbers-state .header-rules-text,
.vikinglotto-winning-numbers .row-container.shake-viking-numbers-state .header-rules-text {
  -webkit-animation: vikinglotto-rules-pulse-animation 0.8s forwards;
  -moz-animation: vikinglotto-rules-pulse-animation 0.8s forwards;
  -ms-animation: vikinglotto-rules-pulse-animation 0.8s forwards;
  animation: vikinglotto-rules-pulse-animation 0.8s forwards;
}
.vikinglotto-classic-game .row-container.shake-state .row-header .tab-game-rules-text,
.vikinglotto-system-game .row-container.shake-state .row-header .tab-game-rules-text,
.vikinglotto-lucky-game .row-container.shake-state .row-header .tab-game-rules-text,
.vikinglotto-winning-numbers .row-container.shake-state .row-header .tab-game-rules-text,
.vikinglotto-classic-game .row-container.shake-viking-numbers-state .row-header .tab-game-rules-text,
.vikinglotto-system-game .row-container.shake-viking-numbers-state .row-header .tab-game-rules-text,
.vikinglotto-lucky-game .row-container.shake-viking-numbers-state .row-header .tab-game-rules-text,
.vikinglotto-winning-numbers .row-container.shake-viking-numbers-state .row-header .tab-game-rules-text {
  display: inline-block;
  animation: vikinglotto-rules-pulse-animation 0.8s forwards;
}
.vikinglotto-classic-game .row-container.shake-state .cells-container .selected,
.vikinglotto-system-game .row-container.shake-state .cells-container .selected,
.vikinglotto-lucky-game .row-container.shake-state .cells-container .selected,
.vikinglotto-winning-numbers .row-container.shake-state .cells-container .selected {
  -webkit-animation: vikinglotto-number-cell-shake-animation 0.8s forwards;
  -moz-animation: vikinglotto-number-cell-shake-animation 0.8s forwards;
  -ms-animation: vikinglotto-number-cell-shake-animation 0.8s forwards;
  animation: vikinglotto-number-cell-shake-animation 0.8s forwards;
}
.vikinglotto-classic-game .row-container.shake-viking-numbers-state .row-vikings-container .selected,
.vikinglotto-system-game .row-container.shake-viking-numbers-state .row-vikings-container .selected,
.vikinglotto-lucky-game .row-container.shake-viking-numbers-state .row-vikings-container .selected,
.vikinglotto-winning-numbers .row-container.shake-viking-numbers-state .row-vikings-container .selected {
  -webkit-animation: vikinglotto-number-cell-shake-animation 0.6s forwards;
  -moz-animation: vikinglotto-number-cell-shake-animation 0.6s forwards;
  -ms-animation: vikinglotto-number-cell-shake-animation 0.6s forwards;
  animation: vikinglotto-number-cell-shake-animation 0.6s forwards;
}
.vikinglotto-classic-game .row-header,
.vikinglotto-system-game .row-header,
.vikinglotto-lucky-game .row-header,
.vikinglotto-winning-numbers .row-header {
  font-size: 3.466vw;
  line-height: 1.3;
  color: #fff;
  padding-bottom: 2.134vw;
  border-bottom: 1px solid #fff;
  margin: 0 1.4vw 2.6vw;
}
.vikinglotto-classic-game .row-header:after,
.vikinglotto-system-game .row-header:after,
.vikinglotto-lucky-game .row-header:after,
.vikinglotto-winning-numbers .row-header:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-header,
  .vikinglotto-system-game .row-header,
  .vikinglotto-lucky-game .row-header,
  .vikinglotto-winning-numbers .row-header {
    font-size: 1.6rem;
    padding-bottom: 2.5rem;
    margin: 0 1rem 2rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .row-header,
  .vikinglotto-system-game .row-header,
  .vikinglotto-lucky-game .row-header,
  .vikinglotto-winning-numbers .row-header {
    font-size: 2rem;
  }
}
.vikinglotto-classic-game .row-header .counter,
.vikinglotto-system-game .row-header .counter,
.vikinglotto-lucky-game .row-header .counter,
.vikinglotto-winning-numbers .row-header .counter {
  width: 88%;
  text-align: center;
  float: left;
  opacity: .6;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-header .counter,
  .vikinglotto-system-game .row-header .counter,
  .vikinglotto-lucky-game .row-header .counter,
  .vikinglotto-winning-numbers .row-header .counter {
    width: auto;
    padding-right: 2rem;
    text-align: left;
    float: inherit;
  }
}
.vikinglotto-classic-game .row-header .right-side,
.vikinglotto-system-game .row-header .right-side,
.vikinglotto-lucky-game .row-header .right-side,
.vikinglotto-winning-numbers .row-header .right-side {
  padding-right: 2.667vw;
  position: relative;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-header .right-side,
  .vikinglotto-system-game .row-header .right-side,
  .vikinglotto-lucky-game .row-header .right-side,
  .vikinglotto-winning-numbers .row-header .right-side {
    padding-right: 1.5rem;
    display: inline-block;
    position: static;
  }
}
.vikinglotto-classic-game .row-header .right-side .row-delete-button,
.vikinglotto-system-game .row-header .right-side .row-delete-button,
.vikinglotto-lucky-game .row-header .right-side .row-delete-button,
.vikinglotto-winning-numbers .row-header .right-side .row-delete-button {
  display: block;
  position: absolute;
  top: 2.65vw;
  right: 2.1vw;
  cursor: pointer;
  transition: opacity .25s, transform .15s;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-header .right-side .row-delete-button:hover,
  .vikinglotto-system-game .row-header .right-side .row-delete-button:hover,
  .vikinglotto-lucky-game .row-header .right-side .row-delete-button:hover,
  .vikinglotto-winning-numbers .row-header .right-side .row-delete-button:hover {
    transform: scale(1.4);
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-header .right-side .row-delete-button,
  .vikinglotto-system-game .row-header .right-side .row-delete-button,
  .vikinglotto-lucky-game .row-header .right-side .row-delete-button,
  .vikinglotto-winning-numbers .row-header .right-side .row-delete-button {
    top: .1rem;
    right: 1rem;
  }
}
.vikinglotto-classic-game .row-header .right-side .row-delete-button .icon,
.vikinglotto-system-game .row-header .right-side .row-delete-button .icon,
.vikinglotto-lucky-game .row-header .right-side .row-delete-button .icon,
.vikinglotto-winning-numbers .row-header .right-side .row-delete-button .icon {
  width: 2.667vw;
  height: 2.667vw;
  fill: #fff;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-header .right-side .row-delete-button .icon,
  .vikinglotto-system-game .row-header .right-side .row-delete-button .icon,
  .vikinglotto-lucky-game .row-header .right-side .row-delete-button .icon,
  .vikinglotto-winning-numbers .row-header .right-side .row-delete-button .icon {
    width: 1.2rem;
    height: 1.2rem;
  }
}
.vikinglotto-classic-game .row-header .right-side .row-delete-button.inactive,
.vikinglotto-system-game .row-header .right-side .row-delete-button.inactive,
.vikinglotto-lucky-game .row-header .right-side .row-delete-button.inactive,
.vikinglotto-winning-numbers .row-header .right-side .row-delete-button.inactive {
  -webkit-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  cursor: default;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-header .right-side .row-delete-button.inactive:hover,
  .vikinglotto-system-game .row-header .right-side .row-delete-button.inactive:hover,
  .vikinglotto-lucky-game .row-header .right-side .row-delete-button.inactive:hover,
  .vikinglotto-winning-numbers .row-header .right-side .row-delete-button.inactive:hover {
    transform: scale(1);
  }
}
.vikinglotto-classic-game .row-header .header-rules-text,
.vikinglotto-system-game .row-header .header-rules-text,
.vikinglotto-lucky-game .row-header .header-rules-text,
.vikinglotto-winning-numbers .row-header .header-rules-text {
  width: 90%;
  color: #ffffff;
  display: inline-block;
  text-align: center;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-header .header-rules-text,
  .vikinglotto-system-game .row-header .header-rules-text,
  .vikinglotto-lucky-game .row-header .header-rules-text,
  .vikinglotto-winning-numbers .row-header .header-rules-text {
    width: 100%;
    text-align: left;
  }
}
.vikinglotto-classic-game .number-picker-wrapper,
.vikinglotto-system-game .number-picker-wrapper,
.vikinglotto-lucky-game .number-picker-wrapper,
.vikinglotto-winning-numbers .number-picker-wrapper {
  position: relative;
  padding-bottom: 2.7vw;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-wrapper,
  .vikinglotto-system-game .number-picker-wrapper,
  .vikinglotto-lucky-game .number-picker-wrapper,
  .vikinglotto-winning-numbers .number-picker-wrapper {
    padding-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .number-picker-wrapper,
  .vikinglotto-system-game .number-picker-wrapper,
  .vikinglotto-lucky-game .number-picker-wrapper,
  .vikinglotto-winning-numbers .number-picker-wrapper {
    display: flex;
  }
}
.vikinglotto-classic-game .number-picker-container,
.vikinglotto-system-game .number-picker-container,
.vikinglotto-lucky-game .number-picker-container,
.vikinglotto-winning-numbers .number-picker-container {
  margin: 0 -1.9%;
  position: relative;
  white-space: normal;
  transform: translateZ(0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-container,
  .vikinglotto-system-game .number-picker-container,
  .vikinglotto-lucky-game .number-picker-container,
  .vikinglotto-winning-numbers .number-picker-container {
    margin: 0 -1rem;
  }
}
.vikinglotto-classic-game .number-picker-container .cells-container,
.vikinglotto-system-game .number-picker-container .cells-container,
.vikinglotto-lucky-game .number-picker-container .cells-container,
.vikinglotto-winning-numbers .number-picker-container .cells-container {
  width: 67.068vw;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .cells-container,
  .vikinglotto-system-game .number-picker-container .cells-container,
  .vikinglotto-lucky-game .number-picker-container .cells-container,
  .vikinglotto-winning-numbers .number-picker-container .cells-container {
    width: 80.2rem;
    margin: 0 0 2rem 0;
    padding: 0 1rem 0 0;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .cells-container,
  .vikinglotto-system-game .number-picker-container .cells-container,
  .vikinglotto-lucky-game .number-picker-container .cells-container,
  .vikinglotto-winning-numbers .number-picker-container .cells-container {
    width: 85%;
  }
}
.vikinglotto-classic-game .number-picker-container .cell,
.vikinglotto-system-game .number-picker-container .cell,
.vikinglotto-lucky-game .number-picker-container .cell,
.vikinglotto-winning-numbers .number-picker-container .cell {
  width: 8.4vw;
  height: 8.4vw;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 1.356vw;
  border-radius: 50%;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .cell,
  .vikinglotto-system-game .number-picker-container .cell,
  .vikinglotto-lucky-game .number-picker-container .cell,
  .vikinglotto-winning-numbers .number-picker-container .cell {
    width: 4.6rem;
    height: 0;
    padding-top: 4.6rem;
    margin: 1rem;
    cursor: pointer;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .cell,
  .vikinglotto-system-game .number-picker-container .cell,
  .vikinglotto-lucky-game .number-picker-container .cell,
  .vikinglotto-winning-numbers .number-picker-container .cell {
    width: calc(16.666% - 16px);
    height: 5.5vw;
    margin: 8px;
  }
}
.vikinglotto-classic-game .number-picker-container .cell:after,
.vikinglotto-system-game .number-picker-container .cell:after,
.vikinglotto-lucky-game .number-picker-container .cell:after,
.vikinglotto-winning-numbers .number-picker-container .cell:after {
  content: '';
  position: absolute;
  top: -1vw;
  left: -1vw;
  right: -1vw;
  bottom: -1vw;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .cell:after,
  .vikinglotto-system-game .number-picker-container .cell:after,
  .vikinglotto-lucky-game .number-picker-container .cell:after,
  .vikinglotto-winning-numbers .number-picker-container .cell:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .cell:hover .back-face,
  .vikinglotto-system-game .number-picker-container .cell:hover .back-face,
  .vikinglotto-lucky-game .number-picker-container .cell:hover .back-face,
  .vikinglotto-winning-numbers .number-picker-container .cell:hover .back-face,
  .vikinglotto-classic-game .number-picker-container .cell:hover .front-face,
  .vikinglotto-system-game .number-picker-container .cell:hover .front-face,
  .vikinglotto-lucky-game .number-picker-container .cell:hover .front-face,
  .vikinglotto-winning-numbers .number-picker-container .cell:hover .front-face {
    transform: scale(1.26);
  }
}
.vikinglotto-classic-game .number-picker-container .cell .back-face,
.vikinglotto-system-game .number-picker-container .cell .back-face,
.vikinglotto-lucky-game .number-picker-container .cell .back-face,
.vikinglotto-winning-numbers .number-picker-container .cell .back-face,
.vikinglotto-classic-game .number-picker-container .cell .front-face,
.vikinglotto-system-game .number-picker-container .cell .front-face,
.vikinglotto-lucky-game .number-picker-container .cell .front-face,
.vikinglotto-winning-numbers .number-picker-container .cell .front-face {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  color: #1d1d1d;
  transition: transform .1s linear;
  will-change: transform;
}
.vikinglotto-classic-game .number-picker-container .cell .back-face .number,
.vikinglotto-system-game .number-picker-container .cell .back-face .number,
.vikinglotto-lucky-game .number-picker-container .cell .back-face .number,
.vikinglotto-winning-numbers .number-picker-container .cell .back-face .number,
.vikinglotto-classic-game .number-picker-container .cell .front-face .number,
.vikinglotto-system-game .number-picker-container .cell .front-face .number,
.vikinglotto-lucky-game .number-picker-container .cell .front-face .number,
.vikinglotto-winning-numbers .number-picker-container .cell .front-face .number {
  width: 8.4vw;
  height: 8.4vw;
  position: absolute;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .cell .back-face .number,
  .vikinglotto-system-game .number-picker-container .cell .back-face .number,
  .vikinglotto-lucky-game .number-picker-container .cell .back-face .number,
  .vikinglotto-winning-numbers .number-picker-container .cell .back-face .number,
  .vikinglotto-classic-game .number-picker-container .cell .front-face .number,
  .vikinglotto-system-game .number-picker-container .cell .front-face .number,
  .vikinglotto-lucky-game .number-picker-container .cell .front-face .number,
  .vikinglotto-winning-numbers .number-picker-container .cell .front-face .number {
    width: 4.6rem;
    height: 4.6rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .cell .back-face .number,
  .vikinglotto-system-game .number-picker-container .cell .back-face .number,
  .vikinglotto-lucky-game .number-picker-container .cell .back-face .number,
  .vikinglotto-winning-numbers .number-picker-container .cell .back-face .number,
  .vikinglotto-classic-game .number-picker-container .cell .front-face .number,
  .vikinglotto-system-game .number-picker-container .cell .front-face .number,
  .vikinglotto-lucky-game .number-picker-container .cell .front-face .number,
  .vikinglotto-winning-numbers .number-picker-container .cell .front-face .number {
    width: 100%;
    height: 100%;
  }
}
.vikinglotto-classic-game .number-picker-container .cell .front-face,
.vikinglotto-system-game .number-picker-container .cell .front-face,
.vikinglotto-lucky-game .number-picker-container .cell .front-face,
.vikinglotto-winning-numbers .number-picker-container .cell .front-face {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.vikinglotto-classic-game .number-picker-container .cell .back-face,
.vikinglotto-system-game .number-picker-container .cell .back-face,
.vikinglotto-lucky-game .number-picker-container .cell .back-face,
.vikinglotto-winning-numbers .number-picker-container .cell .back-face {
  width: 0;
  height: 0;
  background-color: #feb700;
}
.vikinglotto-classic-game .number-picker-container .cell .number,
.vikinglotto-system-game .number-picker-container .cell .number,
.vikinglotto-lucky-game .number-picker-container .cell .number,
.vikinglotto-winning-numbers .number-picker-container .cell .number {
  width: 8.4vw;
  height: 8.4vw;
  font-size: 4vw;
  line-height: 8.4vw;
  border-radius: 50%;
  background-color: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  -webkit-font-smoothing: antialiased;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .cell .number,
  .vikinglotto-system-game .number-picker-container .cell .number,
  .vikinglotto-lucky-game .number-picker-container .cell .number,
  .vikinglotto-winning-numbers .number-picker-container .cell .number {
    width: 4.6rem;
    height: 4.6rem;
    font-size: 1.8rem;
    line-height: 4.6rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .cell .number,
  .vikinglotto-system-game .number-picker-container .cell .number,
  .vikinglotto-lucky-game .number-picker-container .cell .number,
  .vikinglotto-winning-numbers .number-picker-container .cell .number {
    font-size: 2.5vw;
    line-height: 5.5vw;
  }
}
.vikinglotto-classic-game .number-picker-container .cell.flip-animation-in-progress,
.vikinglotto-system-game .number-picker-container .cell.flip-animation-in-progress,
.vikinglotto-lucky-game .number-picker-container .cell.flip-animation-in-progress,
.vikinglotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress {
  transform: translateZ(0);
}
@keyframes vikinglotto-select-face-animation {
  0% {
    width: 100%;
    height: 100%;
  }
  99.999% {
    width: 100%;
    height: 100%;
  }
  100% {
    width: 0;
    height: 0;
  }
}
@keyframes vikinglotto-select-back-animation {
  0% {
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    margin: 0;
    z-index: 1;
  }
  60% {
    width: 100%;
    height: 100%;
    margin: -50%;
  }
  70% {
    width: 140%;
    height: 140%;
    margin: -70%;
  }
  100% {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    margin: -50%;
  }
}
@keyframes vikinglotto-select-back-number-animation {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  70% {
    transform: translate(-50%, -50%) scale(0.769);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes vikinglotto-deselect-face-animation {
  0% {
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    margin: 0;
    z-index: 1;
  }
  100% {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    margin: -50%;
    z-index: 1;
  }
}
@keyframes vikinglotto-deselect-back-animation {
  0% {
    width: 100%;
    height: 100%;
  }
  99.999% {
    width: 100%;
    height: 100%;
  }
  100% {
    width: 0;
    height: 0;
  }
}
@media (min-width: 950px) {
  @keyframes vikinglotto-flip-face-animation {
    0% {
      color: #fff;
      background-color: #fff;
    }
    35% {
      color: #fff;
      background-color: #fff;
    }
    65% {
      color: #fff;
      background-color: #fff;
    }
    80% {
      color: #1d1d1d;
      background-color: #fff;
    }
    100% {
      color: #1d1d1d;
      background-color: #fff;
    }
  }
  @keyframes vikinglotto-reverse-flip-face-animation {
    0% {
      color: #1d1d1d;
      background-color: #fff;
    }
    35% {
      color: #1d1d1d;
      background-color: #fff;
    }
    65% {
      color: #1d1d1d;
      background-color: #fff;
    }
    80% {
      color: #fff;
      background-color: #fff;
    }
    100% {
      color: #fff;
      background-color: #fff;
    }
  }
}
.vikinglotto-classic-game .number-picker-container .cell.flip-animation-in-progress .front-face,
.vikinglotto-system-game .number-picker-container .cell.flip-animation-in-progress .front-face,
.vikinglotto-lucky-game .number-picker-container .cell.flip-animation-in-progress .front-face,
.vikinglotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress .front-face,
.vikinglotto-classic-game .number-picker-container .cell.flip-animation-in-progress .back-face,
.vikinglotto-system-game .number-picker-container .cell.flip-animation-in-progress .back-face,
.vikinglotto-lucky-game .number-picker-container .cell.flip-animation-in-progress .back-face,
.vikinglotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress .back-face {
  position: absolute;
}
.vikinglotto-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation,
.vikinglotto-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation,
.vikinglotto-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation,
.vikinglotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation {
  animation: vikinglotto-flip-face-animation 0.3s forwards linear;
}
.vikinglotto-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .front-face,
.vikinglotto-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .front-face,
.vikinglotto-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .front-face,
.vikinglotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation .front-face {
  animation: vikinglotto-select-face-animation 0.3s forwards linear;
}
.vikinglotto-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .back-face,
.vikinglotto-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .back-face,
.vikinglotto-lucky-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .back-face,
.vikinglotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation .back-face {
  animation: vikinglotto-select-back-animation 0.4s forwards linear;
}
.vikinglotto-classic-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation,
.vikinglotto-system-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation,
.vikinglotto-lucky-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation,
.vikinglotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation {
  animation: vikinglotto-reverse-flip-face-animation 0.3s forwards linear;
}
.vikinglotto-classic-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .front-face,
.vikinglotto-system-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .front-face,
.vikinglotto-lucky-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .front-face,
.vikinglotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .front-face {
  animation: vikinglotto-deselect-face-animation 0.3s forwards linear;
}
.vikinglotto-classic-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .back-face,
.vikinglotto-system-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .back-face,
.vikinglotto-lucky-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .back-face,
.vikinglotto-winning-numbers .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .back-face {
  animation: vikinglotto-deselect-back-animation 0.3s forwards linear;
}
.vikinglotto-classic-game .number-picker-container .cell.active .number,
.vikinglotto-system-game .number-picker-container .cell.active .number,
.vikinglotto-lucky-game .number-picker-container .cell.active .number,
.vikinglotto-winning-numbers .number-picker-container .cell.active .number {
  line-height: 7.428vw;
  height: 130%;
  -webkit-transform: translateY(-100%) scale(1.3);
  -moz-transform: translateY(-100%) scale(1.3);
  -ms-transform: translateY(-100%) scale(1.3);
  -o-transform: translateY(-100%) scale(1.3);
  transform: translateY(-100%) scale(1.3);
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .cell.active .number,
  .vikinglotto-system-game .number-picker-container .cell.active .number,
  .vikinglotto-lucky-game .number-picker-container .cell.active .number,
  .vikinglotto-winning-numbers .number-picker-container .cell.active .number {
    height: 100%;
    line-height: 2.1;
    -webkit-transform: translateY(0) scale(1);
    -moz-transform: translateY(0) scale(1);
    -ms-transform: translateY(0) scale(1);
    -o-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
}
.vikinglotto-classic-game .number-picker-container .cell .bubles-container,
.vikinglotto-system-game .number-picker-container .cell .bubles-container,
.vikinglotto-lucky-game .number-picker-container .cell .bubles-container,
.vikinglotto-winning-numbers .number-picker-container .cell .bubles-container {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: inherit;
  z-index: -1;
}
@keyframes vikinglotto-buble-1-1-animation {
  0% {
    width: 0;
    height: 0;
    transform: translate3D(0, 0, 0);
  }
  60% {
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3D(-3rem, -1.5rem, 0);
    opacity: 1;
  }
  100% {
    width: 0;
    height: 0;
    transform: translate3D(-4rem, -2.5rem, 0);
    opacity: 0;
  }
}
@keyframes vikinglotto-buble-1-2-animation {
  0% {
    width: 0;
    height: 0;
    transform: translate3D(0, 0, 0);
  }
  60% {
    width: 2.5rem;
    height: 2.5rem;
    transform: translate3D(1.4rem, 1rem, 0);
    opacity: 1;
  }
  100% {
    width: 0;
    height: 0;
    transform: translate3D(2.4rem, 2rem, 0);
    opacity: 0;
  }
}
@keyframes vikinglotto-buble-1-3-animation {
  0% {
    width: 0;
    height: 0;
    transform: translate3D(0, 0, 0);
  }
  60% {
    width: 2.2rem;
    height: 2.2rem;
    transform: translate3D(1.4rem, -3.9rem, 0);
    opacity: 1;
  }
  100% {
    width: 0;
    height: 0;
    transform: translate3D(2.6rem, -4.2rem, 0);
    opacity: 0;
  }
}
@keyframes vikinglotto-buble-1-4-animation {
  0% {
    width: 0;
    height: 0;
    transform: translate3D(0, 0, 0);
  }
  60% {
    width: 3rem;
    height: 3rem;
    transform: translate3D(-3.9rem, 1.2rem, 0);
    opacity: 1;
  }
  100% {
    width: 0;
    height: 0;
    transform: translate3D(-4.3rem, 1.8rem, 0);
    opacity: 0;
  }
}
@keyframes vikinglotto-buble-1-5-animation {
  0% {
    width: 0;
    height: 0;
    transform: translate3D(0, 0, 0);
  }
  60% {
    width: 1.8rem;
    height: 1.8rem;
    transform: translate3D(-2.1rem, -3.5rem, 0);
    opacity: 1;
  }
  100% {
    width: 0;
    height: 0;
    transform: translate3D(-2.3rem, -4rem, 0);
    opacity: 0;
  }
}
.vikinglotto-classic-game .number-picker-container .cell .bubles-container hr,
.vikinglotto-system-game .number-picker-container .cell .bubles-container hr,
.vikinglotto-lucky-game .number-picker-container .cell .bubles-container hr,
.vikinglotto-winning-numbers .number-picker-container .cell .bubles-container hr {
  background-color: #fff;
  border: none;
  transform: translate3d(0, 0, 0);
  border-radius: 50%;
  transition: transform .25s linear;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
}
.vikinglotto-classic-game .number-picker-container .cell .bubles-container hr:nth-of-type(1),
.vikinglotto-system-game .number-picker-container .cell .bubles-container hr:nth-of-type(1),
.vikinglotto-lucky-game .number-picker-container .cell .bubles-container hr:nth-of-type(1),
.vikinglotto-winning-numbers .number-picker-container .cell .bubles-container hr:nth-of-type(1) {
  animation: vikinglotto-buble-1-1-animation 0.3s forwards linear;
}
.vikinglotto-classic-game .number-picker-container .cell .bubles-container hr:nth-of-type(2),
.vikinglotto-system-game .number-picker-container .cell .bubles-container hr:nth-of-type(2),
.vikinglotto-lucky-game .number-picker-container .cell .bubles-container hr:nth-of-type(2),
.vikinglotto-winning-numbers .number-picker-container .cell .bubles-container hr:nth-of-type(2) {
  animation: vikinglotto-buble-1-2-animation 0.3s forwards linear;
}
.vikinglotto-classic-game .number-picker-container .cell .bubles-container hr:nth-of-type(3),
.vikinglotto-system-game .number-picker-container .cell .bubles-container hr:nth-of-type(3),
.vikinglotto-lucky-game .number-picker-container .cell .bubles-container hr:nth-of-type(3),
.vikinglotto-winning-numbers .number-picker-container .cell .bubles-container hr:nth-of-type(3) {
  animation: vikinglotto-buble-1-3-animation 0.3s forwards linear;
}
.vikinglotto-classic-game .number-picker-container .cell .bubles-container hr:nth-of-type(4),
.vikinglotto-system-game .number-picker-container .cell .bubles-container hr:nth-of-type(4),
.vikinglotto-lucky-game .number-picker-container .cell .bubles-container hr:nth-of-type(4),
.vikinglotto-winning-numbers .number-picker-container .cell .bubles-container hr:nth-of-type(4) {
  animation: vikinglotto-buble-1-4-animation 0.3s forwards linear;
}
.vikinglotto-classic-game .number-picker-container .cell .bubles-container hr:nth-of-type(5),
.vikinglotto-system-game .number-picker-container .cell .bubles-container hr:nth-of-type(5),
.vikinglotto-lucky-game .number-picker-container .cell .bubles-container hr:nth-of-type(5),
.vikinglotto-winning-numbers .number-picker-container .cell .bubles-container hr:nth-of-type(5) {
  animation: vikinglotto-buble-1-5-animation 0.3s forwards linear;
}
@keyframes vikinglotto-face-1-1-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
.vikinglotto-classic-game .number-picker-container .cell .bubles-container span,
.vikinglotto-system-game .number-picker-container .cell .bubles-container span,
.vikinglotto-lucky-game .number-picker-container .cell .bubles-container span,
.vikinglotto-winning-numbers .number-picker-container .cell .bubles-container span {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: inherit;
}
.vikinglotto-classic-game .number-picker-container .cell .bubles-container span:nth-of-type(1),
.vikinglotto-system-game .number-picker-container .cell .bubles-container span:nth-of-type(1),
.vikinglotto-lucky-game .number-picker-container .cell .bubles-container span:nth-of-type(1),
.vikinglotto-winning-numbers .number-picker-container .cell .bubles-container span:nth-of-type(1) {
  animation: vikinglotto-face-1-1-animation 0.3s forwards linear;
}
.vikinglotto-classic-game .number-picker-container .cell.selected .front-face,
.vikinglotto-system-game .number-picker-container .cell.selected .front-face,
.vikinglotto-lucky-game .number-picker-container .cell.selected .front-face,
.vikinglotto-winning-numbers .number-picker-container .cell.selected .front-face {
  width: 0;
  height: 0;
}
.vikinglotto-classic-game .number-picker-container .cell.selected .back-face,
.vikinglotto-system-game .number-picker-container .cell.selected .back-face,
.vikinglotto-lucky-game .number-picker-container .cell.selected .back-face,
.vikinglotto-winning-numbers .number-picker-container .cell.selected .back-face {
  width: 100%;
  height: 100%;
}
.vikinglotto-classic-game .number-picker-container .cell.selected .bubles-container hr,
.vikinglotto-system-game .number-picker-container .cell.selected .bubles-container hr,
.vikinglotto-lucky-game .number-picker-container .cell.selected .bubles-container hr,
.vikinglotto-winning-numbers .number-picker-container .cell.selected .bubles-container hr {
  background-color: #fff;
}
.vikinglotto-classic-game .number-picker-container .numbers-vikings-separator,
.vikinglotto-system-game .number-picker-container .numbers-vikings-separator,
.vikinglotto-lucky-game .number-picker-container .numbers-vikings-separator,
.vikinglotto-winning-numbers .number-picker-container .numbers-vikings-separator {
  width: 1px;
  height: calc(100% - 5.412vw);
  position: absolute;
  top: 1.356vw;
  right: 10.2vw;
  background-color: #fff;
  border: none;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .numbers-vikings-separator,
  .vikinglotto-system-game .number-picker-container .numbers-vikings-separator,
  .vikinglotto-lucky-game .number-picker-container .numbers-vikings-separator,
  .vikinglotto-winning-numbers .number-picker-container .numbers-vikings-separator {
    height: calc(100% - 4rem);
    top: 1rem;
    right: 12.5rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .numbers-vikings-separator,
  .vikinglotto-system-game .number-picker-container .numbers-vikings-separator,
  .vikinglotto-lucky-game .number-picker-container .numbers-vikings-separator,
  .vikinglotto-winning-numbers .number-picker-container .numbers-vikings-separator {
    right: 13.5%;
  }
}
.vikinglotto-classic-game .number-picker-container .row-vikings-container,
.vikinglotto-system-game .number-picker-container .row-vikings-container,
.vikinglotto-lucky-game .number-picker-container .row-vikings-container,
.vikinglotto-winning-numbers .number-picker-container .row-vikings-container {
  width: 11.338vw;
  text-align: center;
  position: absolute;
  right: -0.7vw;
  top: -0.1vw;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .row-vikings-container,
  .vikinglotto-system-game .number-picker-container .row-vikings-container,
  .vikinglotto-lucky-game .number-picker-container .row-vikings-container,
  .vikinglotto-winning-numbers .number-picker-container .row-vikings-container {
    width: 10.5rem;
    right: -0.3rem;
    top: .9rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .row-vikings-container,
  .vikinglotto-system-game .number-picker-container .row-vikings-container,
  .vikinglotto-lucky-game .number-picker-container .row-vikings-container,
  .vikinglotto-winning-numbers .number-picker-container .row-vikings-container {
    width: 15%;
  }
}
.vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box,
.vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box,
.vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box,
.vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box {
  width: 100%;
  height: 11.05vw;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  float: left;
  position: relative;
  transition: transform .1s linear;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box:hover,
  .vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box:hover,
  .vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box:hover,
  .vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box:hover {
    transform: scale(1.26);
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box,
  .vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box,
  .vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box,
  .vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box {
    width: 50%;
    height: 6.65rem;
    top: -0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box,
  .vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box,
  .vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box,
  .vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box {
    height: 7.5vw;
    padding: 8px;
  }
}
.vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box .number,
.vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box .number,
.vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box .number,
.vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box .number {
  width: 100%;
  font-size: 3.8vw;
  line-height: 2.95;
  display: block;
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box .number,
  .vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box .number,
  .vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box .number,
  .vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box .number {
    font-size: 1.6rem;
    line-height: 2.9;
    left: 0;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box .number,
  .vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box .number,
  .vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box .number,
  .vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box .number {
    font-size: 2.5vw;
    line-height: 3.4;
  }
}
.vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box .icon,
.vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box .icon,
.vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box .icon,
.vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box .icon {
  width: 6.8vw;
  height: 9.26vw;
  margin: auto;
  position: absolute;
  top: 1.1vw;
  left: 0;
  right: 0;
  transition: opacity .25s linear;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box .icon,
  .vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box .icon,
  .vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box .icon,
  .vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box .icon {
    width: 4.2rem;
    height: 5.5rem;
    top: 0;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box .icon,
  .vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box .icon,
  .vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box .icon,
  .vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box .icon {
    width: 5.5vw;
    height: 6.3vw;
  }
}
.vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box .icon.yellow,
.vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box .icon.yellow,
.vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box .icon.yellow,
.vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box .icon.yellow {
  opacity: 0;
}
.vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box.selected .number,
.vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box.selected .number,
.vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box.selected .number,
.vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box.selected .number {
  color: #feb700;
}
.vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box.selected .icon.white,
.vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box.selected .icon.white,
.vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box.selected .icon.white,
.vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box.selected .icon.white {
  opacity: 0;
}
.vikinglotto-classic-game .number-picker-container .row-vikings-container .viking-box.selected .icon.yellow,
.vikinglotto-system-game .number-picker-container .row-vikings-container .viking-box.selected .icon.yellow,
.vikinglotto-lucky-game .number-picker-container .row-vikings-container .viking-box.selected .icon.yellow,
.vikinglotto-winning-numbers .number-picker-container .row-vikings-container .viking-box.selected .icon.yellow {
  opacity: 1;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .vikinglotto-content-wrapper--version-2 .number-picker-container .cells-container,
  .vikinglotto-lucky-game .vikinglotto-content-wrapper--version-2 .number-picker-container .cells-container,
  .vikinglotto-system-game .vikinglotto-content-wrapper--version-2 .number-picker-container .cells-container {
    width: 86.2rem;
  }
  .vikinglotto-classic-game .vikinglotto-content-wrapper--version-2 .number-picker-container .cell,
  .vikinglotto-lucky-game .vikinglotto-content-wrapper--version-2 .number-picker-container .cell,
  .vikinglotto-system-game .vikinglotto-content-wrapper--version-2 .number-picker-container .cell {
    margin: 1.5rem 1.2rem;
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .vikinglotto-content-wrapper--version-2 .number-picker-container .row-vikings-container,
  .vikinglotto-lucky-game .vikinglotto-content-wrapper--version-2 .number-picker-container .row-vikings-container,
  .vikinglotto-system-game .vikinglotto-content-wrapper--version-2 .number-picker-container .row-vikings-container {
    width: 5.5rem;
  }
  .vikinglotto-classic-game .vikinglotto-content-wrapper--version-2 .number-picker-container .row-vikings-container .viking-box,
  .vikinglotto-lucky-game .vikinglotto-content-wrapper--version-2 .number-picker-container .row-vikings-container .viking-box,
  .vikinglotto-system-game .vikinglotto-content-wrapper--version-2 .number-picker-container .row-vikings-container .viking-box {
    float: none;
    width: auto;
    height: 5.8rem;
    top: auto;
  }
  .vikinglotto-classic-game .vikinglotto-content-wrapper--version-2 .number-picker-container .row-vikings-container .viking-box .icon,
  .vikinglotto-lucky-game .vikinglotto-content-wrapper--version-2 .number-picker-container .row-vikings-container .viking-box .icon,
  .vikinglotto-system-game .vikinglotto-content-wrapper--version-2 .number-picker-container .row-vikings-container .viking-box .icon {
    height: 4.8rem;
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .vikinglotto-content-wrapper--version-2 .numbers-vikings-separator,
  .vikinglotto-lucky-game .vikinglotto-content-wrapper--version-2 .numbers-vikings-separator,
  .vikinglotto-system-game .vikinglotto-content-wrapper--version-2 .numbers-vikings-separator {
    right: 7.5rem;
  }
}
