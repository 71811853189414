@import url("../Mixins/_mixins.less");

.vikinglotto-top-spot {
  @hover-fade: 0.3s;

  .btn {
    margin-top: 1rem;
  }

  h1.hd.xxlarge {
    min-height: 1em;
    min-width: 1em;
  }

  h3.hd.large {
    min-height: 1em;
    min-width: 1em;
    margin-top: 11vw;

    @media (min-width: @vikinglotto-desktop-width) {
      margin-top: 6rem;
    }
  }

  small.faq {
    padding-top: 2vw;

    @media (min-width: @vikinglotto-desktop-width) {
      padding-top: 2rem;
    }
  }

  .vikinglotto__curved-bg {
    margin-bottom: -6.8rem;

    &--mosaik {
      z-index: 1;
    }

    @media (min-width: @vikinglotto-desktop-width) {
      margin-bottom: -4.8rem;
    }
  }

  &--dark-blue {
    .vikinglotto__curved-bg {
      background-color: @vikinglotto-mosaik-darkblue;
    }

    .vikinglotto-content-wrapper {
      color: @vikinglotto-mosaik-babyblue;
    }

    @media (min-width: @vikinglotto-desktop-width) {
      .vikinglotto-section .vikinglotto-color-toggle .vikinglotto-smile {
        fill: @vikinglotto-mosaik-babyblue;
      }
    }
  }

  &--babyblue {
    .vikinglotto__curved-bg {
      background-color: @vikinglotto-mosaik-babyblue;
    }

    .vikinglotto-content-wrapper {
      color: @vikinglotto-mosaik-darkblue;
    }

    @media (min-width: @vikinglotto-desktop-width) {

      .vikinglotto-section .vikinglotto-color-toggle .vikinglotto-smile {
        fill: @vikinglotto-mosaik-darkblue;
        transition-duration: @hover-fade;
        transition-property: fill;
      }

      .vikinglotto-section .vikinglotto-color-toggle:hover .vikinglotto-smile {
        fill: @vikinglotto-mosaik-babyblue;
      }
    }
  }

  &--pink {
    .vikinglotto__curved-bg {
      background-color: @vikinglotto-mosaik-pink;
    }

    .vikinglotto-content-wrapper {
      color: @vikinglotto-mosaik-purple;
    }

    @media (min-width: @vikinglotto-desktop-width) {
      .vikinglotto-section .vikinglotto-color-toggle .vikinglotto-smile {
        fill: @vikinglotto-mosaik-purple;
        transition-duration: @hover-fade;
        transition-property: fill;
      }

      .vikinglotto-section .vikinglotto-color-toggle:hover .vikinglotto-smile {
        fill: @vikinglotto-mosaik-babyblue;
      }
    }
  }

  &--purple {
    .vikinglotto__curved-bg {
      background-color: @vikinglotto-mosaik-purple;
    }

    .vikinglotto-content-wrapper {
      color: @vikinglotto-mosaik-pink;
    }

    @media (min-width: @vikinglotto-desktop-width) {

      .vikinglotto-section .vikinglotto-color-toggle .vikinglotto-smile {
        fill: @vikinglotto-mosaik-pink;
        transition-duration: @hover-fade;
        transition-property: fill;
      }

      .vikinglotto-section .vikinglotto-color-toggle:hover .vikinglotto-smile {
        fill: @vikinglotto-mosaik-babyblue;
      }
    }
  }

  &--yellow {
    .vikinglotto__curved-bg {
      background-color: @vikinglotto-mosaik-yellow;
    }

    .vikinglotto-content-wrapper {
      color: @vikinglotto-mosaik-pink;
    }

    @media (min-width: @vikinglotto-desktop-width) {
      .vikinglotto-section .vikinglotto-color-toggle .vikinglotto-smile {
        fill: @vikinglotto-mosaik-pink;
        transition-duration: @hover-fade;
        transition-property: fill;
      }

      .vikinglotto-section .vikinglotto-color-toggle:hover .vikinglotto-smile {
        fill: @vikinglotto-mosaik-babyblue;
      }
    }
  }

  .vikinglotto-section {
    padding: 0;
    position: relative;
    box-sizing: border-box;
    min-height: 66vw;
    background-color: transparent;

    .vikinglotto-color-toggle {
      width: 10.5rem;
      height: 10.5rem;
      display: inline;
      position: absolute;
      z-index: 10;
      left: 50%;
      transform: translateX(-50%);
      bottom: -10rem;

      @media (min-width: @vikinglotto-desktop-width) {
        bottom: -8rem;
      }

      .vikinglotto-color-toggle-background {
        width: 11.5rem;
        height: 11.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: @vikinglotto-mosaik-darkblue;
        cursor: pointer;

        &:after {
          content: "";
          position: absolute;
          width: 13rem;
          height: 13rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          box-shadow: 0 0 1px rgba(0, 0, 0, 0);
          transition-duration: @hover-fade;
          transition-property: box-shadow background-color;
        }

        &:hover:after {
          box-shadow: 0 0 16px rgba(0, 0, 0, 0.6);

          @media (min-width: @vikinglotto-desktop-width) {
            background-color: @vikinglotto-mosaik-darkblue;
          }
        }

        @media (min-width: @vikinglotto-desktop-width) {
          background-color: transparent;
        }
      }

      @media (min-width: @vikinglotto-desktop-width) {
        width: 11.5rem;
        height: 11.5rem;
        left: 85%;
        bottom: 7.5rem;
      }
    }

    .vikinglotto-smile {
      pointer-events: none;
      position: absolute;
      bottom: 0;
      fill: @vikinglotto-white;

      &.vikinglotto-smile--spin {
        animation: spin 20s linear infinite;
        transform-origin: center;
        font-size: 12px;
        top: 0.1rem;

        @media (min-width: @vikinglotto-desktop-width) {
          width: 12rem;
          height: 12rem;
          top: -0.3rem;
          left: -0.1rem;
        }
      }
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(-360deg);
      }
    }

    @media (min-width: @vikinglotto-desktop-width) {
      min-height: 25rem;
    }

    .vikinglotto-content-wrapper {
      padding: 3vw 0;
      // Position all other elements above background and video
      > * {
        position: relative;
        z-index: 2;
      }

      .vikinglotto__flipclock-cta-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;

        .btn {
          margin: 2rem 0;
        }
      }
    }

    #vikinglotto-winning-number-subheader {
      text-transform: none;
      margin: 0 auto 14vw;
      font-weight: 100;
      font-size: 4vw;
      width: 60vw;

      @media (min-width: @vikinglotto-desktop-width) {
        /*font-size: 16vw;
margin: 4rem auto;*/
        margin: 0 auto 6rem;
        font-size: 3rem;
      }
    }

    &.faq {
      font-size: 12.9vw;
      line-height: 12.9vw;
      margin: 3vw auto 0;

      @media (min-width: @vikinglotto-desktop-width) {
        font-size: 7rem;
        margin: 8rem auto 0;
        line-height: 7rem;
      }
    }
  }

  .help-link {
    // changes to the underline of the hyperlink
    text-decoration: none;
    border-bottom: .1rem solid @vikinglotto-white;
    font-size: 3.7vw;
    line-height: 4.2vw;
    margin: 2vw 0;
    display: inline-block;

    &:hover {
      border-color: transparent;
    }

    @media (min-width: @vikinglotto-desktop-width) {
      line-height: 1.5rem;
      margin-bottom: 1.5rem;
      margin-top: 4.5rem;
      font-size: 1.6rem;
    }
  }

  .vikinglotto-logo-wrapper {

    @media (min-width: @vikinglotto-desktop-width) {
      display: block;
      margin-bottom: -3rem; //was before 3rem
    }
  }

  // placeholder until image arrives
  .vikinglotto-logo {
    width: 33vw;
    margin: 0 auto 4vw;

    @media (min-width: @vikinglotto-desktop-width) {
      width: 12.6rem;
      margin: 0 auto 1rem;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &.faq-top-spot {
    .vikinglotto-section {
      .vikinglotto-content-wrapper {
        padding: 9vw 0 5vw;

        @media (min-width: @vikinglotto-desktop-width) {
          padding: 8rem 0 4rem;
        }

        .vikinglotto-logo-wrapper {
          .vikinglotto-logo {
            width: 17.3vw;

            @media (min-width: @vikinglotto-desktop-width) {
              width: 6.5rem;
            }
          }
        }

        .hd.faq {
          font-size: 9.3vw;
          margin: -4vw auto 5vw;
          line-height: 1;
          font-weight: 900;
          font-style: italic;

          @media (min-width: @vikinglotto-desktop-width) {
            font-size: 5rem;
            margin: 3.7rem auto 7.1rem;
          }

          .faq {
            font-size: 4.6vw;
            margin-top: -.5vw;

            @media (min-width: @vikinglotto-desktop-width) {
              font-size: 2.6rem;
              font-weight: 900;
              margin-top: -.9rem;
            }
          }
        }

        #vikinglotto-winning-number-subheader {
          margin: 0 auto -1.5vw;
        }
      }
    }

    & + .informationswitcherspotview {
      padding: 8vw 0 0;
      border-color: @vikinglotto-white;

      @media (min-width: @vikinglotto-desktop-width) {
        padding: 11rem 0;
      }

      .informationswitcher-selector {
        margin-top: 9vw;

        @media (min-width: @vikinglotto-desktop-width) {
          margin-top: 0;
        }
      }
    }
  }

  .vikinglotto-mosaik {

    .vikinglotto-mosaik__tiles {
      display: flex;
    }

    .vikinglotto-mosaik__tile {
      position: relative;
      height: 45vw;
      flex-grow: 1;
      display: none;

      &#vikinglotto-mosaik-tile-4 {
        height: 66vw;

        @media (min-width: @vikinglotto-desktop-width) {
          height: inherit;
        }

        & .vikinglotto-mosaik__tile-graphics {
          top: unset;
          bottom: 32%;

          @media (min-width: @vikinglotto-desktop-width) {
            top: 55%;
            bottom: unset;
          }
        }
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      @media (min-width: @vikinglotto-desktop-width) {
        &::after {
          content: none;
        }
      }

      .vikinglotto-mosaik__tile-graphics {
        transition: opacity 750ms;
        transition: opacity var(--fade-timer);
        opacity: 0;
        position: absolute;
        top: 55%;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);
        display: none;

        &--mobile {
          display: block;
        }

        @media (min-width: @vikinglotto-desktop-width) {
          display: block;
        }

        &--show {
          opacity: 1;
        }
      }

      .vikinglotto-mosaik__tile-svg, .vikinglotto-mosaik__tile-image {
        width: 70%;
      }

      .vikinglotto-mosaik__tile-static-spot {
        max-width: 90%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
          cursor: pointer;
        }
      }


      &--mobile {
        display: block;
      }

      @media (min-width: @vikinglotto-desktop-width) {
        display: block;
        height: 14.285vw; // 7 tiles / 100vw = 14.285vw
      }

      &--grow2 {
        flex-grow: 2;
      }

      &--dark-blue {
        background-color: @vikinglotto-mosaik-darkblue;

        .vikinglotto-mosaik__tile-svg {
          fill: @vikinglotto-mosaik-babyblue;
        }
      }

      &--pink {
        background-color: @vikinglotto-mosaik-pink;

        .vikinglotto-mosaik__tile-svg {
          fill: @vikinglotto-mosaik-purple;
        }
      }

      &--yellow {
        background-color: @vikinglotto-mosaik-yellow;

        .vikinglotto-mosaik__tile-svg {
          fill: @vikinglotto-mosaik-pink;
        }

        &.vikinglotto-mosaik__tile::after {
          background: url("/Components/DanskeSpil/Domain/VikingLotto/Graphics/mosaik-smile_face.png") no-repeat center;
          background-size: 50%;
        }
      }

      &--purple {
        background-color: @vikinglotto-mosaik-purple;

        .vikinglotto-mosaik__tile-svg {
          fill: @vikinglotto-mosaik-pink;
        }

        &.vikinglotto-mosaik__tile::after {
          background: url("/Components/DanskeSpil/Domain/VikingLotto/Graphics/mosaik-vk-spot.png") no-repeat center;
          background-size: 80%;
        }
      }

      &--babyblue {
        background-color: @vikinglotto-mosaik-babyblue;

        .vikinglotto-mosaik__tile-svg {
          fill: @vikinglotto-mosaik-darkblue;
        }
      }
    }
  }
}
