@import (reference) "Mixins/_mixins.less";

.select-dropdown {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  z-index: 10;
  width: 100%;
  -webkit-touch-callout: none;  // disable iOS callout
  user-select: none;
  font-size: 1.5em;
  font-weight: 700;
  color: @vikinglotto-mediumgray;

  @media (min-width: @vikinglotto-desktop-width) {
    max-width: 50rem;
  }

  &:after {
    content: '';
    height: 1rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50%;
    z-index: -1;
    transition: bottom .05s .2s, background-color .05s;
  }

  select {
    display: none;
  }

  .cs-placeholder {
    background-color: @vikinglotto-white;
    border-radius: 6px;
    transition: background-color .15s;
    border: 1px solid @vikinglotto-lightgray;

    .select-dropdown-arrow {
      width: 4vw;
      height: 2.5vw;
      fill: @vikinglotto-white;
      position: absolute;
      top: 6.8vw;
      right: 4vw;
      transition: transform .15s linear;

      @media (min-width: @vikinglotto-desktop-width) {
        width: 1.3rem;
        height: .8rem;
        top: 2.5rem;
        right: 2.4rem;
      }
    }
  }

  span {
    display: block;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5.4vw 0;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 16vw;

    @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
      padding: 1.5rem 2.5rem;
      line-height: 5rem;
    }

    @media (min-width: @vikinglotto-desktop-width) {
      padding: .6rem 2.5rem .2rem;
      line-height: 5rem;
    }
  }

  .select-options {
    max-height: 0;
    background-color: @vikinglotto-white;
    pointer-events: none;
    position: absolute;
    overflow: hidden;
    width: 100%;
    border-radius: 0 0 6px 6px;
    transition: max-height .25s;
    box-sizing: border-box;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        border-bottom: .1rem solid @vikinglotto-lightgray;

        &:hover {
          background-color: @vikinglotto-whitesmoke;
        }
      }
    }
  }

  &.reversed {
    &:after {
      top: 50%;
      transition: top .05s .2s;
    }

    .select-options {
      top: auto;
      bottom: 15vw;
      border-radius: 6px 6px 0 0;

      @media (min-width: @vikinglotto-desktop-width) {
        bottom: 5.9rem;
      }

      ul {
        transform: rotateX(-180deg);
        will-change: transform;

        li {
          transform: scaleY(-1);
          will-change: transform;
        }
      }
    }
  }

  // when active!
  &.is-active {
    &:after {
      bottom: -.4rem;
      transition: bottom .0s .0s, background-color 0s 0s;
    }

    .cs-placeholder {
      background-color: @vikinglotto-whitesmoke;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .select-options {
      top: 16vw;
      pointer-events: auto;
      border: 0.1rem solid @vikinglotto-lightgray;

      @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
        top: 8rem;
      }

      @media (min-width: @vikinglotto-desktop-width) {
        top: 5.9rem;
      }

      &.overflowed {
        overflow-y: auto;
      }
    }

    &.reversed {
      &:after {
        bottom: inherit;
        top: -.4rem;
        transition: top .0s .0s;
      }

      .cs-placeholder {
        border-bottom-width: .1rem;
        border-bottom-style: solid;
        border-radius: 0 0 6px 6px;
        border-top: 0;

        .select-dropdown-arrow {
          transform: rotate(180deg);
        }
      }

      .select-options {
        top: auto;
      }
    }
  }
}
