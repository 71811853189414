.vikinglotto-system-game-header {
  background-color: #000078;
  overflow: hidden;
}
@media (min-width: 950px) {
  .vikinglotto-system-game-header {
    height: 7rem;
  }
}
.vikinglotto-system-game-header .game-navigation-wrapper {
  width: 100%;
  display: table;
  overflow: hidden;
  table-layout: fixed;
}
.vikinglotto-system-game-header .game-navigation-wrapper .game-navigation-item {
  color: #fff;
  text-align: center;
  font-size: 3.733vw;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5.45vw 0;
  display: table-cell;
  cursor: pointer;
}
@media (min-width: 950px) {
  .vikinglotto-system-game-header .game-navigation-wrapper .game-navigation-item {
    font-size: 1.6rem;
    padding: 2.4rem 0;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-system-game-header .game-navigation-wrapper .game-navigation-item {
    font-size: 2.5vw;
    padding: 2.5vw 0;
  }
}
.vikinglotto-system-game-header .game-navigation-wrapper .game-navigation-item.active {
  color: #fff;
  background-color: #0000ff;
}
.vikinglotto-system-game-header .game-switch-wrapper {
  background-color: #0000ff;
  position: relative;
}
.vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper {
  white-space: nowrap;
  user-select: none;
  overflow: hidden;
  overflow-x: auto;
}
.vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper {
  display: inline-block;
  padding-right: 6vw;
}
@media (min-width: 950px) {
  .vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper {
    padding-right: 0;
  }
}
.vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-title,
.vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item {
  color: #fff;
  font-size: 3.733vw;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  padding: 5.4vw 0;
  margin: 0 0 0 5vw;
}
@media (min-width: 950px) {
  .vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-title,
  .vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item {
    font-size: 1.6rem;
    padding: 2.4rem 0;
    margin: 0 0 0 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-title,
  .vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item {
    font-size: 2.5vw;
    padding: 2.5vw 0;
  }
}
.vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-title:last-child,
.vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item:last-child {
  margin: 0 0 0 6vw;
}
@media (min-width: 950px) {
  .vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-title:last-child,
  .vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item:last-child {
    margin: 0 9.5rem 0 2.5rem;
  }
}
.vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-title {
  cursor: default;
}
.vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item {
  cursor: pointer;
  opacity: .6;
}
.vikinglotto-system-game-header .game-switch-wrapper .switch-inner-wrapper .switch-items-wrapper .switch-item.active {
  color: #fff;
  opacity: 1;
}
.vikinglotto-system-game-header .game-switch-wrapper .switch-button {
  width: 11vw;
  height: 100%;
  position: absolute;
  top: 0;
  transition: transform .2s linear;
  z-index: 1;
  cursor: pointer;
  will-change: transform;
}
@media (min-width: 950px) {
  .vikinglotto-system-game-header .game-switch-wrapper .switch-button {
    width: 11rem;
    height: 7rem;
  }
}
.vikinglotto-system-game-header .game-switch-wrapper .switch-button.next {
  right: 0;
  background: -moz-linear-gradient(left, rgba(0, 0, 255, 0) 0%, #0000ff 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 255, 0) 0%, #0000ff 100%);
  background: linear-gradient(to right, rgba(0, 0, 255, 0) 0%, #0000ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000ff', endColorstr='#0000ff', GradientType=1);
  transform: translate3d(100%, 0, 0);
}
.vikinglotto-system-game-header .game-switch-wrapper .switch-button.next:hover .icon {
  transform: translate3d(0, -50%, 0);
}
.vikinglotto-system-game-header .game-switch-wrapper .switch-button.prev {
  left: 0;
  background: -moz-linear-gradient(left, #0000ff 0%, rgba(0, 0, 255, 0) 100%);
  background: -webkit-linear-gradient(left, #0000ff 0%, rgba(0, 0, 255, 0) 100%);
  background: linear-gradient(to right, #0000ff 0%, rgba(0, 0, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0000ff', endColorstr='#000000ff', GradientType=1);
  transform: translate3d(-100%, 0, 0);
}
.vikinglotto-system-game-header .game-switch-wrapper .switch-button.prev:hover .icon {
  transform: translate3d(-100%, -50%, 0);
}
.vikinglotto-system-game-header .game-switch-wrapper .switch-button.active {
  transform: translate3d(0, 0, 0);
}
.vikinglotto-system-game-header .game-switch-wrapper .switch-button .icon {
  width: 2.133vw;
  height: 3.466vw;
  fill: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: transform .15s linear;
}
@media (min-width: 950px) {
  .vikinglotto-system-game-header .game-switch-wrapper .switch-button .icon {
    width: 2rem;
    height: 3rem;
  }
}
.vikinglotto-system-game-header.overflown-left .game-switch-wrapper .switch-button.prev {
  transform: translate3d(0, 0, 0);
}
.vikinglotto-system-game-header.overflown-right .game-switch-wrapper .switch-button.next {
  transform: translate3d(0, 0, 0);
}
