.select-dropdown {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  z-index: 10;
  width: 100%;
  -webkit-touch-callout: none;
  user-select: none;
  font-size: 1.5em;
  font-weight: 700;
  color: #dadbdd;
}
@media (min-width: 950px) {
  .select-dropdown {
    max-width: 50rem;
  }
}
.select-dropdown:after {
  content: '';
  height: 1rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50%;
  z-index: -1;
  transition: bottom 0.05s 0.2s, background-color 0.05s;
}
.select-dropdown select {
  display: none;
}
.select-dropdown .cs-placeholder {
  background-color: #fff;
  border-radius: 6px;
  transition: background-color 0.15s;
  border: 1px solid #4f5254;
}
.select-dropdown .cs-placeholder .select-dropdown-arrow {
  width: 4vw;
  height: 2.5vw;
  fill: #fff;
  position: absolute;
  top: 6.8vw;
  right: 4vw;
  transition: transform .15s linear;
}
@media (min-width: 950px) {
  .select-dropdown .cs-placeholder .select-dropdown-arrow {
    width: 1.3rem;
    height: .8rem;
    top: 2.5rem;
    right: 2.4rem;
  }
}
.select-dropdown span {
  display: block;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5.4vw 0;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 16vw;
}
@media (min-width: 768px) and (max-width: 950px) {
  .select-dropdown span {
    padding: 1.5rem 2.5rem;
    line-height: 5rem;
  }
}
@media (min-width: 950px) {
  .select-dropdown span {
    padding: .6rem 2.5rem .2rem;
    line-height: 5rem;
  }
}
.select-dropdown .select-options {
  max-height: 0;
  background-color: #fff;
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  width: 100%;
  border-radius: 0 0 6px 6px;
  transition: max-height 0.25s;
  box-sizing: border-box;
}
.select-dropdown .select-options ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.select-dropdown .select-options ul li {
  border-bottom: 0.1rem solid #4f5254;
}
.select-dropdown .select-options ul li:hover {
  background-color: #ededee;
}
.select-dropdown.reversed:after {
  top: 50%;
  transition: top .05s .2s;
}
.select-dropdown.reversed .select-options {
  top: auto;
  bottom: 15vw;
  border-radius: 6px 6px 0 0;
}
@media (min-width: 950px) {
  .select-dropdown.reversed .select-options {
    bottom: 5.9rem;
  }
}
.select-dropdown.reversed .select-options ul {
  transform: rotateX(-180deg);
  will-change: transform;
}
.select-dropdown.reversed .select-options ul li {
  transform: scaleY(-1);
  will-change: transform;
}
.select-dropdown.is-active:after {
  bottom: -0.4rem;
  transition: bottom 0s 0s, background-color 0s 0s;
}
.select-dropdown.is-active .cs-placeholder {
  background-color: #ededee;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select-dropdown.is-active .select-options {
  top: 16vw;
  pointer-events: auto;
  border: 0.1rem solid #4f5254;
}
@media (min-width: 768px) and (max-width: 950px) {
  .select-dropdown.is-active .select-options {
    top: 8rem;
  }
}
@media (min-width: 950px) {
  .select-dropdown.is-active .select-options {
    top: 5.9rem;
  }
}
.select-dropdown.is-active .select-options.overflowed {
  overflow-y: auto;
}
.select-dropdown.is-active.reversed:after {
  bottom: inherit;
  top: -0.4rem;
  transition: top .0s .0s;
}
.select-dropdown.is-active.reversed .cs-placeholder {
  border-bottom-width: .1rem;
  border-bottom-style: solid;
  border-radius: 0 0 6px 6px;
  border-top: 0;
}
.select-dropdown.is-active.reversed .cs-placeholder .select-dropdown-arrow {
  transform: rotate(180deg);
}
.select-dropdown.is-active.reversed .select-options {
  top: auto;
}
