﻿@import url("../Mixins/_mixins.less");

.vikinglotto-promotion-spot {

  .vikinglotto-section {
    position: relative;
    // Position all other elements above background and video
    > * {
      position: relative;
      z-index: 2;
    }
    // Background will be set with inline style
    //background-color: @vikinglotto-darkgreen;
    border-color: @vikinglotto-blue;
    //Curved arc at bottom
    .vikinglotto-image-wrapper {
      // Background will be inserted with inline style
      // background: linear-gradient(@vikinglotto-green 66%, transparent 66%, transparent 100%);
      margin-top: -15vw;
      padding-top: 15vw;

      @media (min-width: @vikinglotto-desktop-width) {
        margin-top: -10rem;
        padding-top: 10rem;
      }

      .vikinglotto-image {
        display: block;
        margin: auto;
        width: 75vw;
        height: auto;
        max-width: 100%;

        @media (min-width: @vikinglotto-desktop-width) {
          width: auto;
        }
      }
    }

    .btn {
      margin-top: 6.5vw;

      @media (min-width: @vikinglotto-desktop-width) {
        margin-top: 3rem;
      }
    }
  }
}
