@import url("../../Mixins/_mixins.less");

.region-vikinglotto {

  .informationswitcherspotview {
    padding: 10rem 0;

    .circlepromotionspot {
      .richtext {
        color: @numbergames-darkgray;
      }

      .circle-promotion-headline {
        max-width: 86.5vw;
        font-size: 9vw;
        font-weight: 800;
        line-height: 9vw;
        text-transform: uppercase;
        color: @vikinglotto-darkblue;
        padding-bottom: 2.666vw;
        margin: 1vw auto;

        @media(min-width:@desktop-width) {
          font-size: 3.8rem;
          line-height: 3rem;
          padding-bottom: 3rem;
          margin: 1rem auto;
        }
      }

      .circle-with-number {
        background: @vikinglotto-darkblue;
      }
    }
  }
}
