// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

@vikinglotto-overlay-animation-duration: .3s;

.vikinglotto-classic-game-overlay, .vikinglotto-lightning-game-overlay, .vikinglotto-system-game-overlay, .vikinglotto-lucky-game-overlay, .vikinglotto-coupon-overlay {
  .keyframes(vikinglotto-overlay-background-animation; {
    0% {
      background-color: rgba(0,0,0,0);
    }

    100% {
      background-color: rgba(0,0,0,.3);
    }
  }

);
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  .animation(vikinglotto-overlay-background-animation @vikinglotto-overlay-animation-duration forwards linear);

  .keyframes(vikinglotto-overlay-panel-animation; {
    0% {
      .transform(translateY(-50%) scale(.85));
      .blur(.3rem);
      .opacity(0);
    }

    100% {
      .transform(translateY(-50%) scale(1));
      .blur(0);
      .opacity(1);
    }
  }

);

  .keyframes(vikinglotto-overlay-panel-remove-animation; {
    0% {
      .transform(translateY(-50%) scale(1));
      .blur(0);
      .opacity(1);
    }

    100% {
      .transform(translateY(-50%) scale(.85));
      .blur(.3rem);
      .opacity(0);
    }
  }

);

  .pannel {
    width: 80%;
    background-color: #fff;
    margin: auto;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    .transform(translateY(-50%));
    padding: 5.3333vw;
    .box-sizing();
    .border-radius(6px);
    .animation(vikinglotto-overlay-panel-animation @vikinglotto-overlay-animation-duration forwards linear);

    @media (min-width: @vikinglotto-desktop-width) {
      width: 30rem;
      padding: 2rem;
    }
  }

  .icon-wrapper {
    width: 17.334vw;
    height: 17.334vw;
    background-color: @vikinglotto-darkblue;
    display: inline-block;
    .border-radius(50%);

    & > .icon {
      width: 9.4vw;
      height: 9.4vw;
      fill: #fff;
      position: relative;
      top: 50%;
      .transform(translateY(-50%));

      @media (min-width: @vikinglotto-desktop-width) {
        width: 3.4rem;
        height: 3.4rem;
      }
    }

    @media (min-width: @vikinglotto-desktop-width) {
      width: 6.5rem;
      height: 6.5rem;
    }
  }

  .headline {
    font-size: 6.666vw;
    font-weight: 900;
    font-style: italic;
    text-transform: uppercase;
    color: @vikinglotto-darkblue;
    margin: 3.5vw 0 0;

    @media (min-width: @vikinglotto-desktop-width) {
      font-size: 2.5rem;
      margin: 1rem 1.5rem 0.3rem;
    }
  }

  .text {
    font-size: 3vw;
    margin: 3vw 0 0;

    @media (min-width: @vikinglotto-desktop-width) {
      font-size: 1.5rem;
      margin: 0.3rem 1.5rem 1.5rem;
    }
  }

  .buttons {
    margin: 4.5vw 0 0;
    .clearfix();

    & > .btn:first-child {
      float: left;
    }

    .btn {
      /*font-weight: normal;*/
      color: @vikinglotto-black;
    }

    & > .btn:last-child {
      float: right;
    }

    @media (min-width: @vikinglotto-desktop-width) {
      margin: 1.5rem 0 0;

      .btn {
        width: 12.4rem;
      }

      & > .btn:first-child {
        float: none;
        margin-right: .5rem;
      }

      & > .btn:last-child {
        float: none;
        margin-left: .5rem;
      }
    }
  }

  &.remove-overlay {
    .animation(vikinglotto-overlay-background-animation @vikinglotto-overlay-animation-duration/2 reverse linear);

    .pannel {
      .animation(vikinglotto-overlay-panel-remove-animation @vikinglotto-overlay-animation-duration/2 forwards linear);
    }
  }
}

.vikinglotto-coupon-overlay {
  .pannel {
    padding: 0;
    overflow: hidden;

    @media (min-width: @vikinglotto-desktop-width) {
      width: 35rem;
    }

    @keyframes lotto-coupon-iframe-wrapper-height-animation-desktop {
      100% {
        height: 40rem;
        opacity: 1;
      }
    }

    @keyframes lotto-coupon-iframe-wrapper-height-animation-mobile {
      100% {
        height: 60vh;
        opacity: 1;
      }
    }

    .coupon-iframe-wrapper {

      // On iOS devices there are issues scrolling an iframe, so we move the animation to the wrapping element
      .ios-device & {
        height: 0;
        opacity: 0;
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll;
        animation: lotto-coupon-iframe-wrapper-height-animation-mobile .3s .3s linear forwards;
  
        @media (min-width: @vikinglotto-desktop-width) {
          animation: lotto-coupon-iframe-wrapper-height-animation-desktop .3s .3s linear forwards;
        }
      }
    
      .coupon-iframe {
        background-color: @vikinglotto-darkblue;
        width: 100%;
        height: 0;
        opacity: 0;
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll;
        
        animation: lotto-coupon-iframe-wrapper-height-animation-mobile .3s .3s linear forwards;
  
        @media (min-width: @vikinglotto-desktop-width) {
          animation: lotto-coupon-iframe-wrapper-height-animation-desktop .3s .3s linear forwards;
        }

        // On iOS devices there are issues scrolling an iframe, so we move the animation to the wrapping element
        .ios-device & {
          height: 100%; 
          opacity: 1;
          overflow: visible;
          animation: none;

          @media (min-width: @vikinglotto-desktop-width) {
            animation: none;
          }
        }
      }
    }

    .buttons {
      margin: 3vw 4vw 4vw;

      @media (min-width: @vikinglotto-desktop-width) {
        margin: 1.5rem 2rem 2rem;
      }

      .btn {
        width: 35vw;
        transition: background-color .15s linear;

        &:hover {
          @media (min-width: @vikinglotto-desktop-width) {
            background-color: @vikinglotto-cta-yellow-hover;
          }
        }

        @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
          width: 34vw;
        }

        @media (min-width: @vikinglotto-desktop-width) {
          width: 15rem;
        }
      }
    }
  }
}

.keyframes(vikinglotto-overlay-active-animation; {
  0% {
    .blur(0);
  }

  100% {
    .blur(.3rem);
  }
}

); .overlay-active {
  .animation(vikinglotto-overlay-active-animation @vikinglotto-overlay-animation-duration forwards linear);
}

.remove-overlay {
  pointer-events: none;
  .animation(vikinglotto-overlay-active-animation @vikinglotto-overlay-animation-duration/2 reverse linear);
}
