@import (reference) "../variables.less";

body.vikinglotto-page,
body.region-vikinglotto,
body.region-avalon-vikinglotto {
  .seo-text-container,
  footer .info-bar {
    background: @vikinglotto-background;
  }
}
