.region-vikinglotto {
  width: 100%;
  font-size: 4vw;
}
@media (min-width: 950px) {
  .region-vikinglotto {
    font-size: 1.4rem;
  }
}
.region-vikinglotto .container {
  width: 100vw;
}
@media (min-width: 950px) {
  .region-vikinglotto .container {
    width: 100%;
  }
}
.region-vikinglotto .page-box .container {
  width: 100%;
}
.region-vikinglotto .mobile-only {
  display: inherit;
}
@media (min-width: 950px) {
  .region-vikinglotto .mobile-only {
    display: none;
  }
}
.region-vikinglotto .mobile-hide {
  display: none;
}
@media (min-width: 950px) {
  .region-vikinglotto .mobile-hide {
    display: inherit;
  }
}
.vikinglotto-section {
  position: relative;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  background-color: #000078;
  border-color: #000078;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.vikinglotto-section.bg-white {
  background-color: #fff;
  border-color: #fff;
}
.vikinglotto-section > .vikinglotto-content-wrapper {
  margin: auto;
  text-align: center;
  z-index: 4;
  padding: 15vw 0;
}
@media (min-width: 950px) {
  .vikinglotto-section > .vikinglotto-content-wrapper {
    padding: 8rem 0;
  }
}
.vikinglotto-section > .vikinglotto-content-wrapper .a {
  cursor: pointer;
  color: #fff;
}
.vikinglotto-section > .vikinglotto-content-wrapper .a.hd {
  text-decoration: none;
}
.vikinglotto-section > .vikinglotto-content-wrapper .p {
  width: 100%;
  max-width: 86.5vw;
  margin: auto;
  color: #fff;
  font-size: 4vw;
  line-height: normal;
}
@media (min-width: 950px) {
  .vikinglotto-section > .vikinglotto-content-wrapper .p {
    font-size: 1.5rem;
    width: 950px;
  }
}
.vikinglotto-section > .vikinglotto-content-wrapper .p .a {
  cursor: pointer;
  color: #fff;
}
.vikinglotto-section > .vikinglotto-content-wrapper .p .a.hd {
  text-decoration: none;
}
.vikinglotto-section > .vikinglotto-content-wrapper .hr {
  width: 86.5vw;
  border-width: .2rem;
  border-style: solid;
  border-color: #ededee;
  border-top: 0;
  margin: 6vw auto;
}
@media (min-width: 950px) {
  .vikinglotto-section > .vikinglotto-content-wrapper .hr {
    margin: 2.5rem auto;
    max-width: 950px;
  }
}
.section.manchete,
.vikinglotto-section.manchete {
  position: relative;
}
.section.manchete.top:before,
.vikinglotto-section.manchete.top:before,
.section.manchete.bot:after,
.vikinglotto-section.manchete.bot:after {
  content: '';
  background-image: url(/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons.svg#manchete-vikinglotto_use);
  display: block;
  width: 100%;
  height: 2vw;
  position: absolute;
  background-size: 100% 100%;
  z-index: 1;
}
.section.manchete.top.manchete--white:before,
.vikinglotto-section.manchete.top.manchete--white:before,
.section.manchete.bot.manchete--white:after,
.vikinglotto-section.manchete.bot.manchete--white:after {
  background-image: url(/Components/DanskeSpil/Domain/VikingLotto/Graphics/SpriteSheets/VikingLottoCommonIcons.svg#manchete-vikinglotto--white_use);
}
.section.manchete.top:before,
.vikinglotto-section.manchete.top:before {
  top: calc(-2vw + 1px);
}
.section.manchete.bot:after,
.vikinglotto-section.manchete.bot:after {
  bottom: calc(-2vw + 1px);
  transform: rotate(0.5turn);
}
