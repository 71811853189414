@import url("../../Mixins/_mixins.less");

.region-vikinglotto {

  .informationswitcherspotview {
    padding: 10rem 0;

    .select-dropdown {

      &.is-active .cs-placeholder {
        color: @numbergames-cta-yellow;
      }

      .cs-placeholder {
        background: @vikinglotto-darkblue;
      }

      .select-options {
        background-color: @vikinglotto-darkblue;

        ul li {
          transition: background-color 0.3s;

          &:hover {
            background-color: @vikinglotto-mediumblue;
          }
        }
      }
    }
  }
}
