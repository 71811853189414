@media (min-width: 950px) {
  .vikinglotto-classic-game {
    background-color: #000078;
    position: relative;
  }
}
.vikinglotto-classic-game .vikinglotto-content-wrapper {
  width: 100vw;
  text-align: left;
  overflow: hidden;
  padding: 0;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .vikinglotto-content-wrapper {
    width: 100%;
    overflow: visible;
  }
}
.vikinglotto-classic-game .rows-container {
  height: 126.17vw;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  padding-top: 3.633vw;
  padding-bottom: 4.8vw;
  position: relative;
  will-change: transform;
  transform-origin: right center;
  transition: transform .2s linear;
  overflow: hidden;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-container {
    height: 43.8rem;
    padding-top: 0;
    padding-bottom: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .rows-container {
    height: 87vw;
  }
}
@-webkit-keyframes vikinglotto-slide-to-right-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-moz-keyframes vikinglotto-slide-to-right-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-ms-keyframes vikinglotto-slide-to-right-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@keyframes vikinglotto-slide-to-right-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-webkit-keyframes vikinglotto-slide-to-right-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes vikinglotto-slide-to-right-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes vikinglotto-slide-to-right-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes vikinglotto-slide-to-right-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes vikinglotto-slide-to-right-prev-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes vikinglotto-slide-to-right-prev-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes vikinglotto-slide-to-right-prev-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes vikinglotto-slide-to-right-prev-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes vikinglotto-slide-to-left-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-moz-keyframes vikinglotto-slide-to-left-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-ms-keyframes vikinglotto-slide-to-left-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@keyframes vikinglotto-slide-to-left-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-webkit-keyframes vikinglotto-slide-to-left-next-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes vikinglotto-slide-to-left-next-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes vikinglotto-slide-to-left-next-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes vikinglotto-slide-to-left-next-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes vikinglotto-slide-to-right-next-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes vikinglotto-slide-to-right-next-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes vikinglotto-slide-to-right-next-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes vikinglotto-slide-to-right-next-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes vikinglotto-slide-to-left-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes vikinglotto-slide-to-left-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes vikinglotto-slide-to-left-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes vikinglotto-slide-to-left-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@media (min-width: 950px) {
  @-webkit-keyframes vikinglotto-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes vikinglotto-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes vikinglotto-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
}
@media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  @-webkit-keyframes vikinglotto-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes vikinglotto-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes vikinglotto-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
}
@media screen and (min-resolution: 2dppx) and (min-width: 950px) {
  @-webkit-keyframes vikinglotto-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes vikinglotto-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes vikinglotto-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes vikinglotto-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes vikinglotto-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes vikinglotto-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes vikinglotto-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
}
.vikinglotto-classic-game .rows-container.slide-to-right {
  -webkit-animation: vikinglotto-slide-to-right-animation 0.6s forwards ease-in-out;
  -moz-animation: vikinglotto-slide-to-right-animation 0.6s forwards ease-in-out;
  -ms-animation: vikinglotto-slide-to-right-animation 0.6s forwards ease-in-out;
  animation: vikinglotto-slide-to-right-animation 0.6s forwards ease-in-out;
}
.vikinglotto-classic-game .rows-container.slide-to-right .in-focus {
  -webkit-animation: vikinglotto-slide-to-right-infocus-row-animation 0.6s forwards ease-in-out;
  -moz-animation: vikinglotto-slide-to-right-infocus-row-animation 0.6s forwards ease-in-out;
  -ms-animation: vikinglotto-slide-to-right-infocus-row-animation 0.6s forwards ease-in-out;
  animation: vikinglotto-slide-to-right-infocus-row-animation 0.6s forwards ease-in-out;
}
.vikinglotto-classic-game .rows-container.slide-to-right .next-row {
  -webkit-animation: vikinglotto-slide-to-right-next-row-animation 0.6s forwards ease-in-out;
  -moz-animation: vikinglotto-slide-to-right-next-row-animation 0.6s forwards ease-in-out;
  -ms-animation: vikinglotto-slide-to-right-next-row-animation 0.6s forwards ease-in-out;
  animation: vikinglotto-slide-to-right-next-row-animation 0.6s forwards ease-in-out;
}
.vikinglotto-classic-game .rows-container.slide-to-right .prev-row {
  -webkit-animation: vikinglotto-slide-to-right-prev-row-animation 0.6s forwards ease-in-out;
  -moz-animation: vikinglotto-slide-to-right-prev-row-animation 0.6s forwards ease-in-out;
  -ms-animation: vikinglotto-slide-to-right-prev-row-animation 0.6s forwards ease-in-out;
  animation: vikinglotto-slide-to-right-prev-row-animation 0.6s forwards ease-in-out;
}
.vikinglotto-classic-game .rows-container.slide-to-right .prev-prev-row {
  -webkit-animation: vikinglotto-slide-to-right-prev-prev-row-animation 0.6s forwards ease-in-out;
  -moz-animation: vikinglotto-slide-to-right-prev-prev-row-animation 0.6s forwards ease-in-out;
  -ms-animation: vikinglotto-slide-to-right-prev-prev-row-animation 0.6s forwards ease-in-out;
  animation: vikinglotto-slide-to-right-prev-prev-row-animation 0.6s forwards ease-in-out;
}
.vikinglotto-classic-game .rows-container.slide-to-left,
.vikinglotto-classic-game .rows-container.slide-to-left-half {
  -webkit-animation: vikinglotto-slide-to-left-animation 0.6s forwards linear;
  -moz-animation: vikinglotto-slide-to-left-animation 0.6s forwards linear;
  -ms-animation: vikinglotto-slide-to-left-animation 0.6s forwards linear;
  animation: vikinglotto-slide-to-left-animation 0.6s forwards linear;
}
.vikinglotto-classic-game .rows-container.slide-to-left .in-focus,
.vikinglotto-classic-game .rows-container.slide-to-left-half .in-focus {
  -webkit-animation: vikinglotto-slide-to-left-infocus-row-animation 0.6s forwards linear;
  -moz-animation: vikinglotto-slide-to-left-infocus-row-animation 0.6s forwards linear;
  -ms-animation: vikinglotto-slide-to-left-infocus-row-animation 0.6s forwards linear;
  animation: vikinglotto-slide-to-left-infocus-row-animation 0.6s forwards linear;
}
.vikinglotto-classic-game .rows-container.slide-to-left .next-row,
.vikinglotto-classic-game .rows-container.slide-to-left-half .next-row {
  -webkit-animation: vikinglotto-slide-to-left-next-row-animation 0.6s forwards linear;
  -moz-animation: vikinglotto-slide-to-left-next-row-animation 0.6s forwards linear;
  -ms-animation: vikinglotto-slide-to-left-next-row-animation 0.6s forwards linear;
  animation: vikinglotto-slide-to-left-next-row-animation 0.6s forwards linear;
}
.vikinglotto-classic-game .rows-container.slide-to-left .next-next-row,
.vikinglotto-classic-game .rows-container.slide-to-left-half .next-next-row {
  -webkit-animation: vikinglotto-slide-to-left-next-next-row-animation 0.6s forwards linear;
  -moz-animation: vikinglotto-slide-to-left-next-next-row-animation 0.6s forwards linear;
  -ms-animation: vikinglotto-slide-to-left-next-next-row-animation 0.6s forwards linear;
  animation: vikinglotto-slide-to-left-next-next-row-animation 0.6s forwards linear;
}
.vikinglotto-classic-game .rows-container.slide-to-left .prev-row,
.vikinglotto-classic-game .rows-container.slide-to-left-half .prev-row {
  -webkit-animation: vikinglotto-slide-to-left-prev-row-animation 0.6s forwards linear;
  -moz-animation: vikinglotto-slide-to-left-prev-row-animation 0.6s forwards linear;
  -ms-animation: vikinglotto-slide-to-left-prev-row-animation 0.6s forwards linear;
  animation: vikinglotto-slide-to-left-prev-row-animation 0.6s forwards linear;
}
.vikinglotto-classic-game .rows-container.slide-to-left .in-focus + .next-row:not(.interacted-row):last-of-type,
.vikinglotto-classic-game .rows-container.slide-to-left-half .in-focus + .next-row:not(.interacted-row):last-of-type {
  -webkit-animation: vikinglotto-slide-to-left-next-row-animation 0.6s forwards linear;
  -moz-animation: vikinglotto-slide-to-left-next-row-animation 0.6s forwards linear;
  -ms-animation: vikinglotto-slide-to-left-next-row-animation 0.6s forwards linear;
  animation: vikinglotto-slide-to-left-next-row-animation 0.6s forwards linear;
}
@-webkit-keyframes vikinglotto-delete-row-animation-in-focus-row {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-moz-keyframes vikinglotto-delete-row-animation-in-focus-row {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-ms-keyframes vikinglotto-delete-row-animation-in-focus-row {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@keyframes vikinglotto-delete-row-animation-in-focus-row {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1400, 0, 1);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
.vikinglotto-classic-game .rows-container.delete-row > .in-focus {
  -webkit-animation: vikinglotto-delete-row-animation-in-focus-row 0.5s 0s forwards linear;
  -moz-animation: vikinglotto-delete-row-animation-in-focus-row 0.5s 0s forwards linear;
  -ms-animation: vikinglotto-delete-row-animation-in-focus-row 0.5s 0s forwards linear;
  animation: vikinglotto-delete-row-animation-in-focus-row 0.5s 0s forwards linear;
}
.vikinglotto-classic-game .rows-container.delete-row > .in-focus + .next-row {
  -webkit-animation: vikinglotto-slide-to-right-infocus-row-animation 0.5s 0.15s forwards linear;
  -moz-animation: vikinglotto-slide-to-right-infocus-row-animation 0.5s 0.15s forwards linear;
  -ms-animation: vikinglotto-slide-to-right-infocus-row-animation 0.5s 0.15s forwards linear;
  animation: vikinglotto-slide-to-right-infocus-row-animation 0.5s 0.15s forwards linear;
}
.vikinglotto-classic-game .rows-container.delete-row > .in-focus + .next-row + div {
  -webkit-animation: vikinglotto-delete-row-animation-next-next-row 0.5s 0.15s forwards linear;
  -moz-animation: vikinglotto-delete-row-animation-next-next-row 0.5s 0.15s forwards linear;
  -ms-animation: vikinglotto-delete-row-animation-next-next-row 0.5s 0.15s forwards linear;
  animation: vikinglotto-delete-row-animation-next-next-row 0.5s 0.15s forwards linear;
}
.vikinglotto-classic-game .left-gradient,
.vikinglotto-classic-game .right-gradient {
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .left-gradient,
  .vikinglotto-classic-game .right-gradient {
    width: 16vw;
  }
}
.vikinglotto-classic-game .left-gradient {
  left: 0;
  background: -moz-linear-gradient(left, #000078 0%, rgba(0, 0, 120, 0) 100%);
  background: -webkit-linear-gradient(left, #000078 0%, rgba(0, 0, 120, 0) 100%);
  background: linear-gradient(to right, #000078 0%, rgba(0, 0, 120, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000078', endColorstr='#00000078', GradientType=1);
}
.vikinglotto-classic-game .right-gradient {
  right: -19vw;
  background: -moz-linear-gradient(left, rgba(0, 0, 120, 0) 0%, #000078 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 120, 0) 0%, #000078 100%);
  background: linear-gradient(to right, rgba(0, 0, 120, 0) 0%, #000078 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000078', endColorstr='#000078', GradientType=1);
  transition: right .25S linear;
}
.vikinglotto-classic-game .right-gradient.active {
  right: 0;
}
.vikinglotto-classic-game .rows-wrapper {
  filter: url("#rows-side-sliding-blur");
}
.vikinglotto-classic-game .row-container {
  width: 74.799%;
  padding-bottom: 1.6vw;
  display: inline-block;
  position: absolute;
  visibility: hidden;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-container {
    width: 91.6rem;
    padding-bottom: 1.9rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .row-container {
    width: 52%;
  }
}
.vikinglotto-classic-game .row-container.in-focus,
.vikinglotto-classic-game .row-container.next-row,
.vikinglotto-classic-game .row-container.prev-row,
.vikinglotto-classic-game .row-container.next-next-row,
.vikinglotto-classic-game .row-container.prev-prev-row {
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  visibility: visible;
  will-change: transform, opacity;
}
.vikinglotto-classic-game .row-container.in-focus {
  -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  z-index: 1;
}
.vikinglotto-classic-game .row-container.next-row:after,
.vikinglotto-classic-game .row-container.prev-row:after,
.vikinglotto-classic-game .row-container.prev-prev-row:after,
.vikinglotto-classic-game .row-container.next-next-row:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.vikinglotto-classic-game .row-container.next-row {
  -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
  -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
  -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
  -o-transform: scale(0.9) translate3D(115%, 1%, 0);
  transform: scale(0.9) translate3D(115%, 1%, 0);
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-container.next-row {
    -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
    -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
    -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
    -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
    transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
  }
}
@media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  .vikinglotto-classic-game .row-container.next-row {
    -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
    -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
    -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
    -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
    transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 727, 6, 0, 1);
  }
}
@media screen and (min-resolution: 2dppx) and (min-device-width: 950px) {
  .vikinglotto-classic-game .row-container.next-row {
    -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
    -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
    -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
    -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
    transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 900, 6, 0, 1);
  }
}
.vikinglotto-classic-game .row-container.next-next-row {
  -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
  -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
  -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
  -o-transform: scale(0.9) translate3D(185%, 1%, 0);
  transform: scale(0.9) translate3D(185%, 1%, 0);
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-container.next-next-row {
    -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
    -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
    -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
    -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
    transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
  }
}
@media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  .vikinglotto-classic-game .row-container.next-next-row {
    -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
    -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
    -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
    -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
    transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1140, 6, 0, 1);
  }
}
@media screen and (min-resolution: 2dppx) and (min-device-width: 950px) {
  .vikinglotto-classic-game .row-container.next-next-row {
    -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
    -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
    -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
    -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
    transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, 1400, 6, 0, 1);
  }
}
.vikinglotto-classic-game .row-container.prev-row {
  -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
  -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
  -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
  -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
  transform: scale(0.9) translate3D(-115%, 1%, 0);
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-container.prev-row {
    -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
    -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
    -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
    -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
    transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
  }
}
@media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  .vikinglotto-classic-game .row-container.prev-row {
    -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
    -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
    -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
    -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
    transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -727, 6, 0, 1);
  }
}
@media screen and (min-resolution: 2dppx) and (min-width: 950px) {
  .vikinglotto-classic-game .row-container.prev-row {
    -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
    -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
    -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
    -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
    transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -900, 6, 0, 1);
  }
}
.vikinglotto-classic-game .row-container.prev-prev-row {
  -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
  -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
  -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
  -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
  transform: scale(0.9) translate3D(-185%, 1%, 0);
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-container.prev-prev-row {
    -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
    -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
    -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
    -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
    transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
  }
}
@media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  .vikinglotto-classic-game .row-container.prev-prev-row {
    -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
    -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
    -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
    -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
    transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1140, 6, 0, 1);
  }
}
@media screen and (min-resolution: 2dppx) and (min-width: 950px) {
  .vikinglotto-classic-game .row-container.prev-prev-row {
    -webkit-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
    -moz-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
    -ms-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
    -o-transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
    transform: matrix3d(0.815, 0, 0, 0, 0, 0.815, 0, 0, 0, 0, 1, 0, -1400, 6, 0, 1);
  }
}
@keyframes vikinglotto-not-completed-animation {
  0% {
    transform: scale(1);
  }
  14.28% {
    transform: scale(1.2);
  }
  28.57% {
    transform: scale(1);
  }
  42.85% {
    transform: scale(1.2);
  }
  57.13% {
    transform: scale(1);
  }
  71.41% {
    transform: scale(1.2);
  }
  85.69% {
    transform: scale(1);
  }
}
@keyframes vikinglotto-not-completed-text-animation {
  0% {
    transform: scale(1);
    opacity: .3;
  }
  25% {
    transform: scale(1.3);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    opacity: .3;
  }
  75% {
    transform: scale(1.3);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.vikinglotto-classic-game .row-container.row-not-completed .cell:not(.selected) {
  animation: vikinglotto-not-completed-animation 1s forwards;
}
.vikinglotto-classic-game .row-container.row-not-completed .viking-box:not(.selected) {
  animation: vikinglotto-not-completed-animation 1s forwards;
}
.vikinglotto-classic-game .row-container.row-not-completed .header-rules-text {
  animation: vikinglotto-not-completed-text-animation 1s forwards;
}
@keyframes vikinglotto-row-demo-animation {
  0% {
    opacity: .5;
    left: 0%;
  }
  20% {
    opacity: .9;
    left: -5%;
  }
  60% {
    opacity: .9;
    left: -5%;
  }
  100% {
    opacity: .5;
    left: 0%;
  }
}
@keyframes vikinglotto-row-demo1-animation {
  0% {
    filter: blur(0);
  }
  10% {
    filter: blur(5px);
  }
  80% {
    filter: blur(5px);
  }
  100% {
    filter: blur(0);
  }
}
@keyframes vikinglotto-row-demo2-animation {
  0% {
    opacity: .5;
  }
  40% {
    opacity: .7;
  }
  60% {
    opacity: .7;
  }
  100% {
    opacity: .5;
  }
}
@keyframes vikinglotto-cell-selection-animation-demo {
  0% {
    width: 0%;
    height: 0%;
    top: 50%;
    left: 50%;
    margin: 0;
  }
  40% {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    margin: -50%;
  }
  70% {
    width: 130%;
    height: 130%;
    top: 50%;
    left: 50%;
    margin: -65%;
  }
  100% {
    width: 0%;
    height: 0%;
    top: 50%;
    left: 50%;
    margin: 0;
  }
}
.vikinglotto-classic-game .row-container.row-completed.row-blury.row-number-1 {
  animation: vikinglotto-row-demo1-animation 5s linear forwards;
}
.vikinglotto-classic-game .row-container.row-completed + .row-number-2.new-row {
  animation: vikinglotto-row-demo-animation 0.7s 4s linear forwards;
}
.vikinglotto-classic-game .row-container.row-completed + .new-row {
  animation: vikinglotto-row-demo2-animation 0.7s 4s linear forwards;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-container.row-completed + .new-row .cell .back-face {
    animation-name: vikinglotto-cell-selection-animation-demo;
    animation-duration: .6s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-container.row-completed + .new-row .cell:nth-child(12n+1) .back-face {
    animation-delay: 0s;
  }
  .vikinglotto-classic-game .row-container.row-completed + .new-row .cell:nth-child(12n+2) .back-face {
    animation-delay: .09s;
  }
  .vikinglotto-classic-game .row-container.row-completed + .new-row .cell:nth-child(12n+3) .back-face {
    animation-delay: .18s;
  }
  .vikinglotto-classic-game .row-container.row-completed + .new-row .cell:nth-child(12n+4) .back-face {
    animation-delay: .27s;
  }
  .vikinglotto-classic-game .row-container.row-completed + .new-row .cell:nth-child(12n+5) .back-face {
    animation-delay: .36s;
  }
  .vikinglotto-classic-game .row-container.row-completed + .new-row .cell:nth-child(12n+6) .back-face {
    animation-delay: .45s;
  }
  .vikinglotto-classic-game .row-container.row-completed + .new-row .cell:nth-child(12n+7) .back-face {
    animation-delay: .54s;
  }
  .vikinglotto-classic-game .row-container.row-completed + .new-row .cell:nth-child(12n+8) .back-face {
    animation-delay: .63s;
  }
  .vikinglotto-classic-game .row-container.row-completed + .new-row .cell:nth-child(12n+9) .back-face {
    animation-delay: .72s;
  }
  .vikinglotto-classic-game .row-container.row-completed + .new-row .cell:nth-child(12n+10) .back-face {
    animation-delay: .81s;
  }
  .vikinglotto-classic-game .row-container.row-completed + .new-row .cell:nth-child(12n+11) .back-face {
    animation-delay: .90s;
  }
  .vikinglotto-classic-game .row-container.row-completed + .new-row .cell:nth-child(12n+12) .back-face {
    animation-delay: .99s;
  }
}
.vikinglotto-classic-game .prev-row-button {
  width: 10.5vw;
  height: 32vw;
  background-color: rgba(235, 248, 253, 0.8);
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 0 6px 6px 0;
  -webkit-transform: translateY(-50%) translateX(-10.5vw);
  -moz-transform: translateY(-50%) translateX(-10.5vw);
  -ms-transform: translateY(-50%) translateX(-10.5vw);
  -o-transform: translateY(-50%) translateX(-10.5vw);
  transform: translateY(-50%) translateX(-10.5vw);
  -webkit-transition: -webkit-transform 0.15s;
  -moz-transition: -moz-transform 0.15s;
  -ms-transition: -ms-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  z-index: 1;
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .prev-row-button {
    width: 8vw;
    height: 28vw;
    top: 47%;
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .prev-row-button {
    width: 5rem;
    height: 14rem;
    -webkit-transform: translateY(-50%) translateX(-5rem);
    -moz-transform: translateY(-50%) translateX(-5rem);
    -ms-transform: translateY(-50%) translateX(-5rem);
    -o-transform: translateY(-50%) translateX(-5rem);
    transform: translateY(-50%) translateX(-5rem);
    cursor: pointer;
  }
}
.vikinglotto-classic-game .prev-row-button .icon {
  width: 2.4vw;
  height: 3.468vw;
  font-size: 4.5vw;
  line-height: .55;
  fill: #1d1d1d;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-1.5vw);
  -moz-transform: translateY(-50%) translateX(-1.5vw);
  -ms-transform: translateY(-50%) translateX(-1.5vw);
  -o-transform: translateY(-50%) translateX(-1.5vw);
  transform: translateY(-50%) translateX(-1.5vw);
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .prev-row-button .icon {
    width: 1.2rem;
    height: 1.2rem;
    -webkit-transform: translateY(-50%) translateX(-0.2rem);
    -moz-transform: translateY(-50%) translateX(-0.2rem);
    -ms-transform: translateY(-50%) translateX(-0.2rem);
    -o-transform: translateY(-50%) translateX(-0.2rem);
    transform: translateY(-50%) translateX(-0.2rem);
  }
}
.vikinglotto-classic-game .prev-row-button.active {
  transform: translateY(-50%) translateX(0);
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .prev-row-button.active {
    transform: translateY(-50%) translateX(-0.8rem);
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .prev-row-button.active:hover {
    transform: translateY(-50%) translateX(0);
  }
}
.vikinglotto-classic-game .create-new-row-button,
.vikinglotto-classic-game .next-row-button {
  width: 10.5vw;
  height: 32vw;
  background-color: rgba(235, 248, 253, 0.8);
  position: absolute;
  right: 0;
  top: 50%;
  border-radius: 6px 0 0 6px;
  transform: translateY(-50%) translateX(10.5vw);
  transition: right .15s, transform .15s;
  z-index: 1;
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .create-new-row-button,
  .vikinglotto-classic-game .next-row-button {
    width: 8vw;
    height: 28vw;
    top: 47%;
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .create-new-row-button,
  .vikinglotto-classic-game .next-row-button {
    width: 5rem;
    height: 14rem;
    -webkit-transform: translateY(-50%) translateX(5rem);
    -moz-transform: translateY(-50%) translateX(5rem);
    -ms-transform: translateY(-50%) translateX(5rem);
    -o-transform: translateY(-50%) translateX(5rem);
    transform: translateY(-50%) translateX(5rem);
    cursor: pointer;
  }
}
.vikinglotto-classic-game .create-new-row-button .icon,
.vikinglotto-classic-game .next-row-button .icon {
  width: 2.4vw;
  height: 3.468vw;
  font-size: 4.5vw;
  line-height: .55;
  fill: #1d1d1d;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-1.5vw);
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .create-new-row-button .icon,
  .vikinglotto-classic-game .next-row-button .icon {
    width: 1.2rem;
    height: 1.2rem;
    transform: translateY(-50%) translateX(-0.8rem);
  }
}
.vikinglotto-classic-game .next-row-button.active {
  transform: translateY(-50%) translateX(0);
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .next-row-button.active {
    transform: translateY(-50%) translateX(0.8rem);
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .next-row-button.active:hover {
    transform: translateY(-50%) translateX(0);
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .create-new-row-button {
    width: 6.4rem;
    transform: translateY(-50%) translateX(6.4rem);
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .create-new-row-button .icon {
    -webkit-transform: translateY(-50%) translateX(-1.6rem);
    -moz-transform: translateY(-50%) translateX(-1.6rem);
    -ms-transform: translateY(-50%) translateX(-1.6rem);
    -o-transform: translateY(-50%) translateX(-1.6rem);
    transform: translateY(-50%) translateX(-1.6rem);
  }
}
@keyframes vikinglotto-create-button-demo-animation {
  0% {
    transform: translateY(-50%) translateX(6.4rem);
  }
  40% {
    background-color: rgba(235, 248, 253, 0.8);
    transform: translateY(-50%) translateX(0);
  }
  100% {
    transform: translateY(-50%) translateX(2.3rem);
  }
}
.vikinglotto-classic-game .create-new-row-button.active {
  transform: translateY(-50%) translateX(0);
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .create-new-row-button.active {
    -webkit-transform: translateY(-50%) translateX(2.3rem);
    -moz-transform: translateY(-50%) translateX(2.3rem);
    -ms-transform: translateY(-50%) translateX(2.3rem);
    -o-transform: translateY(-50%) translateX(2.3rem);
    transform: translateY(-50%) translateX(2.3rem);
    animation: vikinglotto-create-button-demo-animation 0.95s linear forwards;
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .create-new-row-button.active:hover {
    right: .8rem;
  }
}
.vikinglotto-classic-game .create-new-row-button.active ~ .next-row-button.active {
  display: none;
}
@keyframes vikinglotto-create-new-row-text-animation {
  0% {
    transform: translateY(0vw) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
    opacity: 0;
    filter: blur(5px);
  }
  10% {
    transform: translateY(65vw) translateY(-50%) translateX(-50vw) translateX(50%) scale(1.1);
    opacity: 1;
    filter: blur(0);
  }
  15% {
    transform: translateY(68vw) translateY(-50%) translateX(-50vw) translateX(50%) scale(1.1);
    opacity: 1;
    filter: blur(0);
  }
  75% {
    transform: translateY(68vw) translateY(-50%) translateX(-50vw) translateX(50%) scale(1.1);
    opacity: 1;
    filter: blur(0);
  }
  80% {
    transform: translateY(68vw) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
    opacity: 1;
    filter: blur(0);
  }
  100% {
    transform: translateY(68vw) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
    opacity: 1;
    filter: blur(0);
  }
}
@keyframes vikinglotto-create-new-row-text-animation-desktop {
  0% {
    transform: translateY(1rem) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
    opacity: 0;
    filter: blur(5px);
  }
  10% {
    transform: translateY(20rem) translateY(-50%) translateX(-50vw) translateX(50%) scale(1.1);
    opacity: 1;
    filter: blur(0);
  }
  15% {
    transform: translateY(24.7rem) translateY(-50%) translateX(-50vw) translateX(50%) scale(1.1);
    opacity: 1;
    filter: blur(0);
  }
  75% {
    transform: translateY(24.7rem) translateY(-50%) translateX(-50vw) translateX(50%) scale(1.1);
    opacity: 1;
    filter: blur(0);
  }
  80% {
    transform: translateY(24.7rem) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
    opacity: 1;
    filter: blur(0);
  }
  100% {
    transform: translateY(24.7rem) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
    opacity: 1;
    filter: blur(0);
  }
}
.vikinglotto-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text {
  width: 64vw;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transform: translateY(0vw) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
  animation: vikinglotto-create-new-row-text-animation 4s 0.5s linear forwards;
  z-index: 3;
  pointer-events: none;
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text {
    top: -25%;
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text {
    width: 130%;
    transform: translateY(1rem) translateY(-50%) translateX(-50vw) translateX(50%) scale(0.6);
    animation: vikinglotto-create-new-row-text-animation-desktop 4s 0.5s linear forwards;
  }
}
@keyframes vikinglotto-create-new-row-text-box-animation {
  0% {
    transform: scale(1);
    opacity: 1;
    filter: blur(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
    filter: blur(5px);
  }
}
.vikinglotto-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-box {
  font-size: 6.5vw;
  line-height: 1.2;
  color: #000078;
  background-color: #fff;
  padding: 1.3vw 2.6vw;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  white-space: normal;
  animation: vikinglotto-create-new-row-text-box-animation 0.5s 3.6s linear forwards;
  pointer-events: none;
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-box {
    font-size: 2.5rem;
    line-height: 1.5;
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-box {
    font-size: 1.5vw;
    padding: 1rem 2rem;
    animation: vikinglotto-create-new-row-text-box-animation 0.5s 3.6s linear forwards;
  }
}
@keyframes vikinglotto-create-new-row-text-arrow-animation {
  0% {
    transform: translateX(0) translateY(-50%) scale(0.2);
    opacity: 0;
  }
  40% {
    transform: translateX(40vw) translateY(-50%) scale(1);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateX(80vw) translateY(-50%) scale(0.8);
    opacity: 0;
    display: none;
  }
}
.vikinglotto-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow {
  height: 35vw;
  width: 1.5vw;
  position: absolute;
  top: 50%;
  right: 25%;
  z-index: -1;
  transform: translateX(0) translateY(-50%) scale(0.2);
  opacity: 0;
  animation: vikinglotto-create-new-row-text-arrow-animation 1s 3.5s linear forwards;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow {
    height: 25rem;
    right: 15%;
  }
}
.vikinglotto-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow:before,
.vikinglotto-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow:after {
  content: '';
  height: 63%;
  width: 5vw;
  background-color: #fff;
  position: absolute;
  right: 0;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow:before,
  .vikinglotto-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow:after {
    width: 5rem;
    height: 66%;
  }
}
.vikinglotto-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow:before {
  top: 10%;
  transform-origin: center top;
  transform: rotate(-45deg);
}
.vikinglotto-classic-game .row-number-2.new-row ~ .create-new-row-button.active ~ .right-gradient .create-new-row-text .create-new-row-text-arrow:after {
  bottom: 10%;
  transform-origin: center bottom;
  transform: rotate(45deg);
}
@keyframes vikinglotto-create-new-row-blink-animation {
  0% {
    background-color: transparent;
  }
  12.5% {
    background-color: rgba(245, 217, 0, 0.95);
  }
  25% {
    background-color: transparent;
  }
  37.5% {
    background-color: rgba(245, 217, 0, 0.95);
  }
  50% {
    background-color: transparent;
  }
  62.5% {
    background-color: rgba(245, 217, 0, 0.95);
  }
  75% {
    background-color: transparent;
  }
  87.5% {
    background-color: rgba(245, 217, 0, 0.95);
  }
  100% {
    background-color: transparent;
  }
}
.vikinglotto-classic-game .row-number-2.new-row ~ .create-new-row-button.active:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border-radius: 6px 0 0 6px;
  animation: vikinglotto-create-new-row-blink-animation 1.5s 3.5s linear forwards;
  z-index: -1;
}
.vikinglotto-classic-game .rows-list-box {
  width: 70vw;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 16vw;
  overflow: visible;
  background-color: #0000ff;
  transform: translateX(-100%);
  transition: transform .25s linear;
  will-change: transform;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box {
    width: 30rem;
    bottom: 7rem;
  }
}
.vikinglotto-classic-game .rows-list-box.active {
  transition: transform .15s linear;
  transform: translateX(0);
}
.vikinglotto-classic-game .rows-list-box.active .toggle-button {
  background-color: #ebf8fd;
}
.vikinglotto-classic-game .rows-list-box.active .toggle-button .icon-list {
  opacity: 0;
}
.vikinglotto-classic-game .rows-list-box.active .toggle-button .icon-arrow {
  opacity: 1;
}
.vikinglotto-classic-game .rows-list-box .toggle-button {
  width: 10.5vw;
  height: 10.5vw;
  background-color: rgba(235, 248, 253, 0.8);
  position: absolute;
  right: 0;
  top: 50%;
  border-radius: 0 6px 6px 0;
  transform: translateY(-30vw) translateX(10.5vw);
  transition: transform 0.15s, background-color 0.25s;
  z-index: 1;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .toggle-button {
    width: 4.2rem;
    height: 7rem;
    transform: translateY(-16rem) translateX(4.2rem);
    cursor: pointer;
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .toggle-button:hover .icon-list,
  .vikinglotto-classic-game .rows-list-box .toggle-button:hover .icon-arrow {
    transform: translateY(-50%) translateX(-50%) scale(1.3);
  }
}
.vikinglotto-classic-game .rows-list-box .toggle-button .icon-list,
.vikinglotto-classic-game .rows-list-box .toggle-button .icon-arrow {
  width: 3.8vw;
  height: 4vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  opacity: 1;
  transition: transform .25s, opacity .25s;
  fill: #1d1d1d;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .toggle-button .icon-list,
  .vikinglotto-classic-game .rows-list-box .toggle-button .icon-arrow {
    width: 1.7rem;
    height: 1.2rem;
  }
}
.vikinglotto-classic-game .rows-list-box .toggle-button .icon-arrow {
  opacity: 0;
}
@keyframes vikinglotto-button-count-wrapper-animation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  70% {
    opacity: 1;
    transform: scale(1.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.vikinglotto-classic-game .rows-list-box .toggle-button .button-count {
  font-size: 3vw;
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding: 0 1.5vw;
  user-select: none;
  border-radius: 3vw;
  background-color: #0000ff;
  position: absolute;
  top: -10%;
  left: 50%;
  opacity: 0;
  animation: vikinglotto-button-count-wrapper-animation 0.25s linear forwards;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .toggle-button .button-count {
    min-width: 1.5rem;
    font-size: 1.4rem;
    padding: .05rem .5rem .1rem;
    border-radius: 1rem;
  }
}
.vikinglotto-classic-game .rows-list-box .container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.vikinglotto-classic-game .rows-list-box .container .items-box {
  font-size: 0;
  position: relative;
}
.vikinglotto-classic-game .rows-list-box .container .items-box .rows-list-header {
  font-size: 3.5vw;
  line-height: 2.6;
  color: #fff;
  text-align: center;
  background-color: #0000ff;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .rows-list-header {
    font-size: 1.6rem;
    line-height: 2.8;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .rows-list-header {
    font-size: 2.6vw;
  }
}
@keyframes vikinglotto-information-text-wrapper-fade-in-animation {
  0% {
    max-height: 0;
    opacity: 0;
    margin-bottom: 0;
  }
  100% {
    max-height: 25rem;
    opacity: 1;
    margin-bottom: 2rem;
  }
}
@keyframes vikinglotto-information-text-wrapper-fade-out-animation {
  0% {
    max-height: 25rem;
    opacity: 1;
    margin-bottom: 2rem;
  }
  100% {
    max-height: 0;
    opacity: 0;
    margin-bottom: 0;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .information-text-wrapper {
  width: 80%;
  max-height: 0;
  margin: auto;
  text-align: center;
  margin-bottom: 0;
  overflow: hidden;
  animation: vikinglotto-information-text-wrapper-fade-in-animation 0.25s linear forwards;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .information-text-wrapper {
    margin-bottom: 2rem;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .information-text-wrapper.remove {
  animation: vikinglotto-information-text-wrapper-fade-out-animation 0.25s linear forwards;
}
.vikinglotto-classic-game .rows-list-box .container .items-box .information-text-wrapper .text-row {
  color: #fff;
  font-size: 6vw;
  font-weight: 600;
  margin-top: 4vw;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .information-text-wrapper .text-row {
    font-size: 2.6rem;
    margin-top: 3rem;
  }
}
@keyframes vikinglotto-rows-list-in-animation {
  0% {
    transform: scaleY(0);
    opacity: 0;
    margin-bottom: -4rem;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
    margin-bottom: 0;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item {
  width: 100%;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  padding-left: 2.666vw;
  box-sizing: border-box;
  transform-origin: top;
  transition: background-color 0.25s linear;
  animation: vikinglotto-rows-list-in-animation 0.15s linear forwards;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item {
    cursor: pointer;
    padding-left: 1.45rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item {
    font-size: 2.6vw;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item:nth-child(odd) {
  background-color: #ededee;
}
.vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item:nth-child(even) {
  background-color: #fff;
}
.vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item.active-row {
  background-color: #83d1ff;
}
.vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item:hover {
  transition: background-color 0.15s linear;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item:hover {
    background-color: #c0f6ff;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner {
  width: 100%;
  margin: auto;
  position: relative;
}
.vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .headline {
  width: 30%;
  font-size: 3.5vw;
  line-height: 2.8;
  color: #0000ff;
  position: relative;
  float: left;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .headline {
    width: 24%;
    font-size: 1.2rem;
    line-height: 3.3;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .headline {
    font-size: 2.6vw;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container {
  width: 70%;
  font-size: 3.5vw;
  line-height: 2.8;
  color: #000078;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container {
    width: 76%;
    font-size: 1.4rem;
    line-height: 2.8;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container {
    font-size: 2.6vw;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container .plus-separator {
  width: 1.867vw;
  height: 1.867vw;
  fill: #000078;
  position: absolute;
  top: 3.9vw;
  left: 34.6vw;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container .plus-separator {
    width: 1rem;
    height: 1rem;
    top: 1.5rem;
    left: 14rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container .plus-separator {
    top: 3.8vw;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container .viking-numbers {
  position: absolute;
  left: 38vw;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .numbers-container .viking-numbers {
    left: 16rem;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .delete-button {
  width: 8vw;
  height: 8.3vw;
  position: absolute;
  right: 0;
  top: .7vw;
  transition: transform .15s linear;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .delete-button:hover {
    transform: scale(1.4);
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .delete-button {
    width: 1rem;
    height: 1rem;
    padding: 1.45rem;
    top: 0;
    cursor: pointer;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .delete-button {
    top: 1vw;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .delete-button .icon {
  width: 2.667vw;
  height: 2.667vw;
  position: absolute;
  fill: #777a7f;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item .item-inner .delete-button .icon {
    width: 1.2rem;
    height: 1.2rem;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item.invalid-row {
  box-sizing: border-box;
  padding-left: 5vw;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item.invalid-row {
    padding-left: 3rem;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item.invalid-row .headline:before {
  content: '!';
  width: 3.734vw;
  height: 3.734vw;
  display: inline-block;
  font-size: 2.7vw;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: #d01212;
  margin-right: 0.934vw;
  position: absolute;
  top: 2.8vw;
  left: -4vw;
  line-height: 1.4;
  text-indent: 1.5vw;
  color: #fff;
  cursor: pointer;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item.invalid-row .headline:before {
    width: 2.2rem;
    height: 2.2rem;
    font-size: 1.4rem;
    margin-right: .7rem;
    line-height: 1.6;
    text-indent: .95rem;
    top: .8rem;
    left: -2.7rem;
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .items-inner-box .item.invalid-row .headline:before {
    top: 2.9vw;
    line-height: 1.2;
    text-indent: 1.4vw;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .error-message-box {
  background-color: #e9e9e9;
  margin-top: -6vw;
  margin-left: -1.333vw;
  padding: 4.666vw 4vw;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  position: absolute;
  -webkit-transform: translateY(-110%);
  -moz-transform: translateY(-110%);
  -ms-transform: translateY(-110%);
  -o-transform: translateY(-110%);
  transform: translateY(-110%);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: -webkit-transform 0.25s, opacity 0.25s, visibility 0s 0.3s;
  -moz-transition: -moz-transform 0.25s, opacity 0.25s, visibility 0s 0.3s;
  -ms-transition: -ms-transform 0.25s, opacity 0.25s, visibility 0s 0.3s;
  -o-transition: -o-transform 0.25s, opacity 0.25s, visibility 0s 0.3s;
  transition: transform 0.25s, opacity 0.25s, visibility 0s 0.3s;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .error-message-box {
    margin-top: -3rem;
    margin-left: -1rem;
    padding: 1.5rem 2rem;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .error-message-box:after {
  content: '';
  border-left: 1.333vw solid transparent;
  border-right: 1.333vw solid transparent;
  border-top: 1.333vw solid #e9e9e9;
  position: absolute;
  bottom: -1.333vw;
  left: 2vw;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .error-message-box:after {
    border-left: .6rem solid transparent;
    border-right: .6rem solid transparent;
    border-top: .6rem solid #e9e9e9;
    bottom: -0.6rem;
    left: 1.3rem;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .error-message-box .error-headline {
  font-size: 3.2vw;
  font-weight: bold;
  text-transform: uppercase;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .error-message-box .error-headline {
    font-size: 1.6rem;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .error-message-box .error-subheadline {
  font-size: 3.2vw;
  font-weight: 300;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .error-message-box .error-subheadline {
    font-size: 1.5rem;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .quick-button-wrapper {
  position: relative;
  padding: 6vw 0;
  text-align: center;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .quick-button-wrapper {
    height: 4rem;
    padding: 2rem 0;
  }
}
@keyframes vikinglotto-quick-button-fade-in-animation {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes vikinglotto-quick-button-fade-out-animation {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(120%);
    opacity: 0;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .quick-button-wrapper .quick-button {
  width: 90%;
  font-size: 3.734vw;
  font-weight: 600;
  color: #1d1d1d;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
  padding: 3vw;
  margin: auto;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  right: 0;
  transition: background-color 0.15s linear;
  animation: vikinglotto-quick-button-fade-in-animation 0.25s linear forwards;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .quick-button-wrapper .quick-button {
    font-size: 1.6rem;
    padding: .9rem;
    cursor: pointer;
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .container .items-box .quick-button-wrapper .quick-button:hover {
    background-color: #ededed;
  }
}
.vikinglotto-classic-game .rows-list-box .container .items-box .quick-button-wrapper .quick-button.inactive {
  animation: vikinglotto-quick-button-fade-out-animation 0.25s linear forwards;
}
.vikinglotto-classic-game .rows-list-box .button {
  width: 10.5vw;
  height: 10.5vw;
  background-color: rgba(235, 248, 253, 0.8);
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 0 6px 6px 0;
  overflow: hidden;
  -webkit-transform: translateY(-30vw) translateX(-10.5vw);
  -moz-transform: translateY(-30vw) translateX(-10.5vw);
  -ms-transform: translateY(-30vw) translateX(-10.5vw);
  -o-transform: translateY(-30vw) translateX(-10.5vw);
  transform: translateY(-30vw) translateX(-10.5vw);
  -webkit-transition: -webkit-transform 0.15s, background-color 0.1s;
  -moz-transition: -moz-transform 0.15s, background-color 0.1s;
  -ms-transition: -ms-transform 0.15s, background-color 0.1s;
  -o-transition: -o-transform 0.15s, background-color 0.1s;
  transition: transform 0.15s, background-color 0.1s;
  z-index: 1;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .button {
    width: 4.2rem;
    height: 7rem;
    -webkit-transform: translateY(-16rem) translateX(-4.2vw);
    -moz-transform: translateY(-16rem) translateX(-4.2vw);
    -ms-transform: translateY(-16rem) translateX(-4.2vw);
    -o-transform: translateY(-16rem) translateX(-4.2vw);
    transform: translateY(-16rem) translateX(-4.2vw);
    cursor: pointer;
  }
}
.vikinglotto-classic-game .rows-list-box .button .icon-list {
  width: 3.8vw;
  height: 4vw;
  fill: #1d1d1d;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-1.6vw);
  -moz-transform: translateY(-50%) translateX(-1.6vw);
  -ms-transform: translateY(-50%) translateX(-1.6vw);
  -o-transform: translateY(-50%) translateX(-1.6vw);
  transform: translateY(-50%) translateX(-1.6vw);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -webkit-transition: -webkit-transform 0.25s, opacity 0.25s;
  -moz-transition: -moz-transform 0.25s, opacity 0.25s;
  -ms-transition: -ms-transform 0.25s, opacity 0.25s;
  -o-transition: -o-transform 0.25s, opacity 0.25s;
  transition: transform 0.25s, opacity 0.25s;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .button .icon-list {
    width: 1.5rem;
    height: 1.2rem;
    -webkit-transform: translateY(-50%) translateX(-0.7rem);
    -moz-transform: translateY(-50%) translateX(-0.7rem);
    -ms-transform: translateY(-50%) translateX(-0.7rem);
    -o-transform: translateY(-50%) translateX(-0.7rem);
    transform: translateY(-50%) translateX(-0.7rem);
  }
}
.vikinglotto-classic-game .rows-list-box .button .icon-arrow-left {
  width: 3.8vw;
  height: 3.47vw;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(7vw);
  -moz-transform: translateY(-50%) translateX(7vw);
  -ms-transform: translateY(-50%) translateX(7vw);
  -o-transform: translateY(-50%) translateX(7vw);
  transform: translateY(-50%) translateX(7vw);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.25s, opacity 0.25s;
  -moz-transition: -moz-transform 0.25s, opacity 0.25s;
  -ms-transition: -ms-transform 0.25s, opacity 0.25s;
  -o-transition: -o-transform 0.25s, opacity 0.25s;
  transition: transform 0.25s, opacity 0.25s;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .button .icon-arrow-left {
    width: 1.5rem;
    height: 1.2rem;
    -webkit-transform: translateY(-50%) translateX(-0.7rem);
    -moz-transform: translateY(-50%) translateX(-0.7rem);
    -ms-transform: translateY(-50%) translateX(-0.7rem);
    -o-transform: translateY(-50%) translateX(-0.7rem);
    transform: translateY(-50%) translateX(-0.7rem);
  }
}
.vikinglotto-classic-game .rows-list-box .button.active {
  -webkit-transform: translateY(-30vw) translateX(-1.5vw);
  -moz-transform: translateY(-30vw) translateX(-1.5vw);
  -ms-transform: translateY(-30vw) translateX(-1.5vw);
  -o-transform: translateY(-30vw) translateX(-1.5vw);
  transform: translateY(-30vw) translateX(-1.5vw);
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box .button.active {
    -webkit-transform: translateY(-16rem) translateX(0);
    -moz-transform: translateY(-16rem) translateX(0);
    -ms-transform: translateY(-16rem) translateX(0);
    -o-transform: translateY(-16rem) translateX(0);
    transform: translateY(-16rem) translateX(0);
  }
}
.vikinglotto-classic-game .rows-list-box.active .container {
  transform: translate3d(0, 0, 0);
}
.vikinglotto-classic-game .rows-list-box.active .container .informations-box,
.vikinglotto-classic-game .rows-list-box.active .container > .headline {
  background-color: #ffffff;
}
.vikinglotto-classic-game .rows-list-box.active .container.single-row {
  transform: translate3d(0, 30vw, 0);
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box.active .container.single-row {
    transform: translate3d(0, 8rem, 0);
  }
}
.vikinglotto-classic-game .rows-list-box.active .button {
  background-color: #ffffff;
  -webkit-transform: translateY(-30vw) translateX(85.6vw);
  -moz-transform: translateY(-30vw) translateX(85.6vw);
  -ms-transform: translateY(-30vw) translateX(85.6vw);
  -o-transform: translateY(-30vw) translateX(85.6vw);
  transform: translateY(-30vw) translateX(85.6vw);
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box.active .button {
    -webkit-transform: translateY(-16rem) translateX(48.8rem);
    -moz-transform: translateY(-16rem) translateX(48.8rem);
    -ms-transform: translateY(-16rem) translateX(48.8rem);
    -o-transform: translateY(-16rem) translateX(48.8rem);
    transform: translateY(-16rem) translateX(48.8rem);
  }
}
.vikinglotto-classic-game .rows-list-box.active .button .icon-list {
  -webkit-transform: translateY(-50%) translateX(-11vw);
  -moz-transform: translateY(-50%) translateX(-11vw);
  -ms-transform: translateY(-50%) translateX(-11vw);
  -o-transform: translateY(-50%) translateX(-11vw);
  transform: translateY(-50%) translateX(-11vw);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
}
.vikinglotto-classic-game .rows-list-box.active .button .icon-arrow-left {
  -webkit-transform: translateY(-50%) translateX(-1.6vw);
  -moz-transform: translateY(-50%) translateX(-1.6vw);
  -ms-transform: translateY(-50%) translateX(-1.6vw);
  -o-transform: translateY(-50%) translateX(-1.6vw);
  transform: translateY(-50%) translateX(-1.6vw);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .rows-list-box.active .button .icon-arrow-left {
    -webkit-transform: translateY(-50%) translateX(-0.3rem);
    -moz-transform: translateY(-50%) translateX(-0.3rem);
    -ms-transform: translateY(-50%) translateX(-0.3rem);
    -o-transform: translateY(-50%) translateX(-0.3rem);
    transform: translateY(-50%) translateX(-0.3rem);
  }
}
@media (min-width: 950px) {
  .vikinglotto-classic-game .vikinglotto-content-wrapper--version-2 .rows-container {
    height: 46.8rem;
  }
}
