// bring in mixins  
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

.vikinglotto-system-game, .vikinglotto-lucky-game {
  .vikinglotto-system-game-section, .vikinglotto-lucky-game-section {
    .vikinglotto-content-wrapper {
      padding:0;
      .number-picker-footer {
        padding:4.034vw 0 0;
        @media (min-width: @vikinglotto-desktop-width) {
          padding:1.8rem 0 2.4rem;
        }
      }
      .rows-container {
        width: 84.535%;
        margin:auto;
        position:relative;
        margin-bottom:4vw;
        @media (min-width: @vikinglotto-desktop-width) {
          width: 58rem;
          padding-top:2.5rem;
          margin-bottom:0rem;
        }
        &:before {
          @media (min-width: @vikinglotto-desktop-width) {
            //content:'';
            position:absolute;
            bottom:9.4rem;
            left:-50vw;
            right:-50vw;
            background-color:@vikinglotto-white;
            height:2px;
          }
        }

        .row-container {
          padding-bottom:0vw;
          @media (min-width: @vikinglotto-desktop-width) {
            padding-bottom:.6rem;
          }

          .row-header {
            padding-top:3.3vw;
            padding-bottom:1.9vw;
            margin:0 0 2.6vw;
            @media (min-width: @vikinglotto-desktop-width) {
              padding-top:0;
              padding-bottom:2.5rem;
              margin:0 0 2rem;
            }

            .number-of-rows {
              display:block;
              @media (min-width: @vikinglotto-desktop-width) {
                padding-left:1vw;
                display:inline-block;
              }

              opacity:.6;
            }
          }

          .number-picker-container {
            .number-picker-wrapper {
              border-bottom:none;
            }
          }

          .counter-container {
            position:absolute;

            .counter-box {
              font-size:3.733vw;
              padding:.1vw 0 1vw;
              display:block;
              clear:both;
              @media (min-width: @vikinglotto-desktop-width) {
                font-size:1.4rem;
                padding:.1rem 0 .5rem;
              }
              .mobile-hide {
                @media (min-width: @vikinglotto-desktop-width) {
                  display:inline;
                }
              }
              .mobile-only {
                display:inline;
                @media (min-width: @vikinglotto-desktop-width) {
                  display:none;
                }
              }

            }
          }

        }
      }
    }
  }
}


.vikinglotto-lucky-game {
  .vikinglotto-lucky-game-section {
    .vikinglotto-content-wrapper {
      .rows-container {
        .row-container {
          .row-header {
            padding-top:5.05vw;
            padding-bottom:4.55vw;
            @media (min-width: @vikinglotto-desktop-width) {
              padding-top: 0;
              padding-bottom: 2.5rem;
            }
          }
        }
      }
      .number-picker-footer {
        .buttons-container {
          .reset-row-button {
            float:inherit;
          }
        }
      }
    }
  }
}