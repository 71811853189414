﻿// bring in mixins  
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

@vikinglotto-confirm-bar-height: 16.3vw;
@vikinglotto-confirm-bar-border-color: #cdd3d7;

.confirm-bar-wrapper {
  width: 100%;
  height: @vikinglotto-confirm-bar-height;
  position: relative;
  z-index: 4;

  .confirm-bar {
    width: 100%;
    height: @vikinglotto-confirm-bar-height;
    position: relative;
    font-size: 3.2vw;
    font-weight: bold;

    &:before,
    &:after {
      content: "";
      width: calc(~'(100% - 96rem) / 2');
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: rgba(255,255,255,0.95);
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    .confirm-bar-content {
      position: relative;
      text-align: left;
      background-color: inherit;
      width: 100%;
      max-width: 96rem;
      height: inherit;
      margin: auto;
      display: table;
      table-layout: fixed;
      line-height: 7rem;

      a {
        line-height: normal;
        display: block;
      }


      > div {
        width: 50%;
        height: inherit;
        display: table-cell;
        cursor: pointer;
        vertical-align: middle;
        position: relative;
        border-left: .1rem solid @vikinglotto-confirm-bar-border-color; // fix color mixin?
        box-sizing: border-box;
        .user-select(none);

        span {
          display: none;
        }

        &.confirm-bar-back {
          color: @vikinglotto-black;
          text-align: center;
          background-color: rgba(255,255,255,0.95);
          .transition(background-color 300ms linear);

          &:hover {
            background-color: rgba(255,255,255,1);
          }
        }

        &.confirm-bar-cta {
          width: 50%;
          text-align: center;
          color: @vikinglotto-black;
          border-left: none;
          background-color: @vikinglotto-cta-yellow;
          .transition(background-color 300ms linear);

          &:hover {
            background-color: @vikinglotto-cta-yellow-hover;
          }

          .total-price {
            transition: opacity .25s, transform .25s;
            .opacity(1);
          }

          &.is-inactive {
            &:hover {
              background-color: red;
            }

            cursor: default;

            .total-price {
              .opacity(.3);
            }
          }

          .confirm {
            .purchase-button-loader-box {
              width: 100%;
              position: absolute;
              transform: translateY(-200%) scale(.8);
              opacity: 0;
              transition: transform .25s, opacity .15s;

              .loader-text {
                display: inline-block;
              }

              .loader-animation-box {
                margin-left: .5rem;
                display: inline-block;

                @keyframes vikinglotto-loader-dot-animation {
                  0%, 100% {
                    transform: translateY(0);
                  }

                  50% {
                    transform: translateY(-100%);
                  }
                }

                .loader-dot {
                  width: 1.2vw;
                  height: 1.2vw;
                  margin-left: .3rem;
                  border-radius: 50%;
                  background-color: #000; //@lotto-darkgray;
                  display: inline-block;

                  @media (min-width: @vikinglotto-desktop-width) {
                    width: .8rem;
                    height: .8rem;
                  }
                }
              }
            }
          }

          &.processing, .status-pending-confirmation & {
            cursor: none;

            .confirm {
              .purchase-button-loader-box {
                transform: translateY(0) scale(1);
                opacity: 1;

                .loader-animation-box {
                  .loader-dot {
                    &:nth-child(1n) {
                      animation: vikinglotto-loader-dot-animation .5s ease-in-out -.66s infinite;
                    }

                    &:nth-child(2n) {
                      animation: vikinglotto-loader-dot-animation .5s ease-in-out -.33s infinite;
                    }

                    &:nth-child(3n) {
                      animation: vikinglotto-loader-dot-animation .5s ease-in-out -1s infinite;
                    }
                  }
                }
              }

              .total-price {
                transform: translateY(100%) scale(.8);
                opacity: .6;
              }
            }
          }
        }
      }
    }
  }

  &.fixed {
    .confirm-bar {
      position: fixed;
      bottom: 0;
    }
  }
  // Desktop
  @media (min-width: @vikinglotto-desktop-width) {
    @vikinglotto-confirm-bar-height: 7rem;
    height: @vikinglotto-confirm-bar-height;

    &.fixed {
      bottom: 3rem;
    }

    .confirm-bar {
      font-size: 1.4rem; // hack hack hack
      height: @vikinglotto-confirm-bar-height;

      .confirm-bar-content {
        height: 100%;

        > div {
          width: auto;

          span {
            display: inline;
          }

          &.confirm-bar-cta {
            width: auto;
          }
        }
      }
    }
  }
}
