.vikinglotto-top-spot .btn {
  margin-top: 1rem;
}
.vikinglotto-top-spot h1.hd.xxlarge {
  min-height: 1em;
  min-width: 1em;
}
.vikinglotto-top-spot h3.hd.large {
  min-height: 1em;
  min-width: 1em;
  margin-top: 11vw;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot h3.hd.large {
    margin-top: 6rem;
  }
}
.vikinglotto-top-spot small.faq {
  padding-top: 2vw;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot small.faq {
    padding-top: 2rem;
  }
}
.vikinglotto-top-spot .vikinglotto__curved-bg {
  margin-bottom: -6.8rem;
}
.vikinglotto-top-spot .vikinglotto__curved-bg--mosaik {
  z-index: 1;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto__curved-bg {
    margin-bottom: -4.8rem;
  }
}
.vikinglotto-top-spot--dark-blue .vikinglotto__curved-bg {
  background-color: #020B75;
}
.vikinglotto-top-spot--dark-blue .vikinglotto-content-wrapper {
  color: #a8e1fc;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot--dark-blue .vikinglotto-section .vikinglotto-color-toggle .vikinglotto-smile {
    fill: #a8e1fc;
  }
}
.vikinglotto-top-spot--babyblue .vikinglotto__curved-bg {
  background-color: #a8e1fc;
}
.vikinglotto-top-spot--babyblue .vikinglotto-content-wrapper {
  color: #020B75;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot--babyblue .vikinglotto-section .vikinglotto-color-toggle .vikinglotto-smile {
    fill: #020B75;
    transition-duration: 0.3s;
    transition-property: fill;
  }
  .vikinglotto-top-spot--babyblue .vikinglotto-section .vikinglotto-color-toggle:hover .vikinglotto-smile {
    fill: #a8e1fc;
  }
}
.vikinglotto-top-spot--pink .vikinglotto__curved-bg {
  background-color: #ff7387;
}
.vikinglotto-top-spot--pink .vikinglotto-content-wrapper {
  color: #640096;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot--pink .vikinglotto-section .vikinglotto-color-toggle .vikinglotto-smile {
    fill: #640096;
    transition-duration: 0.3s;
    transition-property: fill;
  }
  .vikinglotto-top-spot--pink .vikinglotto-section .vikinglotto-color-toggle:hover .vikinglotto-smile {
    fill: #a8e1fc;
  }
}
.vikinglotto-top-spot--purple .vikinglotto__curved-bg {
  background-color: #640096;
}
.vikinglotto-top-spot--purple .vikinglotto-content-wrapper {
  color: #ff7387;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot--purple .vikinglotto-section .vikinglotto-color-toggle .vikinglotto-smile {
    fill: #ff7387;
    transition-duration: 0.3s;
    transition-property: fill;
  }
  .vikinglotto-top-spot--purple .vikinglotto-section .vikinglotto-color-toggle:hover .vikinglotto-smile {
    fill: #a8e1fc;
  }
}
.vikinglotto-top-spot--yellow .vikinglotto__curved-bg {
  background-color: #fcff8a;
}
.vikinglotto-top-spot--yellow .vikinglotto-content-wrapper {
  color: #ff7387;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot--yellow .vikinglotto-section .vikinglotto-color-toggle .vikinglotto-smile {
    fill: #ff7387;
    transition-duration: 0.3s;
    transition-property: fill;
  }
  .vikinglotto-top-spot--yellow .vikinglotto-section .vikinglotto-color-toggle:hover .vikinglotto-smile {
    fill: #a8e1fc;
  }
}
.vikinglotto-top-spot .vikinglotto-section {
  padding: 0;
  position: relative;
  box-sizing: border-box;
  min-height: 66vw;
  background-color: transparent;
}
.vikinglotto-top-spot .vikinglotto-section .vikinglotto-color-toggle {
  width: 10.5rem;
  height: 10.5rem;
  display: inline;
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10rem;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto-section .vikinglotto-color-toggle {
    bottom: -8rem;
  }
}
.vikinglotto-top-spot .vikinglotto-section .vikinglotto-color-toggle .vikinglotto-color-toggle-background {
  width: 11.5rem;
  height: 11.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #020B75;
  cursor: pointer;
}
.vikinglotto-top-spot .vikinglotto-section .vikinglotto-color-toggle .vikinglotto-color-toggle-background:after {
  content: "";
  position: absolute;
  width: 13rem;
  height: 13rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: box-shadow background-color;
}
.vikinglotto-top-spot .vikinglotto-section .vikinglotto-color-toggle .vikinglotto-color-toggle-background:hover:after {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.6);
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto-section .vikinglotto-color-toggle .vikinglotto-color-toggle-background:hover:after {
    background-color: #020B75;
  }
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto-section .vikinglotto-color-toggle .vikinglotto-color-toggle-background {
    background-color: transparent;
  }
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto-section .vikinglotto-color-toggle {
    width: 11.5rem;
    height: 11.5rem;
    left: 85%;
    bottom: 7.5rem;
  }
}
.vikinglotto-top-spot .vikinglotto-section .vikinglotto-smile {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  fill: #fff;
}
.vikinglotto-top-spot .vikinglotto-section .vikinglotto-smile.vikinglotto-smile--spin {
  animation: spin 20s linear infinite;
  transform-origin: center;
  font-size: 12px;
  top: 0.1rem;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto-section .vikinglotto-smile.vikinglotto-smile--spin {
    width: 12rem;
    height: 12rem;
    top: -0.3rem;
    left: -0.1rem;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto-section {
    min-height: 25rem;
  }
}
.vikinglotto-top-spot .vikinglotto-section .vikinglotto-content-wrapper {
  padding: 3vw 0;
}
.vikinglotto-top-spot .vikinglotto-section .vikinglotto-content-wrapper > * {
  position: relative;
  z-index: 2;
}
.vikinglotto-top-spot .vikinglotto-section .vikinglotto-content-wrapper .vikinglotto__flipclock-cta-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}
.vikinglotto-top-spot .vikinglotto-section .vikinglotto-content-wrapper .vikinglotto__flipclock-cta-wrapper .btn {
  margin: 2rem 0;
}
.vikinglotto-top-spot .vikinglotto-section #vikinglotto-winning-number-subheader {
  text-transform: none;
  margin: 0 auto 14vw;
  font-weight: 100;
  font-size: 4vw;
  width: 60vw;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto-section #vikinglotto-winning-number-subheader {
    /*font-size: 16vw;
margin: 4rem auto;*/
    margin: 0 auto 6rem;
    font-size: 3rem;
  }
}
.vikinglotto-top-spot .vikinglotto-section.faq {
  font-size: 12.9vw;
  line-height: 12.9vw;
  margin: 3vw auto 0;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto-section.faq {
    font-size: 7rem;
    margin: 8rem auto 0;
    line-height: 7rem;
  }
}
.vikinglotto-top-spot .help-link {
  text-decoration: none;
  border-bottom: 0.1rem solid #fff;
  font-size: 3.7vw;
  line-height: 4.2vw;
  margin: 2vw 0;
  display: inline-block;
}
.vikinglotto-top-spot .help-link:hover {
  border-color: transparent;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .help-link {
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    margin-top: 4.5rem;
    font-size: 1.6rem;
  }
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto-logo-wrapper {
    display: block;
    margin-bottom: -3rem;
  }
}
.vikinglotto-top-spot .vikinglotto-logo {
  width: 33vw;
  margin: 0 auto 4vw;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto-logo {
    width: 12.6rem;
    margin: 0 auto 1rem;
  }
}
.vikinglotto-top-spot .vikinglotto-logo img {
  width: 100%;
  height: auto;
}
.vikinglotto-top-spot.faq-top-spot .vikinglotto-section .vikinglotto-content-wrapper {
  padding: 9vw 0 5vw;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot.faq-top-spot .vikinglotto-section .vikinglotto-content-wrapper {
    padding: 8rem 0 4rem;
  }
}
.vikinglotto-top-spot.faq-top-spot .vikinglotto-section .vikinglotto-content-wrapper .vikinglotto-logo-wrapper .vikinglotto-logo {
  width: 17.3vw;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot.faq-top-spot .vikinglotto-section .vikinglotto-content-wrapper .vikinglotto-logo-wrapper .vikinglotto-logo {
    width: 6.5rem;
  }
}
.vikinglotto-top-spot.faq-top-spot .vikinglotto-section .vikinglotto-content-wrapper .hd.faq {
  font-size: 9.3vw;
  margin: -4vw auto 5vw;
  line-height: 1;
  font-weight: 900;
  font-style: italic;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot.faq-top-spot .vikinglotto-section .vikinglotto-content-wrapper .hd.faq {
    font-size: 5rem;
    margin: 3.7rem auto 7.1rem;
  }
}
.vikinglotto-top-spot.faq-top-spot .vikinglotto-section .vikinglotto-content-wrapper .hd.faq .faq {
  font-size: 4.6vw;
  margin-top: -0.5vw;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot.faq-top-spot .vikinglotto-section .vikinglotto-content-wrapper .hd.faq .faq {
    font-size: 2.6rem;
    font-weight: 900;
    margin-top: -0.9rem;
  }
}
.vikinglotto-top-spot.faq-top-spot .vikinglotto-section .vikinglotto-content-wrapper #vikinglotto-winning-number-subheader {
  margin: 0 auto -1.5vw;
}
.vikinglotto-top-spot.faq-top-spot + .informationswitcherspotview {
  padding: 8vw 0 0;
  border-color: #fff;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot.faq-top-spot + .informationswitcherspotview {
    padding: 11rem 0;
  }
}
.vikinglotto-top-spot.faq-top-spot + .informationswitcherspotview .informationswitcher-selector {
  margin-top: 9vw;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot.faq-top-spot + .informationswitcherspotview .informationswitcher-selector {
    margin-top: 0;
  }
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tiles {
  display: flex;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile {
  position: relative;
  height: 45vw;
  flex-grow: 1;
  display: none;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile#vikinglotto-mosaik-tile-4 {
  height: 66vw;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile#vikinglotto-mosaik-tile-4 {
    height: inherit;
  }
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile#vikinglotto-mosaik-tile-4 .vikinglotto-mosaik__tile-graphics {
  top: unset;
  bottom: 32%;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile#vikinglotto-mosaik-tile-4 .vikinglotto-mosaik__tile-graphics {
    top: 55%;
    bottom: unset;
  }
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile::after {
    content: none;
  }
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile .vikinglotto-mosaik__tile-graphics {
  transition: opacity 750ms;
  transition: opacity var(--fade-timer);
  opacity: 0;
  position: absolute;
  top: 55%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  display: none;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile .vikinglotto-mosaik__tile-graphics--mobile {
  display: block;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile .vikinglotto-mosaik__tile-graphics {
    display: block;
  }
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile .vikinglotto-mosaik__tile-graphics--show {
  opacity: 1;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile .vikinglotto-mosaik__tile-svg,
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile .vikinglotto-mosaik__tile-image {
  width: 70%;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile .vikinglotto-mosaik__tile-static-spot {
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile .vikinglotto-mosaik__tile-static-spot img {
  cursor: pointer;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile--mobile {
  display: block;
}
@media (min-width: 950px) {
  .vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile {
    display: block;
    height: 14.285vw;
  }
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile--grow2 {
  flex-grow: 2;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile--dark-blue {
  background-color: #020B75;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile--dark-blue .vikinglotto-mosaik__tile-svg {
  fill: #a8e1fc;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile--pink {
  background-color: #ff7387;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile--pink .vikinglotto-mosaik__tile-svg {
  fill: #640096;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile--yellow {
  background-color: #fcff8a;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile--yellow .vikinglotto-mosaik__tile-svg {
  fill: #ff7387;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile--yellow.vikinglotto-mosaik__tile::after {
  background: url("/Components/DanskeSpil/Domain/VikingLotto/Graphics/mosaik-smile_face.png") no-repeat center;
  background-size: 50%;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile--purple {
  background-color: #640096;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile--purple .vikinglotto-mosaik__tile-svg {
  fill: #ff7387;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile--purple.vikinglotto-mosaik__tile::after {
  background: url("/Components/DanskeSpil/Domain/VikingLotto/Graphics/mosaik-vk-spot.png") no-repeat center;
  background-size: 80%;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile--babyblue {
  background-color: #a8e1fc;
}
.vikinglotto-top-spot .vikinglotto-mosaik .vikinglotto-mosaik__tile--babyblue .vikinglotto-mosaik__tile-svg {
  fill: #020B75;
}
