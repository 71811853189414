@import url("../../Mixins/_mixins.less");

.region-vikinglotto {

  .informationswitcherspotview {
    padding: 10rem 0;

    .informationspot {

      .richtext {
        color: @numbergames-darkgray;
      }

      .information-spot-headline {
        max-width: 86.5vw;
        font-size: 9vw;
        font-weight: 800;
        line-height: 9vw;
        text-transform: uppercase;
        color: @vikinglotto-darkblue;
        padding-bottom: 2.666vw;
        margin: 1vw auto;


        @media(min-width:@desktop-width) {
          font-size: 3.8rem;
          line-height: 3rem;
          padding-bottom: 3rem;
          margin: 1rem auto;
        }
      }

      h3 {
        font-weight: 800;
        color: @vikinglotto-darkblue;
      }
    }

    table {
      border: 0.1rem solid fade(@vikinglotto-lightgray, 40%);

      td, th {
        color: @numbergames-extra-lightgray;
        border: 0.1rem solid fade(@vikinglotto-lightgray, 40%);
      }

      tr {
        &:nth-child(even) {
          background-color: @vikinglotto-whitesmoke;
        }
      }

      thead {
        th {
          color: @numbergames-white;
          background-color: @vikinglotto-blue;
        }
      }
    }
  }
}
