// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

// Variables
@cell-width--tablet: calc(~'16.666% - 16px');
@cell-height--tablet: 5.5vw;

.vikinglotto-lucky-game {
  .number-picker-footer {
    .buttons-container {
      text-align: center;
    }
  }
}

.vikinglotto-classic-game, .vikinglotto-system-game, .vikinglotto-lucky-game, .vikinglotto-winning-numbers {
  .number-picker-footer {
    padding: 4.034vw 0 2.134vw;
    position: relative;
    margin: 0 1.9%;
    border-top: 1px solid @vikinglotto-white;
    .clearfix();

    @media (min-width: @vikinglotto-desktop-width) {
      padding: 3rem 1rem 1.1rem;
      text-align: center;
      margin: 0 1rem;
    }

    .buttons-container {
      white-space: nowrap;

      .button {
        min-width: 23.2vw;
        font-size: 3.734vw;
        line-height: 1.36;
        font-weight: bold;
        color: @vikinglotto-darkgray;
        text-align: left;
        background-color: #fff;
        margin-left: 3.334vw;
        padding: 3.05vw 3.6vw 2.55vw;
        border-radius: 6px;
        display: inline-block;
        text-transform: uppercase;
        .box-sizing();
        position: relative;
        transition: opacity .25s, background-color .25s, color .25s, transform .25s;
        user-select: none;
        overflow: hidden;
        will-change: transform;

        &:hover {
          @media (min-width: @vikinglotto-desktop-width) {
            transform: scale(1.05);
          }
        }

        @media (min-width: @vikinglotto-desktop-width) {
          min-width: initial;
          min-width: inherit;
          font-size: 1.6rem;
          margin-left: 2rem;
          padding: 1.3rem 1.8rem 1.1rem 1.8rem;
          cursor: pointer;
        }

        @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
          font-size: 1.7rem;
          text-align: center;
        }

        &:first-child {
          margin-left: 0;
          float: left;

          @media (min-width: @vikinglotto-desktop-width) {
            float: inherit;
          }
        }

        &.inactive {
          .opacity(.3);
          cursor: default;

          &:hover {
            @media (min-width: @vikinglotto-desktop-width) {
              transform: scale(1);
            }
          }
        }

        &.autogenerating-numbers {
          opacity: .3;

          @keyframes vikinglotto-autogenerate-button-animation {
            0% {
              transform: translate(-50%, -50%) scale(0);
              opacity: 0;
            }

            50% {
              opacity: .5;
            }

            100% {
              transform: translate(-50%, -50%) scale(1.5);
              opacity: 0;
            }
          }

          &:before, &:after {
            content: '';
            width: 100%;
            padding-top: 100%;
            border-radius: 50%;
            background: radial-gradient(center, ellipse cover, rgba(43, 43, 43,0) 0%, rgba(43, 43, 43,0) 20%, rgba(43, 43, 43,0.65) 45%, rgba(43, 43, 43,0.9) 55%, rgba(43, 43, 43,0.5) 65%, rgba(43, 43, 43,0) 90%, rgba(43, 43, 43,0) 100%);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) scale(0);
          }

          &:before {
            animation: vikinglotto-autogenerate-button-animation .8s linear infinite;
          }

          &:after {
            animation: vikinglotto-autogenerate-button-animation .8s .3s linear infinite;
          }

          &:hover {
            cursor: default;

            @media (min-width: @vikinglotto-desktop-width) {
              transform: scale(1);
            }
          }
        }

        &.reset-row-button {
          float: right;

          @media (min-width: @vikinglotto-desktop-width) {
            float: none;
          }
        }
      }

      .autogenerate-button .icon, .reset-row-button .icon {
        width: 2.934vw;
        height: 3.7vw;
        fill: #4f5254;
        position: absolute;
        top: 3.4vw;
        right: 3.7vw;
        .transition(fill .25s);
        overflow: visible;

        @media (min-width: @vikinglotto-desktop-width) {
          width: 1.6rem;
          height: 1.6rem;
          top: 1.1rem;
          right: 1.4rem;
        }
      }
    }
  }

  .row-container {
    .keyframes(vikinglotto-number-cell-shake-animation; {
      0% {
        .transform(scale(1) rotate(0));
      }

      10% {
        .transform(scale(1.13) rotate(5deg));
      }

      20% {
        .transform(scale(1.13) rotate(-5deg));
      }

      30% {
        .transform(scale(1.13) rotate(5deg));
      }

      40% {
        .transform(scale(1.13) rotate(-5deg));
      }

      50% {
        .transform(scale(1.13) rotate(5deg));
      }

      60% {
        .transform(scale(1.13) rotate(-5deg));
      }

      70% {
        .transform(scale(1.13) rotate(5deg));
      }

      80% {
        .transform(scale(1.13) rotate(-5deg));
      }

      90% {
        .transform(scale(1.13) rotate(5deg));
      }

      100% {
        .transform(scale(1) rotate(0));
      }
    }

  );

    .keyframes(vikinglotto-rules-pulse-animation; {
      0% {
        .transform(scale(1));
      }

      25% {
        .transform(scale(1.13));
      }

      50% {
        .transform(scale(1));
      }

      75% {
        .transform(scale(1.13));
      }

      100% {
        .transform(scale(1));
      }
    }

  );

    &.shake-state, &.shake-viking-numbers-state {
      .header-rules-text {
        .animation(vikinglotto-rules-pulse-animation .8s forwards);
      }

      .row-header {
        .tab-game-rules-text {
          display: inline-block;
          animation: vikinglotto-rules-pulse-animation .8s forwards;
        }
      }
    }

    &.shake-state {
      .cells-container {
        .selected {
          .animation(vikinglotto-number-cell-shake-animation .8s forwards);
        }
      }
    }

    &.shake-viking-numbers-state {
      .row-vikings-container {
        .selected {
          .animation(vikinglotto-number-cell-shake-animation .6s forwards);
        }
      }
    }
  }

  .row-header {
    font-size: 3.466vw;
    line-height: 1.3;
    color: #fff;
    padding-bottom: 2.134vw;
    border-bottom: 1px solid @vikinglotto-white;
    margin: 0 1.4vw 2.6vw;
    .clearfix();

    @media (min-width: @vikinglotto-desktop-width) {
      font-size: 1.6rem;
      padding-bottom: 2.5rem;
      margin: 0 1rem 2rem;
    }

    @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
      font-size: 2rem;
    }

    .counter {
      width: 88%;
      text-align: center;
      float: left;
      opacity: .6;

      @media (min-width: @vikinglotto-desktop-width) {
        width: auto;
        padding-right: 2rem;
        text-align: left;
        float: inherit;
      }
    }

    .right-side {
      padding-right: 2.667vw;
      position: relative;

      @media (min-width: @vikinglotto-desktop-width) {
        padding-right: 1.5rem;
        display: inline-block;
        position: static;
      }

      .row-delete-button {
        display: block;
        position: absolute;
        top: 2.65vw;
        right: 2.1vw;
        cursor: pointer;
        transition: opacity .25s, transform .15s;

        &:hover {
          @media (min-width: @vikinglotto-desktop-width) {
            transform: scale(1.4);
          }
        }

        @media (min-width: @vikinglotto-desktop-width) {
          top: .1rem;
          right: 1rem;
        }

        .icon {
          width: 2.667vw;
          height: 2.667vw;
          fill: #fff;

          @media (min-width: @vikinglotto-desktop-width) {
            width: 1.2rem;
            height: 1.2rem;
          }
        }

        &.inactive {
          .opacity(.3);
          cursor: default;

          &:hover {
            @media (min-width: @vikinglotto-desktop-width) {
              transform: scale(1);
            }
          }
        }
      }
    }

    .header-rules-text {
      width: 90%;
      color: rgba(255,255,255,1);
      display: inline-block;
      text-align: center;

      @media (min-width: @vikinglotto-desktop-width) {
        width: 100%;
        text-align: left
      }
    }
  }

  .number-picker-wrapper {
    position: relative;
    padding-bottom: 2.7vw;

    @media (min-width: @vikinglotto-desktop-width) {
      padding-bottom: 0;
    }

    @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
      display: flex;
    }
  }

  .number-picker-container {
    margin: 0 -1.9%;
    position: relative;
    white-space: normal;
    transform: translateZ(0);
    -webkit-touch-callout: none;
    .user-select(none);

    @media (min-width: @vikinglotto-desktop-width) {
      margin: 0 -1rem;
    }

    .cells-container {
      width: 67.068vw; //61.068vw;
      position: relative;
      display: inline-block;
      box-sizing: border-box;

      @media (min-width: @vikinglotto-desktop-width) {
        width: 80.2rem;
        margin: 0 0 2rem 0;
        padding: 0 1rem 0 0;
      }

      @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
        width: 85%;
      }
    }

    .cell {
      width: 8.4vw; //9.868vw;
      height: 8.4vw; //9.868vw;
      text-align: center;
      position: relative;
      display: inline-block;
      margin: 1.356vw;
      border-radius: 50%;

      @media (min-width: @vikinglotto-desktop-width) {
        width: 4.6rem;
        height: 0;
        padding-top: 4.6rem;
        margin: 1rem;
        cursor: pointer;
      }

      @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
        width: @cell-width--tablet;
        height: @cell-height--tablet;
        margin: 8px;
      }

      &:after {
        content: '';
        position: absolute;
        top: -1vw;
        left: -1vw;
        right: -1vw;
        bottom: -1vw;

        @media (min-width: @vikinglotto-desktop-width) {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      &:hover {
        .back-face, .front-face {
          @media (min-width: @vikinglotto-desktop-width) {
            transform: scale(1.26);
          }
        }
      }

      .back-face, .front-face {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        color: @vikinglotto-darkgray;
        transition: transform .1s linear;
        will-change: transform;

        .number {
          width: 8.4vw; //9.868vw;
          height: 8.4vw; //9.868vw;
          position: absolute;

          @media (min-width: @vikinglotto-desktop-width) {
            width: 4.6rem;
            height: 4.6rem;
          }

          @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
            width: 100%;
            height: 100%;
          }
        }
      }

      .front-face {
        width: 100%;
        height: 100%;
        background-color: @vikinglotto-white;
      }

      .back-face {
        width: 0;
        height: 0;
        background-color: @vikinglotto-yellow;
      }

      .number {
        width: 8.4vw; //9.868vw;
        height: 8.4vw; //9.868vw;
        font-size: 4vw;
        line-height: 8.4vw; //2.7;
        //text-indent:.1rem;
        border-radius: 50%;
        background-color: inherit;
        position: absolute;
        top: 50%;
        left: 50%;
        overflow: hidden;
        transform: translate(-50%,-50%);
        -webkit-font-smoothing: antialiased;

        @media (min-width: @vikinglotto-desktop-width) {
          width: 4.6rem;
          height: 4.6rem;
          font-size: 1.8rem;
          line-height: 4.6rem;
        }

        @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
          font-size: 2.5vw;
          line-height: @cell-height--tablet;
        }
      }

      @vikinglotto-flip-animation-duration: .3s;
      @vikinglotto-flip-animation-function: linear;

      &.flip-animation-in-progress {
        transform: translateZ(0);

        @keyframes vikinglotto-select-face-animation {
          0% {
            width: 100%;
            height: 100%;
          }

          99.999% {
            width: 100%;
            height: 100%;
          }

          100% {
            width: 0;
            height: 0;
          }
        }

        @keyframes vikinglotto-select-back-animation {
          0% {
            width: 0;
            height: 0;
            top: 50%;
            left: 50%;
            margin: 0;
            z-index: 1;
          }

          60% {
            width: 100%;
            height: 100%;
            margin: -50%;
          }

          70% {
            width: 140%;
            height: 140%;
            margin: -70%;
          }

          100% {
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            margin: -50%;
          }
        }

        @keyframes vikinglotto-select-back-number-animation {
          0% {
            transform: translate(-50%,-50%) scale(1);
          }

          50% {
            transform: translate(-50%,-50%) scale(1);
          }

          70% {
            transform: translate(-50%,-50%) scale(.769);
          }

          100% {
            transform: translate(-50%,-50%) scale(1);
          }
        }

        @keyframes vikinglotto-deselect-face-animation {
          0% {
            width: 0;
            height: 0;
            top: 50%;
            left: 50%;
            margin: 0;
            z-index: 1;
          }

          100% {
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            margin: -50%;
            z-index: 1;
          }
        }

        @keyframes vikinglotto-deselect-back-animation {
          0% {
            width: 100%;
            height: 100%;
          }

          99.999% {
            width: 100%;
            height: 100%;
          }

          100% {
            width: 0;
            height: 0;
          }
        }

        @media (min-width: @vikinglotto-desktop-width) {
          @keyframes vikinglotto-flip-face-animation {
            0% {
              color: #fff;
              background-color: @vikinglotto-white;
            }

            35% {
              color: #fff;
              background-color: @vikinglotto-white;
            }

            65% {
              color: #fff;
              background-color: @vikinglotto-white;
            }

            80% {
              color: @vikinglotto-darkgray;
              background-color: #fff;
            }

            100% {
              color: @vikinglotto-darkgray;
              background-color: #fff;
            }
          }

          @keyframes vikinglotto-reverse-flip-face-animation {
            0% {
              color: @vikinglotto-darkgray;
              background-color: #fff;
            }

            35% {
              color: @vikinglotto-darkgray;
              background-color: #fff;
            }

            65% {
              color: @vikinglotto-darkgray;
              background-color: #fff;
            }

            80% {
              color: #fff;
              background-color: @vikinglotto-white;
            }

            100% {
              color: #fff;
              background-color: @vikinglotto-white;
            }
          }
        }

        .front-face, .back-face {
          position: absolute;
        }

        &.select-number-animation {
          animation: vikinglotto-flip-face-animation @vikinglotto-flip-animation-duration forwards @vikinglotto-flip-animation-function;

          .front-face {
            animation: vikinglotto-select-face-animation @vikinglotto-flip-animation-duration forwards @vikinglotto-flip-animation-function;
          }

          .back-face {
            animation: vikinglotto-select-back-animation .4s forwards @vikinglotto-flip-animation-function;
          }
        }

        &.deselect-number-animation {
          animation: vikinglotto-reverse-flip-face-animation @vikinglotto-flip-animation-duration forwards @vikinglotto-flip-animation-function;

          .front-face {
            //
            animation: vikinglotto-deselect-face-animation @vikinglotto-flip-animation-duration forwards @vikinglotto-flip-animation-function;
          }

          .back-face {
            animation: vikinglotto-deselect-back-animation @vikinglotto-flip-animation-duration forwards @vikinglotto-flip-animation-function;
          }
        }
      }

      &.active .number {
        line-height: 7.428vw;
        height: 130%;
        .transform(translateY(-100%) scale(1.3));

        @media (min-width: @vikinglotto-desktop-width) {
          height: 100%;
          line-height: 2.1;
          .transform(translateY(0) scale(1));
        }
      }

      .bubles-container {
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: inherit;
        z-index: -1;

        @keyframes vikinglotto-buble-1-1-animation {
          0% {
            width: 0;
            height: 0;
            transform: translate3D(0,0,0);
          }

          60% {
            width: 1.5rem;
            height: 1.5rem;
            transform: translate3D(-3rem,-1.5rem,0);
            opacity: 1;
          }

          100% {
            width: 0;
            height: 0;
            transform: translate3D(-4rem,-2.5rem,0);
            opacity: 0;
          }
        }

        @keyframes vikinglotto-buble-1-2-animation {
          0% {
            width: 0;
            height: 0;
            transform: translate3D(0,0,0);
          }

          60% {
            width: 2.5rem;
            height: 2.5rem;
            transform: translate3D(1.4rem,1rem,0);
            opacity: 1;
          }

          100% {
            width: 0;
            height: 0;
            transform: translate3D(2.4rem,2rem,0);
            opacity: 0;
          }
        }

        @keyframes vikinglotto-buble-1-3-animation {
          0% {
            width: 0;
            height: 0;
            transform: translate3D(0,0,0);
          }

          60% {
            width: 2.2rem;
            height: 2.2rem;
            transform: translate3D(1.4rem,-3.9rem,0);
            opacity: 1;
          }

          100% {
            width: 0;
            height: 0;
            transform: translate3D(2.6rem,-4.2rem,0);
            opacity: 0;
          }
        }

        @keyframes vikinglotto-buble-1-4-animation {
          0% {
            width: 0;
            height: 0;
            transform: translate3D(0,0,0);
          }

          60% {
            width: 3rem;
            height: 3rem;
            transform: translate3D(-3.9rem,1.2rem,0);
            opacity: 1;
          }

          100% {
            width: 0;
            height: 0;
            transform: translate3D(-4.3rem,1.8rem,0);
            opacity: 0;
          }
        }

        @keyframes vikinglotto-buble-1-5-animation {
          0% {
            width: 0;
            height: 0;
            transform: translate3D(0,0,0);
          }

          60% {
            width: 1.8rem;
            height: 1.8rem;
            transform: translate3D(-2.1rem,-3.5rem,0);
            opacity: 1;
          }

          100% {
            width: 0;
            height: 0;
            transform: translate3D(-2.3rem,-4rem,0);
            opacity: 0;
          }
        }

        hr {
          background-color: #fff;
          border: none;
          transform: translate3d(0,0,0);
          border-radius: 50%;
          transition: transform .25s linear;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 1;

          &:nth-of-type(1) {
            animation: vikinglotto-buble-1-1-animation @vikinglotto-flip-animation-duration forwards @vikinglotto-flip-animation-function;
          }

          &:nth-of-type(2) {
            animation: vikinglotto-buble-1-2-animation @vikinglotto-flip-animation-duration forwards @vikinglotto-flip-animation-function;
          }

          &:nth-of-type(3) {
            animation: vikinglotto-buble-1-3-animation @vikinglotto-flip-animation-duration forwards @vikinglotto-flip-animation-function;
          }

          &:nth-of-type(4) {
            animation: vikinglotto-buble-1-4-animation @vikinglotto-flip-animation-duration forwards @vikinglotto-flip-animation-function;
          }

          &:nth-of-type(5) {
            animation: vikinglotto-buble-1-5-animation @vikinglotto-flip-animation-duration forwards @vikinglotto-flip-animation-function;
          }
        }

        @keyframes vikinglotto-face-1-1-animation {
          0% {
            transform: scale(1);
          }

          50% {
            transform: scale(1.3);
          }

          100% {
            transform: scale(1);
          }
        }

        span {
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: inherit;

          &:nth-of-type(1) {
            animation: vikinglotto-face-1-1-animation @vikinglotto-flip-animation-duration forwards @vikinglotto-flip-animation-function;
          }
        }
      }

      &.selected {
        .front-face {
          width: 0;
          height: 0;
        }

        .back-face {
          width: 100%;
          height: 100%;
        }

        .bubles-container {
          hr {
            background-color: @vikinglotto-white;
          }
        }
      }
    }


    .numbers-vikings-separator {
      width: 1px;
      height: calc(~'100% - 5.412vw');
      position: absolute;
      top: 1.356vw;
      right: 10.2vw; //13.9vw;
      background-color: @vikinglotto-white;
      border: none;

      @media (min-width: @vikinglotto-desktop-width) {
        height: calc(~'100% - 4rem');
        top: 1rem;
        right: 12.5rem;
      }

      @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
        right: 13.5%;
      }
    }

    .row-vikings-container {
      width: 11.338vw; //13.335vw;
      text-align: center;
      position: absolute;
      right: -.7vw;
      top: -.1vw;

      @media (min-width: @vikinglotto-desktop-width) {
        width: 10.5rem;
        right: -.3rem;
        top: .9rem;
      }

      @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
        width: 15%;
      }

      .viking-box {
        width: 100%;
        height: 11.05vw; //12.06vw;
        padding: 0.5rem 1rem;
        box-sizing: border-box;
        float: left;
        position: relative;
        transition: transform .1s linear;

        &:hover {
          @media (min-width: @vikinglotto-desktop-width) {
            transform: scale(1.26);
          }
        }

        @media (min-width: @vikinglotto-desktop-width) {
          width: 50%;
          height: 6.65rem;
          top: -.5rem;
        }

        @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
          height: 7.5vw;
          padding: 8px;
        }

        .number {
          width: 100%;
          font-size: 3.8vw;
          line-height: 2.95;
          display: block;
          color: @vikinglotto-white;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;

          @media (min-width: @vikinglotto-desktop-width) {
            font-size: 1.6rem;
            line-height: 2.9;
            left: 0;
          }

          @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
            font-size: 2.5vw;
            line-height: 3.4;
          }
        }

        .icon {
          width: 6.8vw; //10.8vw;
          height: 9.26vw; //10.267vw;
          margin: auto;
          position: absolute;
          top: 1.1vw;
          left: 0;
          right: 0;
          transition: opacity .25s linear;

          @media (min-width: @vikinglotto-desktop-width) {
            width: 4.2rem;
            height: 5.5rem;
            top: 0;
          }

          @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
            width: 5.5vw;
            height: 6.3vw;
          }

          &.yellow {
            opacity: 0;
          }
        }

        &.selected {
          .number {
            color: @vikinglotto-yellow;
          }

          .icon {
            &.white {
              opacity: 0;
            }

            &.yellow {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.vikinglotto-classic-game,
.vikinglotto-lucky-game,
.vikinglotto-system-game {
  .vikinglotto-content-wrapper--version-2 {
    .number-picker-container {
      @media (min-width: @vikinglotto-desktop-width) {
        .cells-container {
          width: 86.2rem;
        }

        .cell {
          margin: 1.5rem 1.2rem;
        }
      }

      .row-vikings-container {
        @media (min-width: @vikinglotto-desktop-width) {
          width: 5.5rem;

          .viking-box {
            float: none;
            width: auto;
            height: 5.8rem;
            top: auto;
  
            .icon {
              height: 4.8rem;
            }
          }
        }
      }
    }

    .numbers-vikings-separator {
      @media (min-width: @vikinglotto-desktop-width) {
        right: 7.5rem;
      }
    }
  }
}