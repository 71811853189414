@import url("../../Mixins/_mixins.less");

.region-vikinglotto {

  .calltoactionspot {

    .call-to-action-headline {
      max-width: 86.5vw;
      font-size: 3.5vw;
      font-weight: 800;
      line-height: 3rem;
      color: @vikinglotto-darkblue;
      text-transform: uppercase;
      margin: 0 auto;

      @media (min-width: @desktop-width) {
        font-size: 2rem;
        line-height: 2rem;
        margin: 1rem auto;
      }
    }

    .call-to-action-text {
      max-width: 86.667vw;
      font-size: 4vw;
      line-height: 1.3;
      padding-bottom: 5.333vw;
      margin: 0 auto;

      @media (min-width: @desktop-width) {
        font-size: 1.5rem;
        padding-bottom: 2rem;
      }
    }
  }
}
