@import url("../Mixins/_mixins.less");

.vikinglotto-receipt {
  .vikinglotto-section {
    background-color: #fff;
    border-color: #fff;

    @media (min-width: @vikinglotto-desktop-width) {
      padding: 4rem 0;
    }

    .vikinglotto-content-wrapper {
      width: 100%;
      max-width: 86.7vw;
      padding: 8vw 0 4vw;

      @media (min-width: @vikinglotto-desktop-width) {
        max-width: 96rem;
        padding: 0;
      }

      .receipt-confirmation {
        margin: auto;

        @media (min-width: @vikinglotto-desktop-width) {
          width: 100%;
          max-width: 96rem;
        }


        svg.receipt-icon {
          width: 13.33vw;
          height: 13.33vw;
          margin: 0 auto 3.5vw;

          @media (min-width: @vikinglotto-desktop-width) {
            width: 6.4rem;
            height: 6.4rem;
            margin: 0 auto 2rem;
          }
        }

        .receipt-text {
          font-size: 3.75vw;
          color: @vikinglotto-darkgray;
          margin: 0 auto 1.5rem;

          @media (min-width: @vikinglotto-desktop-width) {
            max-width: 100%;
            font-size: 2rem;
            line-height: 2.4rem;
          }
        }

        .reminder-alert {
          margin: 2rem;
          color: black;
          font-size: 3vw;

          @media (min-width: @vikinglotto-desktop-width) {
            font-size: 1.6rem;
          }

          input[type=checkbox] {
            display: none;

            &:checked + label .checkbox:after {
              content: '';
              position: absolute;
              border: 0.3vw solid black;
              border-left: 0;
              border-top: 0;
              transform: rotate(45deg);
              font-size: 3.4vw;
              left: 0.3em;
              top: 0.1em;
              width: 0.3em;
              height: 0.5em;

              @media (min-width: @vikinglotto-desktop-width) {
                border: 0.2rem solid black;
                border-left: 0;
                border-top: 0;
                font-size: 2rem;
              }

            }
          }

          label {
            background: #eee;
            display: inline-block;
            padding: 4vw 4vw 4vw 10vw;
            border-radius: 0.5rem;
            position: relative;
            text-align: left;
            line-height: 1.5;
            user-select: none;

            @media (min-width: @vikinglotto-desktop-width) {
              padding: 2.5rem 2.5rem 2.5rem 6rem;
            }

            .checkbox {
              background: white;
              width: 3.5vw;
              height: 3.5vw;
              left: 4vw;
              top: 4.5vw;
              border-radius: 0.4rem;
              content: '';
              display: inline-block;
              vertical-align: text-top;
              border: 0.1rem solid #a0a0a0;
              position: absolute;

              @media (min-width: @vikinglotto-desktop-width) {
                width: 2.0rem;
                height: 2.0rem;
                left: 2.5rem;
                top: 2.6rem;
              }
            }
          }

          &.reminder-alert--loading label {
            overflow: hidden;

            &:before {
              position: absolute;
              content: "";
              top: -50%;
              bottom: -50%;
              left: -200%;
              width: 3rem;
              background-color: rgba(0, 0, 0, 0.05);
              animation: reminder-alert--loading 1000ms linear infinite;
              transform: rotate(20deg);
            }

            &:after {
              position: absolute;
              content: "";
              top: -50%;
              bottom: -50%;
              left: -200%;
              width: 1.5rem;
              background-color: rgba(0, 0, 0, 0.05);
              animation: reminder-alert--loading 1000ms linear infinite;
              animation-delay: 500ms;
              transform: rotate(20deg) translateX(-2rem);
            }

            @keyframes reminder-alert--loading {
              100% {
                left: 300%;
              }
            }
          }

        }

        .go-to-coupon {
          border-bottom: .1rem solid @vikinglotto-black;
          cursor: pointer;
          display: inline-block;
        }
      }

      .hr {
        border-color: #1d1d1d;
        display: none;

        @media (min-width: @vikinglotto-desktop-width) {
          display: block;
          margin: 3.5rem auto 2.5rem;
        }
      }
    }
  }
}
