.vikinglotto-receipt .countdown,
.vikinglotto-no-open-draw .countdown {
  width: 100%;
  max-width: 82vw;
  margin: auto;
  padding-top: 6vw;
}
@media (min-width: 950px) {
  .vikinglotto-receipt .countdown,
  .vikinglotto-no-open-draw .countdown {
    max-width: 96rem;
    padding: 1.5rem 0;
  }
}
.vikinglotto-receipt .countdown .countdown-headline,
.vikinglotto-no-open-draw .countdown .countdown-headline {
  font-size: 4.67vw;
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  color: #000078;
  margin-top: 0;
}
@media (min-width: 950px) {
  .vikinglotto-receipt .countdown .countdown-headline,
  .vikinglotto-no-open-draw .countdown .countdown-headline {
    font-size: 2.6rem;
    line-height: 0.9;
  }
}
.vikinglotto-receipt .countdown .number-container,
.vikinglotto-no-open-draw .countdown .number-container {
  display: inline-block;
  margin: 0 3vw;
  color: #000078;
}
@media (min-width: 950px) {
  .vikinglotto-receipt .countdown .number-container,
  .vikinglotto-no-open-draw .countdown .number-container {
    margin: 0 1.5rem;
  }
}
.vikinglotto-receipt .countdown .number-container .number,
.vikinglotto-no-open-draw .countdown .number-container .number {
  font-size: 8vw;
  font-weight: bold;
  margin-right: 1vw;
}
@media (min-width: 950px) {
  .vikinglotto-receipt .countdown .number-container .number,
  .vikinglotto-no-open-draw .countdown .number-container .number {
    font-size: 3.8rem;
    line-height: normal;
    margin-right: .5rem;
  }
}
.vikinglotto-receipt .countdown .number-container .time-denominator,
.vikinglotto-no-open-draw .countdown .number-container .time-denominator {
  font-size: 5.4vw;
  line-height: 5.9vw;
}
@media (min-width: 950px) {
  .vikinglotto-receipt .countdown .number-container .time-denominator,
  .vikinglotto-no-open-draw .countdown .number-container .time-denominator {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}
