﻿@import url("../../Mixins/_mixins.less");

.date-picker-placeholder-wrapper {
  background-color: @numbergames-white;
  border-color: @numbergames-mediumgray;
}

.date-picker-wrapper {

  .date-picker-calendar-outer-wrapper {
    border-color: @vikinglotto-blue;
    background-color: @numbergames-white;

    .date-picker-calendar-wrapper {
      border-color: @keno-powderblue;


      .calendar-cell-wrapper {

        &:not(.weeknr):not(.inactive) {

          &.selected .day-wrapper {
            color: @numbergames-white;
          }

          .day-wrapper {
            color: @vikinglotto-blue;
          }
        }

        &:after {
          background-color: @numbergames-white;
        }

        &.selected {
          &:hover > &:after, &:after {
            background-color: @vikinglotto-blue;
          }
        }
      }
    }
  }
}

