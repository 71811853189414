.top-navigation-theme-vikinglotto .menu-toggle-right {
  background-color: #0000ff;
}
.top-navigation-theme-vikinglotto .brand-nav {
  background-color: #0000ff;
}
.top-navigation-theme-vikinglotto .corporate-nav {
  background-color: #000078;
}
.top-navigation-theme-vikinglotto .corporate-nav__list-item:not(.js-has-subnav) .corporate-nav__list-link:hover {
  color: white;
  opacity: 0.7;
}
.top-navigation-theme-vikinglotto .mobile-navigation-header {
  background-color: #0000ff;
}
.top-navigation-theme-vikinglotto .mobile-navigation-footer-icon {
  background-color: #0000ff;
}
.top-navigation-theme-vikinglotto .mobile-navigation-item-link:before {
  background-color: #0000ff;
}
.top-navigation-theme-vikinglotto .mega-menu__campaign__cta-button {
  background-color: #0000ff;
  color: white;
}
.top-navigation-theme-vikinglotto .mega-menu__campaign__cta-button:hover {
  background-color: #000078;
}
