@import url("../Mixins/_mixins.less");

.vikinglotto-rotary-selector {
  font-size: 1vw;
  margin: auto;
  overflow: hidden;
  padding-bottom: 2rem;

  @media (min-width: @vikinglotto-desktop-width) {
    font-size: .55rem;
  }

  @media screen and (min-resolution: 2dppx) and (min-width: @vikinglotto-desktop-width) {
    font-size:6px;
  }

  .selector-container {
    height: 32em;
    max-width: 60em;
    position: relative;
    padding: 6em 0;
    perspective: 150em;
    margin: auto;
    box-sizing: content-box;
  }

  .selector-background {
    width: 32em;
    height: 32em;
    background-color: @vikinglotto-blue;
    border-radius: 1em;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }

  .selector-numbers {
    width: 60em;
    height: 0;
    padding-bottom: 60em;
    position: absolute;
    transform-origin: center center;
    transform-style: preserve-3d;
    transition: transform .5s cubic-bezier(0.34, 1.61, 0.7, 1);
    transform: rotateX(-25deg) rotateZ(0deg) translateZ(-45em);
    top: 70em;

    .number-wrapper {
      width: 30em;
      height: 100%;
      position: absolute;
      left: 50%;
      top: -50%;
      margin-left: -15em;
      text-align: center;
      transform-origin: center bottom;
      transform-style: preserve-3d;
      /* Define two variables as the loop limits */
      @from: 0;
      @to: 18;

      /* Create a Parametric mixin and add a guard operation */
      .loop(@index) when (@index =< @to) {
        /* As the mixin is called CSS is outputted */
        &:nth-child(@{index}n+0) {
          transform: rotate(unit((@index - 1) * 20, deg)) translateZ(0);
        }
        /* Interation call and operation */
        .loop(@index + 1);
      }
      /* the mixin is called, css outputted and iterations called */
      .loop(@from);

      .number {
        font-size: 15em;
        font-weight: 900;
        color: @vikinglotto-white;
        position: relative;
        top: 4em;
        transform: scale(.6) translateZ(0) rotateX(10deg) translateY(-7em);
        opacity: 0;
        transition: transform .3s, opacity .25s;
        line-height: 1.75; /* Fix for Signa font */
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          top: 4.4em;
        }
        @media screen and (min-resolution: 2dppx) and (min-width: @vikinglotto-desktop-width) {
          top:3.9em;
        }
      }

      &.in-view {
        .number {
          cursor: pointer;
          transform: scale(.7) translateY(-7.75em) translateZ(0) rotateX(20deg);
          opacity: .85;
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            top: 4.4em;
          }
        }
      }

      &.in-view[data-id='plus1'] {
        .number {
          transform: scale(.7) translateY(-7.75em) translateZ(0) rotateX(20deg) translateX(1em) rotateZ(5deg);
          @media (min-width: @vikinglotto-desktop-width) {
            transform: scale(0.7) translate3d(1.6em, -8.3em, 0.2em) rotateX(24deg) rotateY(0deg) rotateZ(-8deg);
          }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            transform: scale(.7) translateY(-8.5em) translateZ(0) rotateX(20deg) translateX(1.5em) rotateZ(-5deg);

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active), (max-width: @vikinglotto-desktop-width) {
              transform: scale(.7) translateY(-7.75em) translateZ(0) rotateX(20deg) translateX(1em) rotateZ(5deg);
            }
          }
        }
      }

      &.in-view[data-id='minus1'] {
        .number {
          transform: scale(.7) translateY(-7.75em) translateZ(0) rotateX(20deg) translateX(-1em) rotateZ(-5deg);
          @media (min-width: @vikinglotto-desktop-width) {
            transform: scale(0.7) translate3d(-1.6em, -8.3em, 0.2em) rotateX(16.4deg) rotateY(0deg) rotateZ(8deg);
          }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            transform: scale(.7) translateY(-8.5em) translateZ(0) rotateX(20deg) translateX(-1.5em) rotateZ(5deg);

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active), (max-width: @vikinglotto-desktop-width) {
              transform: scale(.7) translateY(-7.75em) translateZ(0) rotateX(20deg) translateX(-1em) rotateZ(-5deg);
            }
          }
        }
      }
      &.in-view[data-id='plus2'] {
        .number {
          transform: scale(.7) translateY(-7.75em) translateZ(0) rotateX(20deg) translateX(0.5em);
          @media (min-width: @vikinglotto-desktop-width) {
            transform: scale(0.7) translate3d(-0.1em, -9.65em, 1.7em) rotateX(10deg) rotateY(3deg) rotateZ(-18deg);
          }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            transform: scale(.7) translateX(1em) translateY(-9.2em) rotateX(20deg) rotateZ(-14deg);

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active), (max-width: @vikinglotto-desktop-width) {
              transform: scale(.7) translateX(0.5em) translateY(-7.75em) rotateX(20deg);
            }
          }
        }
      }
      &.in-view[data-id='minus2'] {
        .number {
          transform: scale(.7) translateY(-7.75em) translateZ(0) rotateX(20deg) translateX(-0.5em);
          @media (min-width: @vikinglotto-desktop-width) {
            transform: scale(0.7) translate3d(0.1em, -9.65em, 1.7em) rotateX(10deg) rotateY(3deg) rotateZ(18deg);
          }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            transform: scale(.7) translateY(-9.2em) rotateX(20deg) translateX(-1em) rotateZ(14deg);

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active), (max-width: @vikinglotto-desktop-width) {
              transform: scale(.7) translateY(-7.75em) rotateX(20deg) translateX(-0.5em);
            }
          }
        }
      }

      &.in-focus {
        .number {
          transform: scale(1) translateY(-6em) translateZ(1em) rotateX(30deg);
          opacity: 1;
        }
      }
    }
  }

  .selector-foreground {
    width: 50.666em;
    height: 32em;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateZ(7em) scale(0.95333);

    .navigation-button {
      width: 9.5em;
      height: 10.666em;
      background-color: @vikinglotto-blue;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      &:before, &:after {
        content: '';
        width: 4em;
        height: 4em;
        border-radius: 50%;
        border-style: solid;
        border-color: transparent transparent transparent @vikinglotto-blue;
        border-width: 1em;
        box-sizing: border-box;
        position: absolute;
      }

      .icon {
        width: 2.13em;
        height: 3.46em;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: @vikinglotto-white;
      }

      &.previous {
        border-radius: 1em 0 0 1em;
        left: 0;

        &:before {
          top: -3em;
          right: -.7em;
          transform: rotate(-135deg);
        }

        &:after {
          bottom: -3.1em;
          right: -.7em;
          transform: rotate(135deg);
        }
      }

      &.next {
        border-radius: 0 1em 1em 0;
        right: 0;

        &:before {
          top: -3em;
          left: -.8em;
          transform: rotate(-45deg);
        }

        &:after {
          bottom: -3em;
          left: -.8em;
          transform: rotate(45deg);
        }
      }
    }

    .number-frame {
      width: 32em;
      height: 32em;
      border-radius: 1em;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      overflow: hidden;

      &:before, &:after {
        content: '';
        width: 18%;
        height: 100%;
        position: absolute;
        top: 0;
      }
      &:before {
        left: 0;
        background: linear-gradient(to right, rgba(0, 0, 255, 1) 0%, rgba(0, 0, 255, 1) 20%, rgba(0, 0, 255, 0) 100%);
      }
      &:after {
        right: 0;
        background: linear-gradient(to right, rgba(0, 0, 255, 0) 0%, rgba(0, 0, 255, 1) 80%, rgba(0, 0, 255, 1) 100%);
      }

      .text-box {
        width: 21.333em;
        text-align: center;
        margin: auto;
        padding-top: 1.65em;
        border-top: .2rem solid @vikinglotto-white;
        position: absolute;
        top: 21em;
        left: 0;
        right: 0;

        .text {
          font-size: 4.266em;
          color: @vikinglotto-white;
        }
      }
    }
  }
}
