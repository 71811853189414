.vikinglotto-game-promotion-spot .btn {
  margin: 2vw auto;
}
@media (min-width: 950px) {
  .vikinglotto-game-promotion-spot .btn {
    margin: 1rem auto;
  }
}
.vikinglotto-game-promotion-spot.big {
  font-size: 0;
}
.vikinglotto-game-promotion-spot.big .vikinglotto-section {
  background-color: #0000ff;
}
@media (min-width: 950px) {
  .vikinglotto-game-promotion-spot.big .vikinglotto-section .vikinglotto-content-wrapper .info-text {
    width: 50rem;
  }
}
.vikinglotto-game-promotion-spot.small .vikinglotto-section {
  background: transparent;
}
.vikinglotto-game-promotion-spot .vikinglotto-section .vikinglotto-content-wrapper {
  padding: 6rem 2rem;
  box-sizing: border-box;
}
.vikinglotto-game-promotion-spot .vikinglotto-section.small {
  display: inline-block;
  width: 100%;
}
@media (max-width: 950px) {
  .vikinglotto-game-promotion-spot .vikinglotto-section.small {
    display: block;
    width: 100%;
  }
}
.vikinglotto-game-promotion-spot .vikinglotto-section .vikinglotto-content-wrapper {
  position: relative;
  text-align: center;
}
@media (min-width: 950px) {
  .vikinglotto-game-promotion-spot .vikinglotto-section .vikinglotto-content-wrapper {
    max-width: 50rem;
  }
}
.vikinglotto-game-promotion-spot .vikinglotto-section .vikinglotto-content-wrapper .vikinglotto-fast-play {
  display: inline-block;
  margin: 0 auto;
}
.vikinglotto-game-promotion-spot .vikinglotto-section .vikinglotto-content-wrapper .vikinglotto-fast-play .fast-play-image {
  margin-bottom: 4rem;
}
