@import url("../Mixins/_mixins.less");

.vikinglotto-system-game-header {
  background-color:@vikinglotto-darkblue;
  overflow:hidden;
  @media (min-width: @vikinglotto-desktop-width) {
    height:7rem;
  }
  .game-navigation-wrapper {
    width:100%;
    display:table;
    overflow:hidden;
    table-layout:fixed;
    .game-navigation-item {
      color:@vikinglotto-white;
      text-align:center;
      font-size:3.733vw;
      font-weight:bold;
      text-transform:uppercase;
      padding:5.45vw 0;
      display:table-cell;
      cursor:pointer;
      @media (min-width: @vikinglotto-desktop-width) {
        font-size:1.6rem;
        padding:2.4rem 0;
      }

      @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
        font-size: 2.5vw;
        padding: 2.5vw 0;
      }

      &.active {
        color:#fff;
        background-color:@vikinglotto-blue;
      }
    }
  }

  .game-switch-wrapper {
    background-color:@vikinglotto-blue;
    position:relative;
		
    .switch-inner-wrapper {
      white-space:nowrap;
      user-select:none;
      overflow:hidden;
      overflow-x:auto;
			
      .switch-items-wrapper {
        display:inline-block;
        padding-right:6vw;
        @media (min-width: @vikinglotto-desktop-width) {
          padding-right:0;
        }
        .switch-title, .switch-item {
          color:@vikinglotto-white;
          font-size:3.733vw;
          font-weight:bold;
          text-transform:uppercase;
          display:inline-block;
          padding:5.4vw 0;
          margin:0 0 0 5vw;
          @media (min-width: @vikinglotto-desktop-width) {
            font-size:1.6rem;
            padding:2.4rem 0;
            margin:0 0 0 2.5rem;
          }

          @media (min-width: @vikinglotto-mobile-width) and (max-width: @vikinglotto-desktop-width) {
            font-size: 2.5vw;
            padding: 2.5vw 0;
          }

          &:last-child {
            margin:0 0 0 6vw;
            @media (min-width: @vikinglotto-desktop-width) {
              margin:0 9.5rem 0 2.5rem;
            }
          }
        }
        .switch-title {
          cursor:default;
        }
        .switch-item {
          cursor:pointer;
          opacity:.6;
          &.active {
            color:#fff;
            opacity:1;
          }
        }
      }
    }

    .switch-button {
      width:11vw;
      height:100%;
      position:absolute;
      top:0;
      transition:transform .2s linear;
      z-index:1;
      cursor:pointer;
      will-change:transform;
      @media (min-width: @vikinglotto-desktop-width) {
        width:11rem;
        height:7rem;
      }
      &.next {
        right:0;
        background: -moz-linear-gradient(left,  rgba(0,0,255,0) 0%, rgba(0,0,255,1) 100%);
        background: -webkit-linear-gradient(left,  rgba(0,0,255,0) 0%,rgba(0,0,255,1) 100%);
        background: linear-gradient(to right,  rgba(0,0,255,0) 0%,rgba(0,0,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000ff', endColorstr='#0000ff',GradientType=1 );
        transform:translate3d(100%, 0, 0);

        &:hover {
          .icon {
            transform:translate3d(0, -50%, 0);
          }
        }
      }
      &.prev {
        left:0;
        background: -moz-linear-gradient(left,  rgba(0,0,255,1) 0%, rgba(0,0,255,0) 100%);
        background: -webkit-linear-gradient(left,  rgba(0,0,255,1) 0%,rgba(0,0,255,0) 100%);
        background: linear-gradient(to right,  rgba(0,0,255,1) 0%,rgba(0,0,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0000ff', endColorstr='#000000ff',GradientType=1 );
        transform:translate3d(-100%, 0, 0);
				
        &:hover {
          .icon {
            transform:translate3d(-100%, -50%, 0);
          }
        }
      }
      &.active {
        transform:translate3d(0, 0, 0);
      }
      .icon {
        width:2.133vw;
        height:3.466vw;
        fill:#fff;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate3d(-50%, -50%, 0);
        transition:transform .15s linear;
        @media (min-width: @vikinglotto-desktop-width) {
          width:2rem;
          height:3rem;
        }
      }
    }
  }

  &.overflown-left {
    .game-switch-wrapper {
      .switch-button.prev {
        transform:translate3d(0, 0, 0);
      }
    }
  }
  &.overflown-right {
    .game-switch-wrapper {
      .switch-button.next {
        transform:translate3d(0, 0, 0);
      }
    }
  }
}