﻿@import (reference) "Mixins/_mixins";

.vikinglotto__curved-bg {
  position: absolute;
  background: @vikinglotto-blue;
  top: 0;
  bottom: 1rem;
  left: @vikinglotto-curve-stretch;
  right: @vikinglotto-curve-stretch;
  pointer-events: unset;
  z-index: -1;
  border-top-left-radius: @vikinglotto-curve-radius;
  border-top-right-radius: @vikinglotto-curve-radius;
  border-bottom-left-radius: @vikinglotto-curve-radius;
  border-bottom-right-radius: @vikinglotto-curve-radius;
  overflow: hidden;

  @media @xl {
    left: @vikinglotto-curve-stretch-xl;
    right: @vikinglotto-curve-stretch-xl;
    border-top-left-radius: @vikinglotto-curve-radius-xl;
    border-top-right-radius: @vikinglotto-curve-radius-xl;
    border-bottom-left-radius: @vikinglotto-curve-radius-xl;
    border-bottom-right-radius: @vikinglotto-curve-radius-xl;
  }

  @media @xxl {
    left: @vikinglotto-curve-stretch-xxl;
    right: @vikinglotto-curve-stretch-xxl;
    border-top-left-radius: @vikinglotto-curve-radius-xxl;
    border-top-right-radius: @vikinglotto-curve-radius-xxl;
    border-bottom-left-radius: @vikinglotto-curve-radius-xxl;
    border-bottom-right-radius: @vikinglotto-curve-radius-xxl;
  }

  &.vikinglotto__curved-bg--top {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.vikinglotto__curved-bg--bottom {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
